import { usersManagedUsersList, usersMeRetrieve } from '@/client/backend/api/users/users'

export const usersLoader = async () => {
  const currentUser = await usersMeRetrieve()

  if (!currentUser) {
    return null
  }

  const users = await usersManagedUsersList(currentUser.id)
  return users
}
