/**
 * * `IN` - IN
 * `ADJACENT` - ADJACENT
 * `OUT` - OUT
 */
export type LocationSensitiveAreaEnum = (typeof LocationSensitiveAreaEnum)[keyof typeof LocationSensitiveAreaEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const LocationSensitiveAreaEnum = {
  IN: 'IN',
  ADJACENT: 'ADJACENT',
  OUT: 'OUT',
} as const
