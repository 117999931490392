import { Site, SpeciesSample } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import * as turf from '@turf/turf'
import { useTranslation } from 'react-i18next'

import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'
import { Icons } from '@/components/icons'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'

interface SpeciesGbifTableProps {
  data: SpeciesSample[]
  site: Site
}

const SpeciesGbifTable = ({ data, site }: SpeciesGbifTableProps) => {
  const { t } = useTranslation()

  const columns: ColumnDef<SpeciesSample>[] = [
    {
      accessorKey: 'canonical_name',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name)}
        />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex flex-col">
            <span className="max-w-[500px] truncate font-medium">{row.getValue('canonical_name')}</span>
            <span className="max-w-[500px] truncate text-xs text-muted-foreground">{row.original.vernacular_name}</span>
          </div>
        )
      },
      enableSorting: false,
    },
    {
      accessorKey: 'iucn_status_eu',
      header: ({ column }) => (
        <div className="flex flex-row items-center space-x-2">
          {' '}
          <DataTableColumnHeader
            column={column}
            children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.priority_species)}
          />
          <RenderTooltip
            size={20}
            explanationText={t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.prioritySpeciesTableHead)}
          />
        </div>
      ),
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate font-medium">
            {row.original.iucn_status_eu === 'CR' || row.original.iucn_status_eu === 'EN' ? <Icons.Check /> : null}
          </span>
        )
      },
      enableSorting: false,
    },
    {
      accessorKey: 'taxon_kingdom',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.category)}
        />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[500px] truncate font-medium">{row.original.taxon_kingdom}</span>
          </div>
        )
      },
      enableSorting: false,
    },
    {
      accessorFn: (row) => {
        const distance = turf.distance(site.properties?.center?.coordinates, row.gbif_location?.coordinates, {
          units: 'kilometers',
        })
        return distance
      },
      accessorKey: 'distance',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.distance_km)}
        />
      ),
      cell: ({ row }) => {
        const distance = turf.distance(site.properties?.center?.coordinates, row.original.gbif_location?.coordinates, {
          units: 'kilometers',
        })
        return (
          <div className="flex space-x-2">
            <span className="max-w-[500px] truncate font-medium">{distance.toFixed(2)}</span>
          </div>
        )
      },
      enableSorting: false,
    },
    {
      accessorKey: 'is_invasive_alien_species',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.is_invasive)}
        />
      ),
      cell: ({ row }) => {
        return (
          <span className="max-w-[500px] truncate font-medium">
            {row.original.is_invasive_alien_species ? <Icons.Check /> : null}
          </span>
        )
      },
      enableSorting: false,
    },
  ]

  return <DataTable columns={columns} data={data} filterIdColumn="canonical_name" />
}

export default SpeciesGbifTable
