import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { CultureArea } from '@/types/culture-area'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

type CultureTableProps = {
  cultures: CultureArea[]
  selectedPlot: string
}

const CulturesTable = ({ cultures, selectedPlot }: CultureTableProps) => {
  const { t } = useTranslation()

  const selectedRow = cultures.findIndex((culture) => culture.name.includes(selectedPlot))
  const columns: ColumnDef<CultureArea>[] = [
    {
      accessorKey: 'name',
      id: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.pesticidesTable.cultures.table.header.cultureType)}
        />
      ),
      cell: ({ row }) => (
        <div className="w-[200px]">
          <div>{row.original.name}</div>
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'total_surface',
      id: 'total_surface',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.pesticidesTable.cultures.table.header.surface)}
        />
      ),
      cell: ({ row }) => (
        <div className="w-[40px]">
          <span>{row.original.total_surface.toFixed(2)}</span>
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
  ]

  return <DataTable columns={columns} data={cultures} filterIdColumn="name" rowSelected={selectedRow.toString()} />
}
export default CulturesTable
