import { useEffect, useMemo, useState } from 'react'
import { getSitesListQueryKey, sitesList } from '@/client/backend/api/sites/sites'
import { PaginatedSiteList, SitesListParams } from '@/client/backend/models'
import { Site } from '@/client/backend/models/site'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { House } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useLocation, useSearchParams } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { LightSites } from '@/types/light-site'
import { SortType } from '@/types/sort-type'
import { AVAILABLE_FILTERS } from '@/lib/available-filters'
import { MODULE } from '@/lib/module'
import { hasAccesTo } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { Input } from '@/components/ui/input'
import {
  Pagination,
  PaginationContent,
  PaginationEllipsis,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from '@/components/ui/pagination'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import SitesMap from '@/components/beeoimpact/sites-map'
import RiskLegend from '@/components/beeoimpact/sites/risk-legend'
import SiteCard from '@/components/beeoimpact/sites/site-card'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb'
import { Icons } from '@/components/icons'
import Loader from '@/components/loader'
import NoSitesMessage from '@/components/no-sites-message'
import SiteCardSkeleton from '@/components/site-card-skeleton'

type SitesPageViewProps = {
  module: MODULE
  availableFilters?: AVAILABLE_FILTERS[]
}

const SitesPageView = ({ module, availableFilters }: SitesPageViewProps) => {
  const PAGE_SIZE = 12
  const { t } = useTranslation()
  const { user } = useAuth()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const [hoveredSiteId, setHoveredSiteId] = useState<number>()
  const [sites, setSites] = useState<Site[]>([])

  const pageParam = searchParams.get('page')
  const page = pageParam ? parseInt(pageParam, 10) : 1

  const defaultFilters = useMemo(() => {
    const baseFilters: SitesListParams = { page: 1, page_size: PAGE_SIZE }

    if (module === MODULE.MONITORING) baseFilters.is_in_monitoring = true
    if (module === MODULE.INITIATIVE) baseFilters.is_in_initiative = true
    if (module === MODULE.IMPACT) baseFilters.is_in_impact = true

    return baseFilters
  }, [module])

  const [filters, setFilters] = useState<SitesListParams>(defaultFilters)

  useEffect(() => {
    setFilters({
      ...filters,
      search: searchParams.get('search') ?? undefined,
      ordering: searchParams.get('ordering') ?? undefined,
      page: isNaN(page) ? 1 : page,
      page_size: PAGE_SIZE,
    })
  }, [location.search])

  const { data: dataSites, isLoading: isLoadingDataSites } = useQuery({
    queryKey: [getSitesListQueryKey(filters)],
    queryFn: () => sitesList(filters, undefined),
  })

  const [paginatedData, setPaginatedData] = useState<PaginatedSiteList>({} as PaginatedSiteList)

  useEffect(() => {
    if (dataSites) setPaginatedData(dataSites)
  }, [dataSites])

  useEffect(() => {
    setSites(paginatedData.results?.features ?? [])
  }, [paginatedData])

  const SortType = {
    ASC: t(i18nKeys.beeoimpact.sites.risk.low),
    DESC: t(i18nKeys.beeoimpact.sites.risk.high),
  }

  const icons = {
    [SortType.ASC]: <Icons.ArrowUpWideNarrow className="mr-2 size-3.5 text-muted-foreground/70" />,
    [SortType.DESC]: <Icons.ArrowDownWideNarrow className="mr-2 size-3.5 text-muted-foreground/70" />,
  }

  const sortByPesticidesScore = (sortType: SortType) => {
    const ordering = sortType === SortType.ASC ? 'stats_pesticides__ecoscore' : '-stats_pesticides__ecoscore'

    setFilters({
      ...filters,
      page_size: PAGE_SIZE,
      page: filters.page ?? 1,
      search: filters.search ?? '',
      ordering: ordering,
    })

    const path = getPath(Routes.BEEOIMPACT_SITES, {
      queryParams: {
        page: 1,
        ordering: ordering,
        search: filters.search ?? '',
      },
    })
    navigate(path, { replace: true })
  }

  const sortByBiodiversityScore = (sortType: SortType) => {
    const ordering =
      sortType === SortType.ASC ? 'stats_biodiversity__biodiversity_score' : '-stats_biodiversity__biodiversity_score'

    setFilters({
      ...filters,
      page_size: PAGE_SIZE,
      page: 1,
      search: filters.search ?? '',
      ordering: ordering,
    })

    const path = getPath(Routes.BEEOIMPACT_SITES, {
      queryParams: {
        page: 1,
        ordering: ordering,
        search: filters.search ?? '',
      },
    })
    navigate(path, { replace: true })
  }

  if (!user) {
    return <Loader />
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFilters({
      ...filters,
      search: e.target.value,
      page: 1,
      ordering: filters.ordering ?? 'name',
    })

    switch (module) {
      case MODULE.AUDIT:
        return navigate(
          getPath(Routes.BEEOAUDIT_SITES, {
            queryParams: {
              page: 1,
              search: filters.search ?? '',
            },
          }),
          { replace: true }
        )
      case MODULE.MONITORING:
        return navigate(
          getPath(Routes.BEEOMONITORING_SITES, {
            queryParams: {
              page: 1,
              search: filters.search ?? '',
            },
          }),
          { replace: true }
        )
      case MODULE.IMPACT:
        return navigate(
          getPath(Routes.BEEOIMPACT_SITES, {
            queryParams: {
              page: 1,
              ordering: filters.ordering ?? 'name',
              search: filters.search ?? '',
            },
          }),
          { replace: true }
        )
    }
  }

  const transformPath = (page: number) => {
    // get the current path and modify the page query param
    const url = new URL(window.location.href)
    url.searchParams.set('page', page.toString())

    let params: SitesListParams = {
      page: page,
    }
    if (filters.search) {
      params = { ...params, search: filters.search }
    }
    if (filters.ordering) {
      params = { ...params, ordering: filters.ordering }
    }

    switch (module) {
      case MODULE.MONITORING:
        return getPath(Routes.BEEOMONITORING_SITES, {
          queryParams: {
            ...params,
          },
        })
      case MODULE.AUDIT:
        return getPath(Routes.BEEOAUDIT_SITES, {
          queryParams: {
            ...params,
          },
        })
      case MODULE.IMPACT:
        return getPath(Routes.BEEOIMPACT_SITES, {
          queryParams: {
            ...params,
          },
        })
      default:
        return ''
    }
  }

  const calculatePaginationRange = (currentPage, totalPages, displayedPages = 5) => {
    let startPage: number, endPage: number

    if (totalPages <= displayedPages) {
      // Total pages is less or equal to the number of pages we want to display
      startPage = 2
      endPage = totalPages - 1
    } else {
      // Calculate start and end pages
      const pagesBeforeCurrentPage = Math.floor(displayedPages / 2)
      const pagesAfterCurrentPage = Math.ceil(displayedPages / 2) - 1
      startPage = currentPage - pagesBeforeCurrentPage
      endPage = currentPage + pagesAfterCurrentPage

      // Adjust if startPage or endPage goes out of bounds
      if (startPage < 2) {
        endPage = endPage + (1 - startPage)
        startPage = 2
      }
      if (endPage >= totalPages) {
        startPage = startPage - (endPage - totalPages)
        endPage = totalPages - 1
      }
    }

    return { startPage, endPage }
  }

  const { startPage, endPage } = calculatePaginationRange(paginatedData.current_page, paginatedData.last_page)

  const getLinkCard = (siteId: number) => {
    switch (module) {
      case MODULE.MONITORING:
        return getPath(Routes.BEEOMONITORING_SITE, { params: { siteId: siteId } })
      case MODULE.AUDIT:
        return getPath(Routes.BEEOAUDIT_SITE, { params: { siteId: siteId } })
      default:
      case MODULE.IMPACT:
        return getPath(Routes.BEEOIMPACT_SITE, { params: { siteId: siteId } })
    }
  }

  const lightSites = sites.map((currentSite): LightSites => {
    const lightSite: LightSites = {
      id: currentSite.id ?? 0,
      name: currentSite.properties?.name ?? '',
      center: currentSite.properties?.center ?? { type: 'Point', coordinates: [0, 0] },
    }

    if (hasAccesTo(user.features, FeatureType.PESTICIDES) && currentSite.properties?.stats_pesticides) {
      lightSite.score_pesticides = currentSite.properties?.stats_pesticides?.ecoscore
    }
    if (hasAccesTo(user.features, FeatureType.BIODIVERSITY) && currentSite.properties?.stats_biodiversity) {
      lightSite.score_biodiversity = currentSite.properties?.stats_biodiversity?.biodiversity_score
    }

    return lightSite
  })

  return (
    <div className="mx-auto w-full ">
      <div className="sticky top-[70px] z-10 h-14 min-h-14 bg-background/95 pl-6 backdrop-blur">
        <Breadcrumb className="flex h-full">
          <BreadcrumbItem>
            <BreadcrumbLink href="/">
              <House className="size-4" />
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbItem>
            <BreadcrumbLink isCurrentPage>{t(i18nKeys.beeoimpact.common.breadcrumb.mySites)}</BreadcrumbLink>
          </BreadcrumbItem>
        </Breadcrumb>
      </div>

      <>
        <div className="sticky top-[126px] z-10 flex flex-col items-center justify-between border-y border-gray-200 bg-background/95 py-4 pl-4 backdrop-blur sm:flex-col sm:items-start sm:gap-y-2  md:flex-col md:gap-y-2  lg:flex-row  xl:flex-row ">
          <div className="flex flex-1 items-center space-x-2 pl-2">
            <Input
              id="search"
              className="w-[150px] lg:w-[250px]"
              placeholder={t(i18nKeys.global.common.datatable.filter)}
              onChange={handleInputChange}
            />
            <Separator orientation="vertical" />
            {hasAccesTo(user.features, FeatureType.PESTICIDES) &&
              availableFilters?.includes(AVAILABLE_FILTERS.SCORE_PESTICIDES) && (
                <Select onValueChange={sortByPesticidesScore}>
                  <SelectTrigger className="w-max space-x-2 text-muted-foreground" id="pesticides">
                    <div className="flex flex-row items-center space-x-1 ">
                      <Icons.FlaskConical className="size-4" />
                      <SelectValue placeholder={t(i18nKeys.beeoimpact.sites.top.sortByPesticide)} />
                    </div>
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(SortType).map(([key, sortType]) => {
                      return (
                        <SelectItem key={key} value={sortType}>
                          <div className="flex items-center space-x-2">
                            {icons[sortType]}
                            {sortType}
                          </div>
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>
              )}
            <Separator orientation="vertical" />
            {hasAccesTo(user.features, FeatureType.BIODIVERSITY) &&
              availableFilters?.includes(AVAILABLE_FILTERS.SCORE_BIODIVERSITY) && (
                <Select onValueChange={sortByBiodiversityScore}>
                  <SelectTrigger className=" w-max space-x-2 text-muted-foreground" id="biodiv">
                    <div className="flex flex-row items-center space-x-1">
                      <Icons.Leaf className="size-4" />
                      <SelectValue placeholder={t(i18nKeys.beeoimpact.sites.top.sortByBiodiv)} />
                    </div>
                  </SelectTrigger>
                  <SelectContent>
                    {Object.entries(SortType).map(([key, sortType]) => {
                      return (
                        <SelectItem key={key} value={sortType}>
                          <div className="flex items-center space-x-2 text-sm">
                            {icons[sortType]}
                            {sortType}
                          </div>
                        </SelectItem>
                      )
                    })}
                  </SelectContent>
                </Select>
              )}
          </div>
          {module === MODULE.IMPACT && (
            <div className="mr-4 sm:pl-2 md:pl-2">
              <RiskLegend />
            </div>
          )}
        </div>

        <div className="flex">
          <div className="flex basis-3/5">
            {!isLoadingDataSites && sites?.length === 0 && <NoSitesMessage />}
            <ScrollArea>
              <div className="mt-4 grid grid-cols-2 xl:grid-cols-3">
                {isLoadingDataSites
                  ? Array.from({ length: PAGE_SIZE }, (_, i) => (
                      <SiteCardSkeleton user={user} key={i} withScoring={true} />
                    ))
                  : sites.map((site) => (
                      <SiteCard
                        className="mb-8 ml-8"
                        site={site}
                        key={site.id}
                        onSiteHoverEnter={() => setHoveredSiteId(site.id)}
                        onSiteHoverOut={() => setHoveredSiteId(site.id)}
                        to={getLinkCard(site.id)}
                        withScoring={module === MODULE.IMPACT}
                      />
                    ))}
              </div>
              <div className="mb-2">
                <Pagination>
                  <PaginationContent>
                    {/* Previous Page Link */}
                    {paginatedData.previous_page && (
                      <PaginationItem>
                        <PaginationPrevious to={transformPath(paginatedData.previous_page)} replace={true} />
                      </PaginationItem>
                    )}
                    {/* First page and possibly an ellipsis */}
                    {(paginatedData?.last_page ?? 0) > 1 && (
                      <PaginationItem>
                        <PaginationLink
                          to={transformPath(1)}
                          isActive={1 === paginatedData.current_page}
                          replace={true}
                        >
                          1
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {startPage > 2 && (
                      <PaginationItem>
                        <PaginationEllipsis />
                      </PaginationItem>
                    )}
                    {/* Dynamic Page Links */}
                    {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((page) => (
                      <PaginationItem key={page}>
                        <PaginationLink
                          to={transformPath(page)}
                          isActive={page === paginatedData.current_page}
                          replace={true}
                        >
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    ))}
                    {/* Ellipsis and last page if needed */}
                    {endPage < paginatedData.last_page - 1 && (
                      <PaginationItem>
                        <PaginationEllipsis />
                      </PaginationItem>
                    )}
                    {paginatedData.last_page > 1 && (
                      <PaginationItem>
                        <PaginationLink
                          to={transformPath(paginatedData.last_page)}
                          isActive={paginatedData.last_page === paginatedData.current_page}
                          replace={true}
                        >
                          {paginatedData.last_page}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    {paginatedData.next_page && (
                      <PaginationItem>
                        <PaginationNext to={transformPath(paginatedData.next_page)} replace={true} />
                      </PaginationItem>
                    )}
                  </PaginationContent>
                </Pagination>
              </div>
            </ScrollArea>
          </div>

          <div className="sticky top-48 ml-6 flex h-[calc(100vh-200px)] min-h-full flex-1 basis-2/5">
            <SitesMap sites={lightSites ?? []} hoveredSiteId={hoveredSiteId} showTooltip={module === MODULE.IMPACT} />
          </div>
        </div>
      </>
    </div>
  )
}

export default SitesPageView
