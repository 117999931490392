import { ResponsivePie } from '@nivo/pie'

import { ValuePieChart } from '@/types/graph'
import { COLORS } from '@/lib/constants/constants'
import { cn } from '@/lib/utils'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

type CardPieChartProps = {
  title: string
  subtitle: string
  data: ValuePieChart[]
}

const CardPieChart = ({ title, subtitle, data }: CardPieChartProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{subtitle}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex h-[360px] w-full items-center justify-center">
          <ResponsivePie
            data={data}
            margin={{ top: 0, right: 90, bottom: 0, left: 90 }}
            innerRadius={0}
            padAngle={0.7}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            colors={COLORS}
            borderColor={{
              from: 'color',
              modifiers: [['darker', 0.2]],
            }}
            valueFormat={(e) => e.toFixed(2)}
            enableArcLinkLabels={true}
            enableArcLabels={false}
            arcLabel={(e) => `${e.value.toFixed(2)}%`}
            arcLinkLabel={(e) => `${e.value.toFixed(2)}%`}
            arcLabelsRadiusOffset={1.3}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor={{
              from: 'color',
              modifiers: [['darker', 2]],
            }}
            tooltip={({ datum: { value, color, label } }) => (
              <div
                style={{
                  padding: '8px',
                  background: 'hsl(210,40%,96.1%)',
                }}
              >
                <div className="flex items-center justify-evenly">
                  <div className={cn('mr-2 size-2 rounded-full')} style={{ backgroundColor: color }} />
                  <span>{label}</span>
                  <span className="ml-5 text-muted-foreground">{value.toFixed(2)}%</span>
                </div>
              </div>
            )}
            theme={{
              tooltip: {
                container: {
                  background: '#333',
                },
              },
            }}
          />
        </div>

        <div className="flex min-h-[50px] flex-wrap justify-center">
          {data.map((item) => (
            <div key={item.id} className="mr-4 flex items-center text-xs text-muted-foreground">
              <div className={cn(' mr-2 size-3 rounded-full')} style={{ backgroundColor: COLORS[item.id] }}></div>
              <span>{item.label}</span>
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

export default CardPieChart
