import { i18nKeys } from '@/locales/keys'
import { SmartphoneIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import { useScreenDetector } from '@/hooks/useScreenDetector'
import Footer from '@/components/footer'
import Header from '@/components/header'
import Image from '@/components/image'
import Menu from '@/components/menu'

const Layout = () => {
  const { isMobile } = useScreenDetector()
  const { t } = useTranslation()

  return (
    <>
      {isMobile ? (
        <div className="min-h-screen">
          <div className="p-8">
            <Image className="mx-auto mb-12 h-10 w-auto" src="./beeo-logo.svg" alt="beeodiversity" />
          </div>
          <div className="flex flex-col items-center justify-center bg-background px-4 ">
            <div className="mx-auto max-w-md text-center">
              <SmartphoneIcon className="mx-auto size-12 text-primary" />
              <h1 className="mt-4 text-3xl font-bold tracking-tight text-foreground sm:text-4xl">
                {t(i18nKeys.global.common.noMobile.title)}
              </h1>
              <p className="mt-4 text-muted-foreground">{t(i18nKeys.global.common.noMobile.description)}</p>
            </div>
          </div>
        </div>
      ) : (
        <div className="flex flex-row">
          <Menu />
          <div className="flex min-h-screen w-full flex-col ">
            <Header />
            <main className="grow">
              <div className="grid h-full grid-cols-1 gap-4">{<Outlet />}</div>
            </main>
            <ScrollRestoration />
            <Footer />
          </div>
        </div>
      )}
    </>
  )
}

export default Layout
