import { useState } from 'react'
import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'
import SettingsSitesTableAction from '@/components/settings-sites-table-action'
import SettingsSitesTableRowAction from '@/components/settings-sites-table-row-action'

type SitesTableProps = {
  data: Site[]
  accountId?: number
}

const SettingsSitesTable = ({ data, accountId }: SitesTableProps) => {
  const { t } = useTranslation()
  const [sites, setSites] = useState<Site[]>(data)

  const sortSitesByCreatedAt = sites.sort((a, b) => {
    if (a.properties?.created_at && b.properties?.created_at) {
      return new Date(b.properties?.created_at).getTime() - new Date(a.properties?.created_at).getTime()
    }
    return 0
  })
  const columns: ColumnDef<Site>[] = [
    {
      accessorKey: 'properties.name',
      id: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.name)} />
      ),
      cell: ({ row }) => {
        const path = row.original.properties?.is_in_initiative
          ? getPath(Routes.BEEOINITIATIVE_SITE, {
              params: {
                siteId: row.original.id ?? '',
              },
            })
          : getPath(Routes.BEEOIMPACT_SITE, {
              params: {
                siteId: row.original.id ?? '',
              },
            })
        return (
          <div className="flex  space-x-2">
            <Link to={path} className="max-w-[150px] truncate">
              <span className="font-medium hover:underline">{row.getValue('name')}</span>
            </Link>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.is_in_initiative',
      id: 'initiative',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.initiative)} />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[200px] truncate font-medium">{row.getValue('initiative') ? '✔️' : ''}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.center.coordinates',
      id: 'lat',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.lat)} />
      ),
      cell: ({ row }) => {
        const center: number[] = row.getValue('lat')
        return (
          <div className="flex space-x-2">
            <span className="max-w-[50px] truncate font-medium">{center[1].toFixed(2)}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.center.coordinates',
      id: 'lng',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.lng)} />
      ),
      cell: ({ row }) => {
        const center: number[] = row.getValue('lng')
        return (
          <div className="flex space-x-2">
            <span className="max-w-[50px] truncate font-medium">{center[0].toFixed(2)}</span>
          </div>
        )
      },
    },

    {
      accessorKey: 'properties.biodiversity_radius',
      id: 'biodiversity_radius',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.radius)} />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[1000px] truncate font-medium">{row.getValue('biodiversity_radius')}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.surface_ha',
      id: 'surface_ha',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.surface)} />
      ),
      cell: ({ row }) => {
        return (
          <div className="flex space-x-2">
            <span className="max-w-[50px] truncate font-medium">{row.getValue('surface_ha')}</span>
          </div>
        )
      },
    },
    {
      accessorKey: 'properties.building_type',
      id: 'building_type',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.settings.page.sites.label.type)} />
      ),
      cell: ({ row }) => {
        const value: string = row.getValue('building_type')
        return (
          <div className="flex space-x-2">
            <span className="max-w-[1000px] truncate font-medium">
              {t(i18nKeys.global.settings.page.sites.buildingType[value])}
            </span>
          </div>
        )
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return (
          <SettingsSitesTableRowAction site={row.original} sites={sites} setSites={setSites} accountId={accountId} />
        )
      },
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={sortSitesByCreatedAt}
      filterIdColumn="name"
      action={<SettingsSitesTableAction sites={sites} setSites={setSites} accountId={accountId} />}
    />
  )
}

export default SettingsSitesTable
