import React from 'react'
import clsx from 'clsx'

import { Input } from '@/components/ui/input'

interface TextFieldType {
  value: string
  type?: string
  onChange: (value: string) => void
  onBlur?: (value: string) => void
  className?: string
  placeholder?: string
  readOnly?: boolean
  startIcon?: React.ReactNode
}

const TextField = ({
  value = '',
  type = 'text',
  onChange,
  onBlur,
  className = '',
  placeholder = '',
  readOnly = false,
  startIcon,
}: TextFieldType) => {
  const handleChange = (event) => {
    onChange(event.target.value)
  }

  const handleOnBlur = (event) => {
    if (onBlur) {
      onBlur(event.target.value)
    }
  }

  const paddingLeft = startIcon ? 'pl-10' : 'pl-3'

  return (
    <div className="flex items-center ">
      {startIcon && <div className="z-10 -mr-8">{startIcon}</div>}
      <Input
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleOnBlur}
        readOnly={readOnly}
        className={clsx(paddingLeft, className)}
      />
    </div>
  )
}

export default TextField
