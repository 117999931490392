import { PaginatedSiteList, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { Earth, MoveRight } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { Button } from '@/components/ui/button'
import { Card } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import { Icons } from '@/components/icons'

interface IndicatorCardProps {
  title: string
  icon?: React.ReactNode
  value: number
  extraIcon: React.ReactNode
  bgColor?: string
  linkText: string
  to: string
}
interface IndicatorsDashboardProps {
  sites: Site[]
  actionsTakenInitiatives?: PaginatedSiteList
  isLoading: boolean
}

const IndicatorCard = ({ title, icon, value, bgColor, linkText, to }: IndicatorCardProps) => {
  return (
    <Card
      className={cn(
        'flex max-h-[210px] flex-col justify-evenly space-y-2 sm:p-8 md:p-8 lg:p-8 xl:p-8 2xl:px-10 2xl:pb-9 2xl:pt-10',
        bgColor
      )}
    >
      <div className="flex  items-center justify-between  text-xl font-semibold">
        {title}
        {icon}
      </div>
      <div className="flex flex-col items-start justify-between space-y-2">
        <p className="text-6xl font-semibold">{value}</p>
        <div>
          <Link to={to} className="text-sm underline">
            {linkText}
          </Link>
        </div>
      </div>
    </Card>
  )
}

const IndicatorsDashboard = ({ sites, actionsTakenInitiatives, isLoading }: IndicatorsDashboardProps) => {
  const { t } = useTranslation()

  const sitesLength = sites.length ?? 0

  const numberOfTakenInitiatives = actionsTakenInitiatives?.results?.features?.reduce(
    (acc, site) => acc + (site?.properties?.actions_taken?.filter((action) => action.status === 'DONE').length ?? 0),
    0
  )
  const totalInitiativeScore = sites?.reduce((acc, site) => acc + (site?.properties?.initiative_score ?? 0), 0)
  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-4">
      {isLoading ? (
        <Skeleton className="h-[210px]" />
      ) : (
        <IndicatorCard
          title={t(i18nKeys.beeoinitiative.dashboard.site, { count: sitesLength })}
          icon={<Earth className=" size-8 text-biodivLimeFiveHundred" />}
          value={sitesLength}
          extraIcon={undefined}
          linkText={t(i18nKeys.beeoinitiative.dashboard.seeAllSites)}
          to={getPath(Routes.BEEOINITIATIVE_SITES)}
        />
      )}
      {isLoading ? (
        <Skeleton className="h-[210px]" />
      ) : (
        <IndicatorCard
          title={t(i18nKeys.beeoinitiative.dashboard.takenInits.title)}
          value={numberOfTakenInitiatives ?? 0}
          extraIcon={<MoveRight className="w-4" />}
          bgColor="bg-white"
          linkText={t(i18nKeys.beeoinitiative.dashboard.takenInits.link)}
          to={getPath(Routes.BEEOINITIATIVE_INITIATIVES)}
        />
      )}
      {isLoading ? (
        <Skeleton className="h-[210px]" />
      ) : (
        <IndicatorCard
          title={t(i18nKeys.beeoinitiative.dashboard.totalScore)}
          icon={<Icons.Leaf className="size-8 text-teal-700" />}
          value={totalInitiativeScore}
          bgColor="bg-biodivStoneHundred"
          linkText={t(i18nKeys.beeoinitiative.dashboard.learnMore)}
          to={getPath(Routes.BEEOINITIATIVE_SITES)}
          extraIcon={undefined}
        />
      )}
      {isLoading ? (
        <Skeleton className="h-[210px]" />
      ) : (
        <Card className="relative flex flex-col items-center bg-biodivTealSevenHundred text-white">
          <div className="absolute bottom-8 left-40 pt-2 ">
            <Icons.GreenCardLines />
          </div>
          <div className="flex justify-center">
            <Icons.GreenCardIcon size={84} />
          </div>
          <div className="flex flex-col items-center justify-center pb-4 text-sm">
            <p>{t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.description1)}</p>
            <p>{t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.description2)}</p>
          </div>
          <div className="z-10 sm:pb-4">
            <Button variant="outline" className="border-white bg-biodivTealSevenHundred text-sm font-semibold">
              <a href="mailto:info@beeodiversity.com?Subject=I would like to discover BeeOimpact">
                {t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.button)}
              </a>
            </Button>
          </div>
        </Card>
      )}
    </div>
  )
}
export default IndicatorsDashboard
