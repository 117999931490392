import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { Account, AccountStats, AccountStatsPesticides, PatchedAccount, SiteList, User } from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const accountsList = (signal?: AbortSignal) => {
  return backendInstance<Account[]>({ url: `/accounts/`, method: 'GET', signal })
}

export const getAccountsListQueryKey = () => {
  return [`/accounts/`] as const
}

export const getAccountsListQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsList>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsList>>> = ({ signal }) => accountsList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountsListQueryResult = NonNullable<Awaited<ReturnType<typeof accountsList>>>
export type AccountsListQueryError = ErrorType<unknown>

export const useAccountsList = <
  TData = Awaited<ReturnType<typeof accountsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsList>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const accountsCreate = (account: NonReadonly<Account>) => {
  return backendInstance<Account>({
    url: `/accounts/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: account,
  })
}

export const getAccountsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsCreate>>,
    TError,
    { data: NonReadonly<Account> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsCreate>>,
  TError,
  { data: NonReadonly<Account> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountsCreate>>, { data: NonReadonly<Account> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return accountsCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AccountsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof accountsCreate>>>
export type AccountsCreateMutationBody = NonReadonly<Account>
export type AccountsCreateMutationError = ErrorType<unknown>

export const useAccountsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsCreate>>,
    TError,
    { data: NonReadonly<Account> },
    TContext
  >
}): UseMutationResult<Awaited<ReturnType<typeof accountsCreate>>, TError, { data: NonReadonly<Account> }, TContext> => {
  const mutationOptions = getAccountsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const accountsRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<Account>({ url: `/accounts/${id}/`, method: 'GET', signal })
}

export const getAccountsRetrieveQueryKey = (id: number) => {
  return [`/accounts/${id}/`] as const
}

export const getAccountsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsRetrieve>>> = ({ signal }) =>
    accountsRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof accountsRetrieve>>>
export type AccountsRetrieveQueryError = ErrorType<unknown>

export const useAccountsRetrieve = <TData = Awaited<ReturnType<typeof accountsRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const accountsUpdate = (id: number, account: NonReadonly<Account>) => {
  return backendInstance<Account>({
    url: `/accounts/${id}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: account,
  })
}

export const getAccountsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsUpdate>>,
    TError,
    { id: number; data: NonReadonly<Account> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsUpdate>>,
  TError,
  { id: number; data: NonReadonly<Account> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsUpdate>>,
    { id: number; data: NonReadonly<Account> }
  > = (props) => {
    const { id, data } = props ?? {}

    return accountsUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AccountsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof accountsUpdate>>>
export type AccountsUpdateMutationBody = NonReadonly<Account>
export type AccountsUpdateMutationError = ErrorType<unknown>

export const useAccountsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsUpdate>>,
    TError,
    { id: number; data: NonReadonly<Account> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsUpdate>>,
  TError,
  { id: number; data: NonReadonly<Account> },
  TContext
> => {
  const mutationOptions = getAccountsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const accountsPartialUpdate = (id: number, patchedAccount: NonReadonly<PatchedAccount>) => {
  return backendInstance<Account>({
    url: `/accounts/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedAccount,
  })
}

export const getAccountsPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedAccount> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedAccount> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsPartialUpdate>>,
    { id: number; data: NonReadonly<PatchedAccount> }
  > = (props) => {
    const { id, data } = props ?? {}

    return accountsPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AccountsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof accountsPartialUpdate>>>
export type AccountsPartialUpdateMutationBody = NonReadonly<PatchedAccount>
export type AccountsPartialUpdateMutationError = ErrorType<unknown>

export const useAccountsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedAccount> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedAccount> },
  TContext
> => {
  const mutationOptions = getAccountsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const accountsDestroy = (id: number) => {
  return backendInstance<void>({ url: `/accounts/${id}/`, method: 'DELETE' })
}

export const getAccountsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof accountsDestroy>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof accountsDestroy>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof accountsDestroy>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return accountsDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type AccountsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof accountsDestroy>>>

export type AccountsDestroyMutationError = ErrorType<unknown>

export const useAccountsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof accountsDestroy>>, TError, { id: number }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof accountsDestroy>>, TError, { id: number }, TContext> => {
  const mutationOptions = getAccountsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
export const accountsRefreshStatsCreate = (id: number, account: NonReadonly<Account>) => {
  return backendInstance<Account>({
    url: `/accounts/${id}/refresh_stats/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: account,
  })
}

export const getAccountsRefreshStatsCreateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsRefreshStatsCreate>>,
    TError,
    { id: number; data: NonReadonly<Account> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof accountsRefreshStatsCreate>>,
  TError,
  { id: number; data: NonReadonly<Account> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof accountsRefreshStatsCreate>>,
    { id: number; data: NonReadonly<Account> }
  > = (props) => {
    const { id, data } = props ?? {}

    return accountsRefreshStatsCreate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AccountsRefreshStatsCreateMutationResult = NonNullable<
  Awaited<ReturnType<typeof accountsRefreshStatsCreate>>
>
export type AccountsRefreshStatsCreateMutationBody = NonReadonly<Account>
export type AccountsRefreshStatsCreateMutationError = ErrorType<unknown>

export const useAccountsRefreshStatsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof accountsRefreshStatsCreate>>,
    TError,
    { id: number; data: NonReadonly<Account> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof accountsRefreshStatsCreate>>,
  TError,
  { id: number; data: NonReadonly<Account> },
  TContext
> => {
  const mutationOptions = getAccountsRefreshStatsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const accountsRefreshTasksRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<Account>({ url: `/accounts/${id}/refresh_tasks/`, method: 'GET', signal })
}

export const getAccountsRefreshTasksRetrieveQueryKey = (id: number) => {
  return [`/accounts/${id}/refresh_tasks/`] as const
}

export const getAccountsRefreshTasksRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsRefreshTasksRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsRefreshTasksRetrieve>>, TError, TData>>
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountsRefreshTasksRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsRefreshTasksRetrieve>>> = ({ signal }) =>
    accountsRefreshTasksRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsRefreshTasksRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountsRefreshTasksRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountsRefreshTasksRetrieve>>
>
export type AccountsRefreshTasksRetrieveQueryError = ErrorType<unknown>

export const useAccountsRefreshTasksRetrieve = <
  TData = Awaited<ReturnType<typeof accountsRefreshTasksRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsRefreshTasksRetrieve>>, TError, TData>>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountsRefreshTasksRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const accountsRefreshTasksRetrieve2 = (id: number, taskId: string, signal?: AbortSignal) => {
  return backendInstance<Account>({ url: `/accounts/${id}/refresh_tasks/${taskId}/`, method: 'GET', signal })
}

export const getAccountsRefreshTasksRetrieve2QueryKey = (id: number, taskId: string) => {
  return [`/accounts/${id}/refresh_tasks/${taskId}/`] as const
}

export const getAccountsRefreshTasksRetrieve2QueryOptions = <
  TData = Awaited<ReturnType<typeof accountsRefreshTasksRetrieve2>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  taskId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsRefreshTasksRetrieve2>>, TError, TData>>
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountsRefreshTasksRetrieve2QueryKey(id, taskId)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsRefreshTasksRetrieve2>>> = ({ signal }) =>
    accountsRefreshTasksRetrieve2(id, taskId, signal)

  return { queryKey, queryFn, enabled: !!(id && taskId), ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsRefreshTasksRetrieve2>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountsRefreshTasksRetrieve2QueryResult = NonNullable<
  Awaited<ReturnType<typeof accountsRefreshTasksRetrieve2>>
>
export type AccountsRefreshTasksRetrieve2QueryError = ErrorType<unknown>

export const useAccountsRefreshTasksRetrieve2 = <
  TData = Awaited<ReturnType<typeof accountsRefreshTasksRetrieve2>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  taskId: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsRefreshTasksRetrieve2>>, TError, TData>>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountsRefreshTasksRetrieve2QueryOptions(id, taskId, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get sites for account
 */
export const accountsSitesList = (id: number, signal?: AbortSignal) => {
  return backendInstance<SiteList>({ url: `/accounts/${id}/sites/`, method: 'GET', signal })
}

export const getAccountsSitesListQueryKey = (id: number) => {
  return [`/accounts/${id}/sites/`] as const
}

export const getAccountsSitesListQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsSitesList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsSitesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountsSitesListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsSitesList>>> = ({ signal }) =>
    accountsSitesList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsSitesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountsSitesListQueryResult = NonNullable<Awaited<ReturnType<typeof accountsSitesList>>>
export type AccountsSitesListQueryError = ErrorType<unknown>

export const useAccountsSitesList = <
  TData = Awaited<ReturnType<typeof accountsSitesList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsSitesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountsSitesListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get stats for account
 */
export const accountsStatsRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<AccountStats>({ url: `/accounts/${id}/stats/`, method: 'GET', signal })
}

export const getAccountsStatsRetrieveQueryKey = (id: number) => {
  return [`/accounts/${id}/stats/`] as const
}

export const getAccountsStatsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsStatsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsStatsRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountsStatsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsStatsRetrieve>>> = ({ signal }) =>
    accountsStatsRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsStatsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountsStatsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof accountsStatsRetrieve>>>
export type AccountsStatsRetrieveQueryError = ErrorType<unknown>

export const useAccountsStatsRetrieve = <
  TData = Awaited<ReturnType<typeof accountsStatsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsStatsRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountsStatsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get stats pesticides for account
 */
export const accountsStatsPesticidesRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<AccountStatsPesticides>({ url: `/accounts/${id}/stats_pesticides/`, method: 'GET', signal })
}

export const getAccountsStatsPesticidesRetrieveQueryKey = (id: number) => {
  return [`/accounts/${id}/stats_pesticides/`] as const
}

export const getAccountsStatsPesticidesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsStatsPesticidesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsStatsPesticidesRetrieve>>, TError, TData>>
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountsStatsPesticidesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsStatsPesticidesRetrieve>>> = ({ signal }) =>
    accountsStatsPesticidesRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsStatsPesticidesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountsStatsPesticidesRetrieveQueryResult = NonNullable<
  Awaited<ReturnType<typeof accountsStatsPesticidesRetrieve>>
>
export type AccountsStatsPesticidesRetrieveQueryError = ErrorType<unknown>

export const useAccountsStatsPesticidesRetrieve = <
  TData = Awaited<ReturnType<typeof accountsStatsPesticidesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsStatsPesticidesRetrieve>>, TError, TData>>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountsStatsPesticidesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

/**
 * Get users for account
 */
export const accountsUsersList = (id: number, signal?: AbortSignal) => {
  return backendInstance<User[]>({ url: `/accounts/${id}/users/`, method: 'GET', signal })
}

export const getAccountsUsersListQueryKey = (id: number) => {
  return [`/accounts/${id}/users/`] as const
}

export const getAccountsUsersListQueryOptions = <
  TData = Awaited<ReturnType<typeof accountsUsersList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsUsersList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAccountsUsersListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof accountsUsersList>>> = ({ signal }) =>
    accountsUsersList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof accountsUsersList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AccountsUsersListQueryResult = NonNullable<Awaited<ReturnType<typeof accountsUsersList>>>
export type AccountsUsersListQueryError = ErrorType<unknown>

export const useAccountsUsersList = <
  TData = Awaited<ReturnType<typeof accountsUsersList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof accountsUsersList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAccountsUsersListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
