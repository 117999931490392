import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Outlet } from 'react-router-dom'

import { Separator } from '@/components/ui/separator'
import SettingsSidebar from '@/components/settings-sidebar'

const SettingsLayout = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="container hidden space-y-6 p-10 pb-16 md:block">
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">{t(i18nKeys.global.settings.title)}</h2>
          <p className="text-muted-foreground">{t(i18nKeys.global.settings.description)}</p>
        </div>
        <Separator className="my-6" />
        <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-12 lg:space-y-0">
          <aside className="lg:w-1/5">
            <SettingsSidebar />
          </aside>
          <div className="flex-1 lg:w-4/5">
            <Outlet />
          </div>
        </div>
      </div>
    </>
  )
}

export default SettingsLayout
