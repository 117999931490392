import { BarChartValueWithColor } from '@/types/graph'
import { Table, TableBody, TableCell, TableFooter, TableHead, TableHeader, TableRow } from '@/components/ui/table'

type SubstanceTableProps = {
  data: BarChartValueWithColor[]
  substance?: string
  headers?: string[]
}

const SubstancesTable = ({ data, substance, headers }: SubstanceTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {headers?.map((header) => (
            <TableHead key={header} className={substance === 'DIOFUR' ? 'text-right' : ''}>
              {header}
            </TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.slice(0, -1)?.map((item) => (
          <TableRow key={item.name}>
            <TableCell className="font-medium">{item.name}</TableCell>
            <TableCell>{item.local_value}</TableCell>
            <TableCell>{item.lmr}</TableCell>
            {item.teq && <TableCell>{item.teq.toFixed(5)}</TableCell>}

            <TableCell className={substance === 'DIOFUR' ? 'text-right' : ''}>{item.unit}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        {data.slice(-1).map((item) => (
          <TableRow key={item.name}>
            <TableCell className="font-medium">{item.name}</TableCell>
            <TableCell>{item.local_value}</TableCell>
            <TableCell>{!item.teq && item.lmr}</TableCell>
            {item.teq && <TableCell>{item.teq.toFixed(5)}</TableCell>}

            <TableCell />
          </TableRow>
        ))}
      </TableFooter>
    </Table>
  )
}

export default SubstancesTable
