import React from 'react'

import { Progress } from '@/components/ui/progress'
import { Separator } from '@/components/ui/separator'

interface MapPopUpProps {
  siteName: string
  title: string
  numberOfInits: React.ReactNode | number
  progressBarValue: number
}

const MapPopUp = React.memo(({ siteName, title, numberOfInits, progressBarValue }: MapPopUpProps) => {
  return (
    <div className="w-[170px] pb-3">
      {/* Site Name */}
      <h3 className="truncate text-sm font-semibold">{siteName}</h3>

      {/* Separator */}
      <Separator className="my-2 bg-biodivLimeFiveHundred" />

      {/* Title and Number of Initiatives */}
      <div className="flex justify-between text-xs">
        <span>{title}</span>
        <span className="font-semibold">{numberOfInits}</span>
      </div>

      {/* Progress Bar */}
      <Progress className="mt-2 h-2" value={progressBarValue} bgColor="bg-biodivLimeFiveHundred" />
    </div>
  )
})

export default MapPopUp
