import { SpeciesType } from '@/types/species-type'
import { Icons } from '@/components/icons'

interface SpeciesIconProps {
  value: number | undefined
  species: SpeciesType
}

export const SpeciesIcon = ({ value, species }: SpeciesIconProps) => {
  const colorDictionary: Record<number, string> = {
    10: 'text-red-500',
    5: 'text-amber-500',
    [2.5]: 'text-amber-500',
    0: 'text-green-500',
  }
  const backgroundColors: Record<number, string> = {
    10: 'bg-red-100',
    5: 'bg-amber-100',
    [2.5]: 'bg-amber-100',
    0: 'bg-green-100',
  }

  const iconDictionary = {
    [SpeciesType.Birds]: Icons.Bird,
    [SpeciesType.Mammals]: Icons.Squirrel,
    [SpeciesType.Fishes]: Icons.Fish,
    [SpeciesType.Bees]: Icons.BeeIcon,
    [SpeciesType.Worms]: Icons.WormIcon,
    [SpeciesType.Soil]: Icons.Moutain,
    [SpeciesType.Plant]: Icons.Clover,
    [SpeciesType.Squirrel]: Icons.Squirrel,
    [SpeciesType.Water]: Icons.Droplet,
  }

  const getAllValues = (value) => {
    if (value > 0 && value <= 2.5) return 2.5
    if (value > 2.5 && value <= 5) return 5
    if (value > 5) return 10
    return 0
  }
  const AnimalIcon = iconDictionary[species]

  if (value === undefined) {
    return (
      <div className="inline-flex size-8 items-center justify-center rounded-full bg-gray-100">
        <AnimalIcon className={`size-5 text-gray-300`} />
      </div>
    )
  }
  const key = getAllValues(value)
  const textColorClass = colorDictionary[key]
  const bgColorClass = backgroundColors[key]

  return (
    <div className={`inline-flex size-8 items-center justify-center rounded-full ${bgColorClass}`}>
      <AnimalIcon className={`size-5 ${textColorClass}`} />
    </div>
  )
}
