import { useState } from 'react'
import { i18nKeys } from '@/locales/keys'
import { Loader } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { Icons } from '@/components/icons'
import Sidebar, { NavItemProps } from '@/components/sidebar'

interface SectionsType {
  condition: boolean
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  icon: React.ReactNode
  title: string
  links: NavItemProps[]
}
const Nav = () => {
  const { t } = useTranslation()
  const { user } = useAuth()
  const [isOpenInit, setIsOpenInit] = useState(true)
  const [isOpenImpact, setIsOpenImpact] = useState(true)
  const [isOpenMonitoring, setIsOpenMonitoring] = useState(true)
  const [isOpenAudit, setIsOpenAudit] = useState(true)
  if (!user) return <Loader />

  const sections = [
    {
      condition:
        hasAccesTo(user.features, FeatureType.BIODIVERSITY) ||
        hasAccesTo(user.features, FeatureType.PESTICIDES) ||
        hasAccesTo(user.features, FeatureType.POTENTIAL),
      isOpen: isOpenImpact,
      onOpenChange: setIsOpenImpact,
      icon: <Icons.BeeoimpactIcon />,
      title: 'BeeOimpact',
      links: [
        {
          to: getPath(Routes.BEEOIMPACT_DASHBOARD),
          icon: <Icons.LayoutGrid className="size-4" />,
          label: 'Dashboard',
        },
        { to: getPath(Routes.BEEOIMPACT_SITES), icon: <Icons.MapPin className="size-4" />, label: 'Sites' },
        {
          to: getPath(Routes.BEEOIMPACT_METHODOLOGY),
          icon: <Icons.BookText className="size-4" />,
          label: t(i18nKeys.beeoimpact.methodology.title),
        },
      ],
    },
    {
      condition: hasAccesTo(user.features, FeatureType.MONITORING),
      isOpen: isOpenMonitoring,
      onOpenChange: setIsOpenMonitoring,
      icon: <Icons.BeeomonitoringIcon />,
      title: 'BeeOmonitoring',
      links: [{ to: getPath(Routes.BEEOMONITORING_SITES), icon: <Icons.MapPin className="size-4" />, label: 'Sites' }],
    },
    {
      condition: true,
      isOpen: isOpenAudit,
      onOpenChange: setIsOpenAudit,
      icon: <Icons.BeeoauditIcon />,
      title: 'BeeOaudit',
      links: [{ to: getPath(Routes.BEEOAUDIT_SITES), icon: <Icons.MapPin className="size-4" />, label: 'Sites' }],
    },
    // {
    //   condition: true,
    //   isOpen: isOpenMetrics,
    //   onOpenChange: setIsOpenMetrics,
    //   icon: <Icons.BeeometricsIcon />,
    //   title: 'BeeOmetrics',
    //   links: [
    //     {
    //       to: '/',
    //       label: t(i18nKeys.global.nav.beeometrics.menuLink.description),
    //     },
    //   ],
    // },
    {
      condition: hasAccesTo(user.features, FeatureType.INITIATIVE),
      isOpen: isOpenInit,
      onOpenChange: setIsOpenInit,
      icon: <Icons.BeeoinitIcon />,
      title: 'BeeOinitiative',
      links: [
        {
          to: getPath(Routes.BEEOINITIATIVE_DASHBOARD),
          icon: <Icons.LayoutGrid className="size-4" />,
          label: 'Dashboard',
        },
        { to: getPath(Routes.BEEOINITIATIVE_SITES), icon: <Icons.MapPin className="size-4" />, label: 'Sites' },
        {
          to: getPath(Routes.BEEOINITIATIVE_INITIATIVES),
          icon: <Icons.Leaf className="size-4" />,
          label: 'Initiatives',
        },
      ],
    },
  ] as SectionsType[]

  return (
    <nav className="text-sm font-medium">
      {sections.map(
        (section, i) =>
          section.condition && (
            <Sidebar
              key={i}
              isOpen={section.isOpen}
              onOpenChange={section.onOpenChange}
              icon={section.icon}
              title={section.title}
              links={section.links}
            />
          )
      )}
    </nav>
  )
}

export default Nav
