import React from 'react'
import { AXIOS_INSTANCE } from '@/client/backend/backend-instance'
import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

import { STORAGE_KEYS } from '@/lib/constants/local-storage'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'

import { AvailableLanguage } from './../types/available-language'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const setHeaders = (token: string, lang: AvailableLanguage) => {
  AXIOS_INSTANCE.defaults.headers.common['Accept-Language'] = lang
  AXIOS_INSTANCE.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const setLanguageHeader = (lang: AvailableLanguage) => {
  return (AXIOS_INSTANCE.defaults.headers.common['Accept-Language'] = lang)
}

// FIXME: This function should be removed when properly initialized in router
export const setupDefaultHeaders = () => {
  const token = localStorage.getItem(STORAGE_KEYS.TOKEN) ?? ''
  const language =
    (localStorage.getItem(STORAGE_KEYS.LAST_SELECTED_LANGUAGE) as AvailableLanguage) ?? AvailableLanguage.FR
  setHeaders(token, language)
}

export const goToLoginPage = () => {
  return setTimeout(() => {
    window.location.href = getPath(Routes.AUTH_LOGIN)
  }, 5000)
}

export function getValidChildren(children: React.ReactNode) {
  return React.Children.toArray(children).filter((child) => React.isValidElement(child)) as React.ReactElement[]
}

export const IS_DEV = Boolean(__DEV__)
