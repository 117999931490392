import { SpectralIndex } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { CartesianGrid, LabelList, Legend, Line, LineChart, ReferenceLine, XAxis, YAxis } from 'recharts'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from '@/components/ui/chart'

const transformChartData = (data) => {
  const ndwiData = data.filter((item) => item.index_type === 'NDWI')
  const ndviData = data.filter((item) => item.index_type === 'NDVI')

  const allDates = [...new Set(data.map((item) => item.start_date))].sort()

  const transformedData = allDates.map((date) => {
    const ndwiItem = ndwiData.find((item) => item.start_date === date)
    const ndviItem = ndviData.find((item) => item.start_date === date)

    return {
      date,
      NDWI: ndwiItem ? ndwiItem.mean_value : null,
      NDVI: ndviItem ? ndviItem.mean_value : null,
    }
  })

  return transformedData
}

const chartConfig = {
  NDWI: {
    label: 'NDWI',
    color: 'hsl(var(--biodiv-blueSixHundred))',
  },
  NDVI: {
    label: 'NDVI',
    color: 'hsl(var(--biodiv-tealSevenHundred))',
  },
} satisfies ChartConfig

type CardSpectralProps = {
  data?: SpectralIndex[]
}

const CardSpectral = ({ data }: CardSpectralProps) => {
  const { t } = useTranslation()
  const transformedChartData = transformChartData(data)
  const today = new Date().toISOString().split('T')[0]

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t(i18nKeys.beeoimpact.biodiversityTable.spectralIndices.card.title)}</CardTitle>
        <CardDescription>{t(i18nKeys.beeoimpact.biodiversityTable.spectralIndices.card.description)}</CardDescription>
      </CardHeader>
      <CardContent>
        {transformedChartData.length > 0 ? (
          <ChartContainer config={chartConfig} className="max-h-[260px] w-full">
            <LineChart
              accessibilityLayer
              data={transformedChartData}
              margin={{
                top: 50,
                left: -10,
                right: 14,
              }}
            >
              <CartesianGrid vertical={false} />
              <XAxis
                dataKey="date"
                tickLine={false}
                axisLine={false}
                tickMargin={8}
                tickFormatter={(value) => new Date(value).toLocaleDateString()}
              />
              <YAxis tickLine={false} axisLine={false} tickMargin={8} />
              <ChartTooltip cursor={false} content={<ChartTooltipContent indicator="line" />} />
              <Legend
                verticalAlign="bottom"
                height={36}
                iconType="circle"
                wrapperStyle={{
                  margin: '-20px', // This adds some space below the legend
                }}
              />
              <ReferenceLine x={today} stroke="red" strokeDasharray="3 3" label={{ value: 'Today', position: 'top' }} />
              <Line
                dataKey="NDWI"
                type="monotone"
                stroke={chartConfig.NDWI.color}
                strokeWidth={2}
                dot={{
                  fill: chartConfig.NDWI.color,
                }}
                activeDot={{
                  r: 6,
                }}
                connectNulls
              >
                <LabelList
                  dataKey="NDWI"
                  position="top"
                  offset={10}
                  formatter={(value) => (value ? value.toFixed(2) : '')}
                />
              </Line>
              <Line
                dataKey="NDVI"
                type="monotone"
                stroke={chartConfig.NDVI.color}
                strokeWidth={2}
                dot={{
                  fill: chartConfig.NDVI.color,
                }}
                activeDot={{
                  r: 6,
                }}
                connectNulls
              >
                <LabelList
                  dataKey="NDVI"
                  position="top"
                  offset={10}
                  formatter={(value) => (value ? value.toFixed(2) : '')}
                />
              </Line>
            </LineChart>
          </ChartContainer>
        ) : (
          <p>{t(i18nKeys.beeoimpact.biodiversityTable.spectralIndices.noData)}</p>
        )}
      </CardContent>
    </Card>
  )
}

export default CardSpectral
