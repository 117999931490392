import { QueryClient } from '@tanstack/react-query'

export const QUERY_CLIENT = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      // staleTime: 3600000 * 2, // 2h
      // For testing purposes, we set the cache time to 0
      staleTime: 0,
    },
  },
})
