import { Initiative, Question, Response } from '@/client/backend/models'
import { Site } from '@/client/backend/models/site'
import { i18nKeys } from '@/locales/keys'
import InitiativeCard from '@/pages/beeoinitiative/initiatives/initiative-card'
import { useTranslation } from 'react-i18next'

import { Card, CardContent } from '@/components/ui/card'
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from '@/components/ui/carousel'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Skeleton } from '@/components/ui/skeleton'
import SiteQuestions from '@/components/beeoinitiative/site-questions'

interface InitCardsCarouselProps {
  site?: Site
  filteredInitsBasedOnResponses?: Initiative[]
  initiativesCount: number
  questions?: Question[]
  responses?: Response[]
  isLoading: boolean
}
const InitCardsCarousel = ({
  site,
  filteredInitsBasedOnResponses,
  initiativesCount,
  questions,
  responses,
  isLoading,
}: InitCardsCarouselProps) => {
  const { t } = useTranslation()

  if (isLoading) {
    return <Skeleton className="h-96 w-full" />
  }
  return (
    <>
      <div className="flex items-end justify-between">
        <div>
          <p className="pb-2.5 text-xl font-semibold">{t(i18nKeys.beeoinitiative.site.suggestedInits)}</p>
          <p className="pb-3 text-sm">
            <span>{t(i18nKeys.beeoinitiative.site.answerInits)} </span>
            <span className="font-semibold text-biodivTealSevenHundred">{initiativesCount}</span>
            <span className="px-1 font-semibold text-biodivTealSevenHundred">
              {t(i18nKeys.beeoinitiative.site.availableInits)}
            </span>
            <span>{t(i18nKeys.beeoinitiative.site.forYourSite)}</span>
          </p>
        </div>
        <Sheet>
          <SheetTrigger asChild>
            <p className="cursor-pointer pb-3 text-sm underline">{t(i18nKeys.beeoinitiative.site.modifyMyAnswers)}</p>
          </SheetTrigger>
          <SheetContent side="right">
            <SheetHeader>
              <SheetTitle> {t(i18nKeys.beeoinitiative.common.sheet.title)}</SheetTitle>
              <SheetDescription>{t(i18nKeys.beeoinitiative.common.sheet.description)}</SheetDescription>
            </SheetHeader>
            <SiteQuestions site={site} questions={questions ?? []} responses={responses ?? []} />
          </SheetContent>
        </Sheet>
      </div>
      <Carousel
        opts={{
          align: 'start',
        }}
        className="w-full"
      >
        <CarouselContent>
          {filteredInitsBasedOnResponses?.map((initiative, index) => (
            <CarouselItem
              key={index}
              className="h-[420px] sm:max-w-[337px] md:max-w-[337px] lg:max-w-[337px] xl:max-w-[357px] 2xl:max-w-[383px]"
            >
              <div>
                <Card>
                  <CardContent className="size-full p-0">
                    <InitiativeCard initiative={initiative} key={initiative.id} site={site} isLoading={isLoading} />
                  </CardContent>
                </Card>
              </div>
            </CarouselItem>
          ))}
        </CarouselContent>

        <CarouselPrevious className="-left-10 top-1/2 -translate-y-1/2" />
        <CarouselNext className="-right-10 top-1/2 -translate-y-1/2" />
      </Carousel>
    </>
  )
}

export default InitCardsCarousel
