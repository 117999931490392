import { PlotCulture } from '@/client/backend/models'

import { CultureArea } from '@/types/culture-area'

export const groupCultures = (plots: PlotCulture[]): CultureArea[] => {
  const cultures = plots.map(({ culture_description, intersection_surface_ha, id }) => ({
    name: culture_description,
    area: intersection_surface_ha,
    id,
  }))

  const cultureTypes = cultures.reduce(
    (group: Record<string, { total_surface: number; plots_ids: number[] }>, { name, area, id }) => {
      // Ensure that group[name] is treated as a number

      name !== null
        ? (group[name] = {
            total_surface: (group[name]?.total_surface ?? 0) + Number(area),
            plots_ids: [...(group[name]?.plots_ids ?? []), id],
          })
        : null
      return group
    },
    {} as Record<string, { total_surface: number; plots_ids: number[] }>
  )

  const namesAreas = Object.entries(cultureTypes).map(([name, { total_surface, plots_ids }]) => ({
    name,
    total_surface,
    plots_ids,
  }))

  return namesAreas.sort((a, b) => b.total_surface - a.total_surface)
}
