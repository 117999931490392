import { HelpCircle } from 'lucide-react'

import { Card, CardContent, CardHeader } from '@/components/ui/card'

type MarketingCardProps = {
  text: string
  contact: string
}

const MarketingCard = ({ text, contact }: MarketingCardProps) => {
  return (
    <Card className="col-span-3 opacity-50">
      <CardHeader />
      <CardContent className="grid gap-4 opacity-60">
        <div className="mx-auto mt-6 flex max-w-[420px] flex-col items-center justify-center text-center">
          <HelpCircle className="size-28" />

          <h3 className="mt-4 text-lg font-semibold">{text}</h3>
          <a href="mailto:info@beeodiversity.com" className="mb-4 mt-2 text-sm underline">
            {contact}
          </a>
        </div>
      </CardContent>
    </Card>
  )
}

export default MarketingCard
