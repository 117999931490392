import { z as zod } from 'zod'

export const auditSchema = zod.object({
  site_id: zod.number().min(0),
  date: zod.date().default(() => new Date()),
  surface_sq_meters: zod.coerce.number().min(0),
  vegetal_cover_percentage: zod.coerce.number().min(0).max(100),
  surface_biotope_coefficient: zod.coerce.number().min(0).max(1),
  amount_birds: zod.coerce.number().min(0),
  amount_mammals: zod.coerce.number().min(0),
  amount_insects: zod.coerce.number().min(0),
  amount_vegetal_species: zod.coerce.number().min(0),
  amount_reptiles: zod.coerce.number().min(0),
  amount_trees: zod.coerce.number().min(0),
  indigenous_vegetal_percentage: zod.coerce.number().min(0).max(100),
})

export type AuditFormValues = zod.infer<typeof auditSchema>
