import { useQuery } from '@tanstack/react-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { PhytoCulture, PhytoCulturesListParams } from '../../models'

export const phytoCulturesList = (params?: PhytoCulturesListParams, signal?: AbortSignal) => {
  return backendInstance<PhytoCulture[]>({ url: `/phyto-cultures/`, method: 'GET', params, signal })
}

export const getPhytoCulturesListQueryKey = (params?: PhytoCulturesListParams) => {
  return [`/phyto-cultures/`, ...(params ? [params] : [])] as const
}

export const getPhytoCulturesListQueryOptions = <
  TData = Awaited<ReturnType<typeof phytoCulturesList>>,
  TError = ErrorType<unknown>,
>(
  params?: PhytoCulturesListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof phytoCulturesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPhytoCulturesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof phytoCulturesList>>> = ({ signal }) =>
    phytoCulturesList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof phytoCulturesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PhytoCulturesListQueryResult = NonNullable<Awaited<ReturnType<typeof phytoCulturesList>>>
export type PhytoCulturesListQueryError = ErrorType<unknown>

export const usePhytoCulturesList = <
  TData = Awaited<ReturnType<typeof phytoCulturesList>>,
  TError = ErrorType<unknown>,
>(
  params?: PhytoCulturesListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof phytoCulturesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPhytoCulturesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const phytoCulturesRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<PhytoCulture>({ url: `/phyto-cultures/${id}/`, method: 'GET', signal })
}

export const getPhytoCulturesRetrieveQueryKey = (id: number) => {
  return [`/phyto-cultures/${id}/`] as const
}

export const getPhytoCulturesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof phytoCulturesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof phytoCulturesRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPhytoCulturesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof phytoCulturesRetrieve>>> = ({ signal }) =>
    phytoCulturesRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof phytoCulturesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PhytoCulturesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof phytoCulturesRetrieve>>>
export type PhytoCulturesRetrieveQueryError = ErrorType<unknown>

export const usePhytoCulturesRetrieve = <
  TData = Awaited<ReturnType<typeof phytoCulturesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof phytoCulturesRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPhytoCulturesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
