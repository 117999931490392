import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Badge } from '@/components/ui/badge'
import { Card } from '@/components/ui/card'
import { Icons } from '@/components/icons'

interface SpeciesCardsType {
  categories: StatsType
}
interface StatsType {
  [key: string]: {
    VU: number
    EN: number
    CR: number
    NT: number
    LC: number
    Other: number
    TotalMainCategs: number
  }
}
const SpeciesCards = ({ categories }: SpeciesCardsType) => {
  const { t } = useTranslation()

  const showIconForGroup = (group: string) => {
    if (group == 'Mammals') {
      return <Icons.Rabbit size={28} />
    } else if (group == 'Birds') {
      return <Icons.Bird size={28} />
    } else if (group == 'Reptiles') {
      return <Icons.Turtle size={28} />
    } else if (group == 'Amphibians') {
      return <Icons.FrogIcon />
    } else if (group == 'Fish') {
      return <Icons.Fish size={28} />
    } else if (group == 'Invertebrates') {
      return <Icons.Snail size={28} />
    } else if (group == 'Plants') {
      return <Icons.Clover size={28} />
    } else {
      return ''
    }
  }

  return (
    <div className="flex flex-row flex-wrap">
      {' '}
      {Object.entries(categories).map(([key, value]) => {
        return (
          <div key={key} className="flex flex-row items-center justify-between pb-4 pr-4">
            {showIconForGroup(key) && (
              <Card className="flex min-h-28 min-w-[25rem] flex-row items-center justify-between p-2">
                <div className="flex w-12 flex-col">
                  <span>{showIconForGroup(key)}</span>
                  <span className="pl-1 text-2xl">{value.TotalMainCategs}</span>
                </div>
                <div>
                  <div className="flex justify-start">
                    {t(i18nKeys.beeoimpact.biodiversityTable.speciesGroups[key])}
                  </div>
                  <div className="flex flex-row space-x-1">
                    <Badge className="h-8 bg-green-400 ">{value.LC} LC</Badge>
                    <Badge className="h-8 bg-green-700 ">{value.NT} NT</Badge>
                    <Badge className="h-8 bg-yellow-500 ">{value.VU} VU</Badge>
                    <Badge className="h-8 bg-orange-500 ">{value.EN} EN</Badge>
                    <Badge className="h-8 bg-ruby hover:bg-ruby-hover">{value.CR} CR</Badge>
                    <Badge className="h-8 bg-white text-black hover:text-white">
                      {t(i18nKeys.beeoimpact.biodiversityTable.iucn.other, {
                        count: value.Other,
                      })}
                    </Badge>
                  </div>
                </div>
              </Card>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default SpeciesCards
