import Markdown from 'react-markdown'

import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'

interface InitiativeDetailDescriptionProps {
  title: string
  bgColor: string
  description?: string | null
  borderColor?: string
  icon?: JSX.Element
  iconColor?: string
  isLoading?: boolean
  size?: string
}

const InitiativeDetailDescription = ({
  title,
  bgColor,
  description,
  borderColor,
  icon,
  iconColor,
  isLoading,
  size,
}: InitiativeDetailDescriptionProps) => {
  if (isLoading) {
    return <Skeleton className={size} />
  }
  return (
    <Card className={`drop-shadow-sm ${bgColor} ${borderColor}`}>
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>{title}</CardTitle>
          {icon && <div className={`text-3xl ${iconColor}`}>{icon}</div>}
        </div>
      </CardHeader>
      <CardContent>
        <Markdown
          components={{
            ul(props) {
              return (
                <ul className=" relative list-disc flex-col space-y-6 pl-6 text-biodivLimeFiveHundred">
                  {props.children}
                </ul>
              )
            },
            li(props) {
              return (
                <li className="text-xl">
                  <span className="text-base text-black">{props.children}</span>
                </li>
              )
            },
            h2(props) {
              const children = props.children as string
              const number = children.split(' ')[0]
              const text = children.split(' ').slice(1).join(' ')
              return (
                <div className="mb-6 mt-10 flex">
                  <div className="text-lg font-bold text-biodivLimeFiveHundred">{number}</div>
                  <Separator className="mx-3 h-7" orientation="vertical" />
                  <h2 className="text-lg font-bold text-biodivTealSevenHundred">{text}</h2>
                </div>
              )
            },
          }}
        >
          {description}
        </Markdown>
      </CardContent>
    </Card>
  )
}

export default InitiativeDetailDescription
