import { i18nKeys } from '@/locales/keys'
import { t } from 'i18next'
import { Search } from 'lucide-react'
import { SetURLSearchParams } from 'react-router-dom'

import { Input } from '@/components/ui/input'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

type SiteFilterProps = {
  nameFilter: string
  setNameFilter: (name: string) => void
  setSelectedName: (name: string) => void
  setSearchParams: SetURLSearchParams
  selectedName: string
}
const SiteFilter = ({ nameFilter, setNameFilter, setSelectedName, setSearchParams, selectedName }: SiteFilterProps) => {
  const onSelectChange = (value: string) => {
    setSelectedName(value)
    if (value === 'inactiveSites') {
      setSearchParams({ inactiveSites: 'true' })
    } else {
      setSearchParams({})
    }
  }
  return (
    <div className="flex flex-row items-center space-x-4">
      <div className="relative flex w-[170px] items-center ">
        <Search className="absolute ml-2 w-4 text-gray-400" />
        <Input
          value={nameFilter}
          type="text"
          placeholder={t(i18nKeys.beeoinitiative.sites.searchSites)}
          onChange={(e) => setNameFilter(e.target.value)}
          className="rounded-md border border-separatorGray pl-8  text-biodivZincFiveHundred focus:border-black "
        />
      </div>
      <div className="w-[170px]">
        <Select onValueChange={onSelectChange} value={selectedName}>
          <SelectTrigger>
            <SelectValue placeholder={t(i18nKeys.beeoinitiative.sites.display)} />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="all">{t(i18nKeys.beeoinitiative.sites.all)}</SelectItem>
            <SelectItem value="activeSites">{t(i18nKeys.beeoinitiative.sites.activeSites)}</SelectItem>
            <SelectItem value="inactiveSites">{t(i18nKeys.beeoinitiative.sites.inactiveSites)}</SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}

export default SiteFilter
