import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Icons } from '@/components/icons'

interface RenderTooltipProps {
  explanationText: string
  size: number
}
const RenderTooltip = ({ explanationText, size }: RenderTooltipProps) => {
  return (
    <Popover>
      <PopoverTrigger>
        <Icons.Info size={size} />
      </PopoverTrigger>
      <PopoverContent className="w-96 bg-black text-white">{explanationText}</PopoverContent>
    </Popover>
  )
}

export default RenderTooltip
