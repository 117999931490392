import { z } from 'zod'

export const ActivateUserSchema = z
  .object({
    newPassword: z.string().nonempty(),
    confirmNewPassword: z.string().nonempty(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ['confirmNewPassword'],
    params: {
      i18n: 'passwordsNoMatch',
    },
  })

export type ActivateUserFormValues = z.infer<typeof ActivateUserSchema>
