import { useEffect } from 'react'
import { auditsCreate, auditsUpdate } from '@/client/backend/api/audits/audits'
import { Audit, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'
import { format } from 'date-fns'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { cn } from '@/lib/utils'
import { AuditFormValues, auditSchema } from '@/lib/validation/add-audit-schema'
import { Button } from '@/components/ui/button'
import { Calendar } from '@/components/ui/calendar'
import { Form, FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Label } from '@/components/ui/label'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Separator } from '@/components/ui/separator'
import { Slider } from '@/components/ui/slider'
import { toast } from '@/components/ui/use-toast'
import { Icons } from '@/components/icons'

type AuditFormProps = {
  audit?: Audit
  site: Site
  setIsFormDirty?: (isDirty: boolean) => void
  setIsOpen?: (isOpen: boolean) => void
  onAuditSave?: (newAudit) => void
}

const defaultAuditFormValues = (site: Site, audit?: Audit) => {
  if (audit) {
    const { date, ...otherAuditProps } = audit
    return {
      ...otherAuditProps,
      date: new Date(date),
      site_id: site.id,
    }
  } else {
    return {
      site_id: site.id,
      date: new Date(),
      surface_sq_meters: 0,
      vegetal_cover_percentage: 0,
      surface_biotope_coefficient: 0,
      amount_birds: 0,
      amount_mammals: 0,
      amount_insects: 0,
      amount_vegetal_species: 0,
      amount_reptiles: 0,
      amount_trees: 0,
      indigenous_vegetal_percentage: 0,
    }
  }
}

const AuditForm = ({ site, audit, setIsFormDirty, setIsOpen, onAuditSave }: AuditFormProps) => {
  const { t } = useTranslation()
  const form = useForm<AuditFormValues>({
    resolver: zodResolver(auditSchema),
    defaultValues: defaultAuditFormValues(site, audit),
    shouldUnregister: false,
    mode: 'onSubmit',
  })
  const onSubmit = async ({ date, ...formValues }: AuditFormValues) => {
    const newDate = format(date, 'yyyy-MM-dd')
    try {
      const newAudit = audit
        ? await auditsUpdate(audit.id, {
            date: newDate,
            ...formValues,
          })
        : await auditsCreate({
            date: newDate,
            ...formValues,
          })
      onAuditSave?.(newAudit)
      setIsOpen?.(false)
      toast({
        title: t(i18nKeys.beeoaudit.auditForm.notification.success),
      })
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.beeoaudit.auditForm.notification.success),
          description: error?.message,
        })
      }
    }
  }

  // Avoid submitting the form and force close the sheet
  const cancel = (e) => {
    e.preventDefault()
    setIsOpen?.(false)
  }

  // Send upwards the form dirty state
  useEffect(() => {
    setIsFormDirty?.(form.formState.isDirty)
  }, [form.formState.isDirty])

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormItem>
          <FormLabel>Site</FormLabel>
          <Input disabled readOnly value={site?.properties?.name} />
        </FormItem>
        <FormField
          control={form.control}
          name="date"
          render={({ field }) => (
            <FormItem className="flex flex-col">
              <FormLabel>{t(i18nKeys.beeoaudit.auditForm.auditDate)}</FormLabel>
              <Popover>
                <PopoverTrigger asChild>
                  <FormControl>
                    <Button
                      variant={'outline'}
                      className={cn('pl-3 text-left font-normal', !field.value && 'text-muted-foreground')}
                    >
                      {field.value ? format(field.value, 'dd/MM/yyyy') : <span>Pick a date</span>}
                      <Icons.Calendar className="ml-auto size-4 opacity-50" />
                    </Button>
                  </FormControl>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0" align="start">
                  <Calendar mode="single" selected={field.value} onSelect={field.onChange} initialFocus />
                </PopoverContent>
              </Popover>
              <FormMessage />
            </FormItem>
          )}
        />

        <Separator className="mb-6" />

        <FormField
          control={form.control}
          name="surface_sq_meters"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.beeoaudit.auditForm.superficy)}</FormLabel>
              <FormControl>
                <div className="relative">
                  <Input {...field} />
                  <span className="absolute right-3.5 top-2.5 size-4 text-sm text-muted-foreground">
                    m<sup>2</sup>
                  </span>
                </div>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name="vegetal_cover_percentage"
          render={({ field }) => (
            <div className="grid gap-4">
              <div className="flex items-center justify-between">
                <Label htmlFor={field.name}>{t(i18nKeys.beeoaudit.auditForm.vegetalCoverPc)}</Label>
                <Input
                  min={0}
                  max={100}
                  {...field}
                  className="w-12 rounded-md border border-transparent px-2 py-0.5 text-right text-sm text-muted-foreground hover:border-border"
                />
              </div>
              <Slider value={[field.value]} step={1} min={0} max={100} onValueChange={field.onChange} />
              <FormMessage />
            </div>
          )}
        />
        <FormField
          control={form.control}
          name="surface_biotope_coefficient"
          render={({ field }) => (
            <div className="grid gap-4">
              <div className="flex items-center justify-between">
                <Label htmlFor={field.name}>{t(i18nKeys.beeoaudit.auditForm.surfaceBiotopeCoefficient)}</Label>
                <Input
                  min={0}
                  max={1}
                  {...field}
                  className="w-12 rounded-md border border-transparent px-2 py-0.5 text-right text-sm text-muted-foreground hover:border-border"
                />
              </div>
              <Slider value={[field.value]} step={0.05} min={0} max={1} onValueChange={field.onChange} />
              <FormMessage />
            </div>
          )}
        />

        <Separator className="mb-6" />

        <FormField
          control={form.control}
          name="amount_birds"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.beeoaudit.auditForm.amountBirds)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="amount_mammals"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.beeoaudit.auditForm.amountMammals)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="amount_insects"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.beeoaudit.auditForm.amountInsects)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="amount_vegetal_species"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.beeoaudit.auditForm.amountVegetalSpecies)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="amount_reptiles"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.beeoaudit.auditForm.amountReptiles)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="amount_trees"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t(i18nKeys.beeoaudit.auditForm.amountTrees)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
              <FormDescription>{t(i18nKeys.beeoaudit.auditForm.amountTreesDescription)}</FormDescription>
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="indigenous_vegetal_percentage"
          render={({ field }) => (
            <div className="grid gap-4">
              <div className="flex items-center justify-between">
                <Label htmlFor={field.name}>{t(i18nKeys.beeoaudit.auditForm.indigenousVegetalPc)}</Label>
                <Input
                  min={0}
                  max={1}
                  {...field}
                  className="w-12 rounded-md border border-transparent px-2 py-0.5 text-right text-sm text-muted-foreground hover:border-border"
                />
              </div>
              <Slider value={[field.value]} step={1} min={0} max={100} onValueChange={field.onChange} />
              <FormMessage />
            </div>
          )}
        />
        <div className="flex justify-end">
          <Button type="submit" disabled={form.formState.isSubmitting} className="mr-2">
            {t(i18nKeys.beeoaudit.auditForm.save)}
          </Button>
          <Button variant="secondary" onClick={cancel}>
            {t(i18nKeys.beeoaudit.auditForm.cancel)}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default AuditForm
