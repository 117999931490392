import { NavLink } from 'react-router-dom'

import { BreadcrumbPage } from '@/components/ui/breadcrumb'
import { Breadcrumb, BreadcrumbItem } from '@/components/breadcrumb'

interface BreadcrumbItemProps {
  label: string
  href?: string
}

interface BreadcrumbComponentProps {
  breadcrumbItems: BreadcrumbItemProps[]
  currentPage?: string
}

const TEXT_CLASSNAME = 'text-xs hover:underline'

const BreadcrumbComponent = ({ breadcrumbItems, currentPage }: BreadcrumbComponentProps) => {
  if (breadcrumbItems.length === 0) return null

  return (
    <div className="mb-4 mt-6">
      <Breadcrumb>
        {breadcrumbItems.map(({ label, href }, index) => (
          <BreadcrumbItem key={`${label}-${index}`}>
            {href ? (
              <NavLink to={href} className={TEXT_CLASSNAME}>
                {label}
              </NavLink>
            ) : (
              <BreadcrumbPage className={TEXT_CLASSNAME}>{label}</BreadcrumbPage>
            )}
          </BreadcrumbItem>
        ))}
        {currentPage && (
          <BreadcrumbItem>
            <BreadcrumbPage className={TEXT_CLASSNAME}>{currentPage}</BreadcrumbPage>
          </BreadcrumbItem>
        )}
      </Breadcrumb>
    </div>
  )
}

export default BreadcrumbComponent
