import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { Audit, PatchedAudit } from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const auditsList = (signal?: AbortSignal) => {
  return backendInstance<Audit[]>({ url: `/audits/`, method: 'GET', signal })
}

export const getAuditsListQueryKey = () => {
  return [`/audits/`] as const
}

export const getAuditsListQueryOptions = <
  TData = Awaited<ReturnType<typeof auditsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditsList>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAuditsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auditsList>>> = ({ signal }) => auditsList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auditsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuditsListQueryResult = NonNullable<Awaited<ReturnType<typeof auditsList>>>
export type AuditsListQueryError = ErrorType<unknown>

export const useAuditsList = <TData = Awaited<ReturnType<typeof auditsList>>, TError = ErrorType<unknown>>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditsList>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuditsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const auditsCreate = (audit: NonReadonly<Audit>) => {
  return backendInstance<Audit>({
    url: `/audits/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: audit,
  })
}

export const getAuditsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auditsCreate>>,
    TError,
    { data: NonReadonly<Audit> },
    TContext
  >
}): UseMutationOptions<Awaited<ReturnType<typeof auditsCreate>>, TError, { data: NonReadonly<Audit> }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof auditsCreate>>, { data: NonReadonly<Audit> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return auditsCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuditsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof auditsCreate>>>
export type AuditsCreateMutationBody = NonReadonly<Audit>
export type AuditsCreateMutationError = ErrorType<unknown>

export const useAuditsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auditsCreate>>,
    TError,
    { data: NonReadonly<Audit> },
    TContext
  >
}): UseMutationResult<Awaited<ReturnType<typeof auditsCreate>>, TError, { data: NonReadonly<Audit> }, TContext> => {
  const mutationOptions = getAuditsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const auditsRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<Audit>({ url: `/audits/${id}/`, method: 'GET', signal })
}

export const getAuditsRetrieveQueryKey = (id: number) => {
  return [`/audits/${id}/`] as const
}

export const getAuditsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof auditsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditsRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getAuditsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof auditsRetrieve>>> = ({ signal }) => auditsRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof auditsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type AuditsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof auditsRetrieve>>>
export type AuditsRetrieveQueryError = ErrorType<unknown>

export const useAuditsRetrieve = <TData = Awaited<ReturnType<typeof auditsRetrieve>>, TError = ErrorType<unknown>>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof auditsRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getAuditsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const auditsUpdate = (id: number, audit: NonReadonly<Audit>) => {
  return backendInstance<Audit>({
    url: `/audits/${id}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: audit,
  })
}

export const getAuditsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auditsUpdate>>,
    TError,
    { id: number; data: NonReadonly<Audit> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof auditsUpdate>>,
  TError,
  { id: number; data: NonReadonly<Audit> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auditsUpdate>>,
    { id: number; data: NonReadonly<Audit> }
  > = (props) => {
    const { id, data } = props ?? {}

    return auditsUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuditsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof auditsUpdate>>>
export type AuditsUpdateMutationBody = NonReadonly<Audit>
export type AuditsUpdateMutationError = ErrorType<unknown>

export const useAuditsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auditsUpdate>>,
    TError,
    { id: number; data: NonReadonly<Audit> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof auditsUpdate>>,
  TError,
  { id: number; data: NonReadonly<Audit> },
  TContext
> => {
  const mutationOptions = getAuditsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const auditsPartialUpdate = (id: number, patchedAudit: NonReadonly<PatchedAudit>) => {
  return backendInstance<Audit>({
    url: `/audits/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedAudit,
  })
}

export const getAuditsPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auditsPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedAudit> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof auditsPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedAudit> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof auditsPartialUpdate>>,
    { id: number; data: NonReadonly<PatchedAudit> }
  > = (props) => {
    const { id, data } = props ?? {}

    return auditsPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuditsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof auditsPartialUpdate>>>
export type AuditsPartialUpdateMutationBody = NonReadonly<PatchedAudit>
export type AuditsPartialUpdateMutationError = ErrorType<unknown>

export const useAuditsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof auditsPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedAudit> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof auditsPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedAudit> },
  TContext
> => {
  const mutationOptions = getAuditsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const auditsDestroy = (id: number) => {
  return backendInstance<void>({ url: `/audits/${id}/`, method: 'DELETE' })
}

export const getAuditsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof auditsDestroy>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof auditsDestroy>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof auditsDestroy>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return auditsDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuditsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof auditsDestroy>>>

export type AuditsDestroyMutationError = ErrorType<unknown>

export const useAuditsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof auditsDestroy>>, TError, { id: number }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof auditsDestroy>>, TError, { id: number }, TContext> => {
  const mutationOptions = getAuditsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
