import React, { Dispatch, SetStateAction } from 'react'
import { accountsCreate, accountsUpdate } from '@/client/backend/api/accounts/accounts'
import { Account, FeaturesEnum } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { t } from 'i18next'
import { Loader } from 'lucide-react'
import { useForm } from 'react-hook-form'

import { AddAccountFormValues, addAccountSchema } from '@/lib/validation/add-account-schema'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'
import { MultiSelect } from '@/components/multi-select'

type AccountFormProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setAccounts: Dispatch<SetStateAction<Account[]>>
  accounts: Account[]
  account?: Account
}

const AccountForm = ({ accounts, account, setOpen, setAccounts }: AccountFormProps) => {
  const { user } = useAuth()

  const formRef = React.useRef<HTMLFormElement>(null)

  const form = useForm<AddAccountFormValues>({
    resolver: zodResolver(addAccountSchema),
    defaultValues: {
      name: account?.name || '',
      features: account?.features || [FeaturesEnum.biodiversity, FeaturesEnum.pesticides],
    },
    shouldUnregister: false,
    mode: 'onSubmit',
  })

  if (!user) return <Loader />

  const onSubmit = async (data) => {
    if (account) {
      try {
        const updatedAccount = await accountsUpdate(account.id, { name: data.name, features: data.features })
        if (updatedAccount) {
          const updatedAccounts = accounts.map((account) =>
            account.id === updatedAccount.id ? updatedAccount : account
          )
          setAccounts(updatedAccounts)
          toast({
            title: t(i18nKeys.global.admin.page.accounts.toast.success),
          })
          setOpen(false)
        }
      } catch (error) {
        toast({
          title: t(i18nKeys.global.admin.page.accounts.toast.error),
        })
        setOpen(true)
      }
    } else {
      try {
        const accountCreated = await accountsCreate({ name: data.name, features: data.features })
        if (accountCreated) {
          setAccounts([...accounts, accountCreated])
          toast({
            title: t(i18nKeys.global.admin.page.accounts.toast.success),
          })
          setOpen(false)
        }
      } catch (error) {
        toast({
          title: t(i18nKeys.global.admin.page.accounts.toast.error),
        })
        setOpen(true)
      }
    }
  }

  return (
    <Form {...form}>
      <form ref={formRef} onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="name"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.admin.page.accounts.table.header.name)}</FormLabel>
              <FormControl>
                <Input {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="features"
          render={({ field }) => {
            return (
              <FormItem>
                <FormLabel> {t(i18nKeys.global.admin.page.accounts.table.header.Produits)}</FormLabel>
                <MultiSelect
                  selected={field.value}
                  options={Object.values(FeaturesEnum).map((feature) => ({
                    value: feature,
                    label: feature,
                  }))}
                  {...field}
                  className="sm:w-[510px]"
                />
                <FormMessage />
              </FormItem>
            )
          }}
        />
        <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
          <Button type="submit">
            {account
              ? t(i18nKeys.global.admin.page.accounts.sheet.edit.title)
              : t(i18nKeys.global.admin.page.accounts.sheet.add.title)}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default AccountForm
