import { i18nKeys } from '@/locales/keys'
import { Loader } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { useAuth } from '@/hooks/useAuth'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { H2, H3, LI, P, UL } from '@/components/typography'

const MethodologyPage = () => {
  const { t } = useTranslation()
  const { user } = useAuth()

  if (!user) return <Loader />

  return (
    <div className="container">
      <div className="flex-1 space-y-4 pt-6">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>{t(i18nKeys.beeoimpact.methodology.title)}</PageHeaderHeading>
          </PageHeader>
        </div>
        <div>
          <P>{t(i18nKeys.beeoimpact.methodology.introduction.p1)}</P>
          <P>{t(i18nKeys.beeoimpact.methodology.introduction.p2)}</P>
          <span>{t(i18nKeys.beeoimpact.methodology.introduction.p3)}</span>
          <UL className="ml-16">
            {hasAccesTo(user.features, FeatureType.POTENTIAL) && (
              <LI>
                <span className="font-bold">{t(i18nKeys.beeoimpact.methodology.introduction.ul.li1.title)}: </span>
                {t(i18nKeys.beeoimpact.methodology.introduction.ul.li1.description)}
              </LI>
            )}
            {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
              <LI>
                <span className="font-bold">{t(i18nKeys.beeoimpact.methodology.introduction.ul.li2.title)}: </span>
                {t(i18nKeys.beeoimpact.methodology.introduction.ul.li2.description)}
              </LI>
            )}
            {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
              <LI>
                <span className="font-bold">{t(i18nKeys.beeoimpact.methodology.introduction.ul.li3.title)}: </span>
                {t(i18nKeys.beeoimpact.methodology.introduction.ul.li3.description)}
              </LI>
            )}
          </UL>
          <P>{t(i18nKeys.beeoimpact.methodology.introduction.p4)}</P>
          <UL className="ml-12">
            <LI className="list-none">
              <blockquote className="mt-6 border-l-2 pl-6 italic">
                {t(i18nKeys.beeoimpact.methodology.introduction.blockquote1)}
              </blockquote>
            </LI>
            <LI className="list-none">
              <blockquote className="mt-6 border-l-2 pl-6 italic">
                {t(i18nKeys.beeoimpact.methodology.introduction.blockquote2)}
              </blockquote>
            </LI>
          </UL>
        </div>
        {hasAccesTo(user.features, FeatureType.POTENTIAL) && (
          <div className="py-4">
            <H2> {t(i18nKeys.beeoimpact.methodology.generalAnalysis.title)}</H2>
            <P>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.description)}</P>

            <H3>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.data.scope.title)}</H3>
            <P>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.data.scope.description)}</P>

            <H3>{t(i18nKeys.beeoimpact.methodology.generalAnalysis.data.datasets.title)}</H3>
            <P> {t(i18nKeys.beeoimpact.methodology.generalAnalysis.data.datasets.description)}</P>

            <UL>
              <LI>
                <a
                  href="https://land.copernicus.eu/en/products/corine-land-cover"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-900 underline"
                >
                  CORINE Land Cover — Copernicus Land Monitoring Service
                </a>
              </LI>
              <LI>
                <a href="https://www.gbif.org/" target="_blank" rel="noreferrer" className="text-blue-900 underline">
                  Global Biodiversity Information Facility (GBIF)
                </a>
              </LI>
            </UL>
          </div>
        )}
        {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && (
          <div className="py-4">
            <H2>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.title)}</H2>
            <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.description)}</P>

            <H3>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.scope.title)}</H3>
            <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.scope.description)}</P>

            <H3>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.score.title)}</H3>
            <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.score.p1)}</P>
            <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.score.p2)}</P>
            <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.score.p3)}</P>
            <H3>
              <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.title)}</P>
            </H3>

            <P>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.description)}</P>
            <span>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.subtitle)}</span>

            <UL className="list-none">
              <LI>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.office)}</LI>
              <LI>
                {t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.manufacturing)}
              </LI>
              <LI>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.extraction)}</LI>
              <LI>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.areaOfInfluence.distances.agricultural)}</LI>
            </UL>

            <H3>{t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.datasets.title)}</H3>

            <P> {t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.datasets.description)}</P>

            <UL>
              <LI>
                <a
                  href="https://www.eea.europa.eu/en/datahub/datahubitem-view/6fc8ad2d-195d-40f4-bdec-576e7d1268e4"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-900 underline"
                >
                  Natura 2000 data - the European network of protected sites
                </a>
                <span className="text-sm">
                  {''} {t(i18nKeys.beeoimpact.methodology.biodiversityRisk.data.datasets.description2)}
                </span>
              </LI>
              <LI>
                <a
                  href="https://www.eea.europa.eu/en/datahub/datahubitem-view/f60cec02-6494-4d08-b12d-17a37012cb28"
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-900 underline"
                >
                  Global Biodiversity Information Facility (GBIF)
                </a>
              </LI>
            </UL>
          </div>
        )}
        {hasAccesTo(user.features, FeatureType.PESTICIDES) && (
          <div className="py-4">
            <H2>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.title)}</H2>
            <P>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.description)}</P>
            <H3>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.scope.title)}</H3>
            <P>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.scope.description)}</P>

            <H3>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.score.title)}</H3>
            <P>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.score.description)}</P>

            <H3>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.title)}</H3>

            <P> {t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.description)}</P>

            <UL>
              <LI>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.libs1)}</LI>
              <LI>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.libs2)}</LI>
              <LI>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.libs3)}</LI>
              <LI>{t(i18nKeys.beeoimpact.methodology.pesticidesRisks.data.datasets.libs4)}</LI>
            </UL>
          </div>
        )}
      </div>
    </div>
  )
}

export default MethodologyPage
