import { Outlet } from 'react-router'

import Image from '@/components/image'

const AuthLayout = () => {
  return (
    <div className="flex min-h-screen min-w-full flex-col justify-center ">
      <div className="flex min-h-full flex-1">
        <div className="flex flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <Image className="h-10 w-auto" src="./beeo-logo.svg" alt="beeodiversity" />

            <div className="mt-10">
              <Outlet />
            </div>
          </div>
        </div>
        <div className="relative hidden w-0 flex-1 lg:block">
          <Image className="absolute inset-0 size-full object-cover" src="./bg-auth.jpg" alt="login cover" />
        </div>
      </div>
    </div>
  )
}

export default AuthLayout
