import * as React from 'react'
import { ReactElement, useEffect } from 'react'
import { i18nKeys } from '@/locales/keys'
import {
  ColumnDef,
  ColumnFiltersState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  OnChangeFn,
  PaginationState,
  SortingState,
  useReactTable,
  VisibilityState,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { PaginationData } from '@/types/pagination'
import { cn } from '@/lib/utils'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'

import { DataTablePagination } from './data-table-pagination'
import { DataTableToolbar } from './data-table-toolbar'

interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
  action?: ReactElement
  filterIdColumn: string
  className?: string
  rowSelected?: string
  sortBy?: SortingState
  paginationData: PaginationData
  setPaginationData: React.Dispatch<React.SetStateAction<PaginationData>>
  setFilterData: React.Dispatch<React.SetStateAction<ColumnFiltersState>>
  sorting: SortingState
  setSorting: OnChangeFn<SortingState>
}

export function DataTableSSR<TData, TValue>({
  columns,
  data,
  action,
  filterIdColumn,
  className,
  rowSelected,
  sorting,
  setSorting,
  paginationData,
  setPaginationData,
  setFilterData,
}: DataTableProps<TData, TValue>) {
  const { t } = useTranslation()
  const [rowSelection, setRowSelection] = React.useState({})
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([])
  //const [sorting, setSorting] = React.useState<SortingState>(sortBy ?? [])
  const [pagination, setPagination] = React.useState<PaginationState>({
    pageIndex: paginationData?.current_page ?? 0,
    pageSize: paginationData?.page_size ?? 10,
  })

  useEffect(() => {
    if (sorting) {
      setSorting(sorting)
    }
    if (columnFilters) {
      setColumnFilters(columnFilters)
    }
  }, [])

  useEffect(() => {
    if (columnFilters) {
      setFilterData(columnFilters)
    }
  }, [columnFilters])

  useEffect(() => {
    if (rowSelected) {
      setRowSelection({ [rowSelected]: true })
    } else {
      setRowSelection('')
    }
  }, [rowSelected])

  useEffect(() => {
    if (paginationData) {
      setPaginationData({
        ...paginationData,
        current_page: pagination.pageIndex,
        next_page: pagination.pageIndex + 1,
        previous_page: pagination.pageIndex == 0 ? null : pagination.pageIndex - 1,
        page_size: pagination.pageSize,
      })
      table.options.pageCount = Math.ceil((paginationData.count ?? 1) / pagination.pageSize)
    }
  }, [pagination])

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),

    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    autoResetPageIndex: false,

    manualPagination: true,
    onPaginationChange: setPagination,

    manualSorting: true,
    onSortingChange: setSorting,
  })

  return (
    <div className="space-y-4">
      <DataTableToolbar table={table} action={action} filterIdColumn={filterIdColumn} />

      <div className={cn('rounded-md border', className)}>
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {t(i18nKeys.global.common.datatable.emptyResults)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <DataTablePagination table={table} />
    </div>
  )
}
