import { i18nKeys } from '@/locales/keys'
import adminUsersLoader from '@/pages/admin/users.loader'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import { Separator } from '@/components/ui/separator'
import UsersTable from '@/components/users-table'

const AdminUsers = () => {
  const { t } = useTranslation()
  const { users, accountId } = useLoaderData() as Awaited<ReturnType<typeof adminUsersLoader>>

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">{t(i18nKeys.global.settings.page.users.title)}</h3>
        <p className="text-sm text-muted-foreground">{t(i18nKeys.global.settings.page.users.description)}</p>
      </div>
      <Separator />
      <UsersTable data={users ?? []} accountId={accountId} />
    </div>
  )
}

export default AdminUsers
