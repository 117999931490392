import { sitesLandplotsList, sitesRetrieve } from '@/client/backend/api/sites/sites'

import { getSiteCenter } from '@/lib/geo'

export async function pesticidesLoader({ params }) {
  const { siteId } = params

  const [site, landplots] = await Promise.all([sitesRetrieve(siteId), sitesLandplotsList(siteId)])

  const center = getSiteCenter(site)
  return { site, landplots, center }
}
