import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { AvailableLanguage } from '@/types/available-language'
import { useLanguage } from '@/hooks/useLanguage'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

const LanguageSelector = () => {
  const { t } = useTranslation()

  const { language, changeLanguage } = useLanguage()

  return (
    <Select value={language} onValueChange={changeLanguage}>
      <SelectTrigger className="h-5 w-32 border-none px-4">
        <SelectValue aria-label={t(i18nKeys.global.common.languages[language])}>
          <div className="flex items-center justify-center">{t(i18nKeys.global.common.languages[language])}</div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent className="mt-0">
        {Object.entries(AvailableLanguage).map(([key, lng]) => {
          return (
            <SelectItem key={key} value={lng}>
              <div className=" flex items-center justify-center">{t(i18nKeys.global.common.languages[lng])}</div>
            </SelectItem>
          )
        })}
      </SelectContent>
    </Select>
  )
}

export default LanguageSelector
