import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { CultureMapping, CultureMappingsListParams, PatchedCultureMapping } from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const cultureMappingsList = (params?: CultureMappingsListParams, signal?: AbortSignal) => {
  return backendInstance<CultureMapping[]>({ url: `/culture-mappings/`, method: 'GET', params, signal })
}

export const getCultureMappingsListQueryKey = (params?: CultureMappingsListParams) => {
  return [`/culture-mappings/`, ...(params ? [params] : [])] as const
}

export const getCultureMappingsListQueryOptions = <
  TData = Awaited<ReturnType<typeof cultureMappingsList>>,
  TError = ErrorType<unknown>,
>(
  params?: CultureMappingsListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof cultureMappingsList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCultureMappingsListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cultureMappingsList>>> = ({ signal }) =>
    cultureMappingsList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof cultureMappingsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CultureMappingsListQueryResult = NonNullable<Awaited<ReturnType<typeof cultureMappingsList>>>
export type CultureMappingsListQueryError = ErrorType<unknown>

export const useCultureMappingsList = <
  TData = Awaited<ReturnType<typeof cultureMappingsList>>,
  TError = ErrorType<unknown>,
>(
  params?: CultureMappingsListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof cultureMappingsList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCultureMappingsListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const cultureMappingsCreate = (cultureMapping: NonReadonly<CultureMapping>) => {
  return backendInstance<CultureMapping>({
    url: `/culture-mappings/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: cultureMapping,
  })
}

export const getCultureMappingsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cultureMappingsCreate>>,
    TError,
    { data: NonReadonly<CultureMapping> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof cultureMappingsCreate>>,
  TError,
  { data: NonReadonly<CultureMapping> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cultureMappingsCreate>>,
    { data: NonReadonly<CultureMapping> }
  > = (props) => {
    const { data } = props ?? {}

    return cultureMappingsCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CultureMappingsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof cultureMappingsCreate>>>
export type CultureMappingsCreateMutationBody = NonReadonly<CultureMapping>
export type CultureMappingsCreateMutationError = ErrorType<unknown>

export const useCultureMappingsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cultureMappingsCreate>>,
    TError,
    { data: NonReadonly<CultureMapping> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof cultureMappingsCreate>>,
  TError,
  { data: NonReadonly<CultureMapping> },
  TContext
> => {
  const mutationOptions = getCultureMappingsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const cultureMappingsRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<CultureMapping>({ url: `/culture-mappings/${id}/`, method: 'GET', signal })
}

export const getCultureMappingsRetrieveQueryKey = (id: number) => {
  return [`/culture-mappings/${id}/`] as const
}

export const getCultureMappingsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof cultureMappingsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof cultureMappingsRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getCultureMappingsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof cultureMappingsRetrieve>>> = ({ signal }) =>
    cultureMappingsRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof cultureMappingsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type CultureMappingsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof cultureMappingsRetrieve>>>
export type CultureMappingsRetrieveQueryError = ErrorType<unknown>

export const useCultureMappingsRetrieve = <
  TData = Awaited<ReturnType<typeof cultureMappingsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof cultureMappingsRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getCultureMappingsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const cultureMappingsUpdate = (id: number, cultureMapping: NonReadonly<CultureMapping>) => {
  return backendInstance<CultureMapping>({
    url: `/culture-mappings/${id}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: cultureMapping,
  })
}

export const getCultureMappingsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cultureMappingsUpdate>>,
    TError,
    { id: number; data: NonReadonly<CultureMapping> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof cultureMappingsUpdate>>,
  TError,
  { id: number; data: NonReadonly<CultureMapping> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cultureMappingsUpdate>>,
    { id: number; data: NonReadonly<CultureMapping> }
  > = (props) => {
    const { id, data } = props ?? {}

    return cultureMappingsUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CultureMappingsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof cultureMappingsUpdate>>>
export type CultureMappingsUpdateMutationBody = NonReadonly<CultureMapping>
export type CultureMappingsUpdateMutationError = ErrorType<unknown>

export const useCultureMappingsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cultureMappingsUpdate>>,
    TError,
    { id: number; data: NonReadonly<CultureMapping> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof cultureMappingsUpdate>>,
  TError,
  { id: number; data: NonReadonly<CultureMapping> },
  TContext
> => {
  const mutationOptions = getCultureMappingsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const cultureMappingsPartialUpdate = (id: number, patchedCultureMapping: NonReadonly<PatchedCultureMapping>) => {
  return backendInstance<CultureMapping>({
    url: `/culture-mappings/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedCultureMapping,
  })
}

export const getCultureMappingsPartialUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cultureMappingsPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedCultureMapping> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof cultureMappingsPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedCultureMapping> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof cultureMappingsPartialUpdate>>,
    { id: number; data: NonReadonly<PatchedCultureMapping> }
  > = (props) => {
    const { id, data } = props ?? {}

    return cultureMappingsPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type CultureMappingsPartialUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof cultureMappingsPartialUpdate>>
>
export type CultureMappingsPartialUpdateMutationBody = NonReadonly<PatchedCultureMapping>
export type CultureMappingsPartialUpdateMutationError = ErrorType<unknown>

export const useCultureMappingsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof cultureMappingsPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedCultureMapping> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof cultureMappingsPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedCultureMapping> },
  TContext
> => {
  const mutationOptions = getCultureMappingsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const cultureMappingsDestroy = (id: number) => {
  return backendInstance<void>({ url: `/culture-mappings/${id}/`, method: 'DELETE' })
}

export const getCultureMappingsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cultureMappingsDestroy>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof cultureMappingsDestroy>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof cultureMappingsDestroy>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return cultureMappingsDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type CultureMappingsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof cultureMappingsDestroy>>>

export type CultureMappingsDestroyMutationError = ErrorType<unknown>

export const useCultureMappingsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof cultureMappingsDestroy>>, TError, { id: number }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof cultureMappingsDestroy>>, TError, { id: number }, TContext> => {
  const mutationOptions = getCultureMappingsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
