import React from 'react'

interface RatingProps {
  rating: number
  filledColor: string
  unfilledColor: string
  icon: React.ReactNode
}
const Rating = ({ rating, filledColor, unfilledColor, icon }: RatingProps) => {
  return (
    <div className="flex flex-row">
      {[...Array(5)].map((_, index) => (
        <span key={index} className={`${index < rating ? filledColor : unfilledColor}`}>
          {icon}
        </span>
      ))}
    </div>
  )
}
export default Rating
