import { DivIcon } from 'leaflet'
import { Bug, Dot } from 'lucide-react'
import { renderToString } from 'react-dom/server'

import { Icons } from '@/components/icons'

interface CustomIconProps {
  category: string
}

export const getCustomMapIcon = ({ category }: CustomIconProps): DivIcon => {
  let iconComponent: React.ReactNode

  switch (category) {
    case 'Animalia':
      iconComponent = <Icons.Squirrel className="size-4 border-none fill-amber-800 text-amber-950" />
      break
    // Add more cases for other categories as needed
    case 'Plantae':
      iconComponent = <Icons.Sprout className="size-4 border-none fill-green-800 text-green-950" />
      break
    case 'Fungi':
      iconComponent = <Icons.Mushroom className="size-5 border-none text-red-800" />
      break
    case 'Bacteria':
      iconComponent = <Bug className="size-4 border-none fill-gray-800 text-gray-950" />
      break
    default:
      iconComponent = <Dot className="size-4 border-none fill-gray-800 text-gray-950" />
      break
  }

  const customIcon = new DivIcon({
    html: renderToString(iconComponent),
    className: 'custom-icon',
  })

  return customIcon
}
