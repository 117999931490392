import { User } from '@/client/backend/models'
import { Separator } from '@radix-ui/react-separator'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { Card, CardContent } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'

type SiteCardSkeletonProps = {
  user: User
  withScoring?: boolean
}

const SiteCardSkeleton = ({ user, withScoring }: SiteCardSkeletonProps) => {
  return (
    <Card className="mb-8 ml-8 rounded-xl border-none shadow-none hover:cursor-pointer">
      <div className="flex flex-col">
        <Skeleton className="3xl:h-[400px] z-0 h-72 rounded-2xl hover:cursor-pointer sm:h-48 md:h-48 xl:h-[240px]" />
      </div>
      <CardContent className="border-none px-0">
        <div className="my-2 flex flex-row items-center justify-between gap-x-2">
          <Skeleton className="h-6 w-1/2" />
        </div>

        {withScoring && (
          <div className="flex flex-row gap-1">
            {hasAccesTo(user.features, FeatureType.PESTICIDES) && (
              <>
                <Skeleton className="h-7 w-32 rounded-full" />
                <Separator orientation="vertical" />
              </>
            )}

            {hasAccesTo(user.features, FeatureType.BIODIVERSITY) && <Skeleton className="h-7 w-32 rounded-full" />}
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default SiteCardSkeleton
