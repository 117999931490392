import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { useAuth } from '@/hooks/useAuth'
import { Separator } from '@/components/ui/separator'

const Profile = () => {
  const { user } = useAuth()
  const { t } = useTranslation()
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">{t(i18nKeys.global.settings.page.profile.title)}</h3>
        <p className="text-sm text-muted-foreground">{t(i18nKeys.global.settings.page.profile.description)}</p>
      </div>
      <Separator />
      <div>
        <h4 className="text-lg font-medium">Email</h4>
        <p className="text-sm text-muted-foreground">{user?.email}</p>
      </div>
      {user?.first_name && user?.last_name && (
        <div>
          <h4 className="text-lg font-medium">Name</h4>
          <p className="text-sm text-muted-foreground">
            {user?.first_name} {user?.last_name}
          </p>
        </div>
      )}
    </div>
  )
}

export default Profile
