import { Navigate, Outlet } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import Loader from '@/components/loader'

type ModuleRouteProps = {
  features: FeatureType[]
}

const ModuleRoute = ({ features }: ModuleRouteProps) => {
  const { user } = useAuth()

  if (!user) {
    return <Loader />
  }
  const hasFeature = user?.features.some((feature) => features.includes(feature as FeatureType))

  if (!hasFeature) {
    return <Navigate to={getPath(Routes.ERROR_403)} replace />
  }

  return <Outlet />
}

export default ModuleRoute
