import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Checkbox } from '@/components/ui/checkbox'
import CategoryIcon from '@/components/beeoinitiative/category-icon'
import Months from '@/components/beeoinitiative/months'

const InitiativeFilterTitle = ({ title }) => {
  return <div className="mb-3 text-lg font-bold capitalize">{title}</div>
}

const InitiativesCheckboxFilter = ({ name, filters, setFilters }) => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col">
      <InitiativeFilterTitle title={t(name)} />
      <div className="flex flex-col gap-1 text-sm">
        {filters.map((filter) => (
          <label key={filter.value} className="flex items-center gap-2">
            <Checkbox
              checked={filter.checked}
              onCheckedChange={(value) =>
                setFilters(filters.map((filt) => (filt.value === filter.value ? { ...filt, checked: value } : filt)))
              }
            />
            <span>{t(filter.labelKey)}</span>
          </label>
        ))}
      </div>
    </div>
  )
}

const InitiativesCategoriesFilter = ({ filters, setFilters }) => {
  const { t } = useTranslation()

  return (
    <div className="flex max-w-[350px] flex-col">
      <InitiativeFilterTitle title={t(i18nKeys.beeoinitiative.initiativesPage.page.initiativeCategory)} />
      <div className="flex flex-row flex-wrap gap-3 text-sm">
        {filters.map(({ value, iconName, color, labelKey }) => {
          const isSelected = filters.find((filter) => filter.value === value)?.checked
          return (
            <div
              key={value}
              className="flex cursor-pointer items-center gap-2 rounded-xl border px-2 py-1 text-xs"
              style={{
                borderColor: color,
                backgroundColor: color + (isSelected ? '99' : '05'),
                color: isSelected ? 'white' : color,
                fontWeight: isSelected ? 'bold' : 'normal',
              }}
              onClick={() =>
                setFilters(
                  filters.map((filter) => (filter.value === value ? { ...filter, checked: !filter.checked } : filter))
                )
              }
            >
              <CategoryIcon name={iconName} color={isSelected ? 'white' : color} className="size-4" />
              {t(labelKey)}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const InitiativesFilters = ({ filters, setFilters }) => {
  const { t } = useTranslation()
  const setSpecificFilter = (filterName) => (newFilters) => {
    setFilters({ ...filters, [filterName]: newFilters })
  }
  const onMonthClick = (key) => {
    setFilters({
      ...filters,
      time_line: filters.time_line.map((filter) =>
        filter.value === key ? { ...filter, checked: !filter.checked } : filter
      ),
    })
  }
  const resetFilters = () => {
    setFilters({
      name: '',
      impact: filters.impact.map((filter) => ({ ...filter, checked: false })),
      cost: filters.cost.map((filter) => ({ ...filter, checked: false })),
      category: filters.category.map((filter) => ({ ...filter, checked: false })),
      site_type: filters.site_type.map((filter) => ({ ...filter, checked: false })),
      time_line: filters.time_line.map((filter) => ({ ...filter, checked: false })),
    })
  }
  const selectedMonths = filters.time_line.filter((filter) => filter.checked).map((filter) => filter.value)
  return (
    <div className="relative flex flex-row justify-between space-x-2 pr-4">
      <InitiativesCheckboxFilter
        name={t(i18nKeys.beeoinitiative.initiativesPage.page.positiveImpact)}
        filters={filters.impact}
        setFilters={setSpecificFilter('impact')}
      />
      <InitiativesCheckboxFilter name="Costs" filters={filters.cost} setFilters={setSpecificFilter('cost')} />
      <InitiativesCheckboxFilter name="Type" filters={filters.site_type} setFilters={setSpecificFilter('site_type')} />
      <div className="flex flex-col">
        <InitiativeFilterTitle title={t(i18nKeys.beeoinitiative.initiativesPage.page.installationPeriod)} />
        <Months selectedMonths={selectedMonths} onMonthClick={onMonthClick} className="max-w-[160px]" />
      </div>
      <InitiativesCategoriesFilter filters={filters.category} setFilters={setSpecificFilter('category')} />
      <div className="absolute right-2 cursor-pointer text-sm underline" onClick={resetFilters}>
        {t('beeoinitiative.initiativesPage.page.resetFilters')}
      </div>
    </div>
  )
}

export default InitiativesFilters
