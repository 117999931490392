import { Badge } from '@/components/ui/badge'
import { Skeleton } from '@/components/ui/skeleton'

const MONTHS = ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D']

interface MonthlyScheduleProps {
  monthsToColor: number[]
  isLoading?: boolean
}

const MonthlySchedule = ({ monthsToColor, isLoading }: MonthlyScheduleProps) => {
  if (isLoading) {
    return <MonthlyScheduleSkeleton />
  }
  const uniqueMonthsToColor = [...new Set(monthsToColor)]

  return (
    <div className="flex flex-row space-x-1">
      {MONTHS.map((month, index) => (
        <Badge
          key={month}
          variant="outline"
          className={`flex size-5 items-center justify-center rounded-sm text-gray-400 ${
            uniqueMonthsToColor.includes(index + 1) ? 'bg-biodivTealSevenHundred text-white' : ''
          }`}
        >
          {month}
        </Badge>
      ))}
    </div>
  )
}

const MonthlyScheduleSkeleton = () => {
  return (
    <div className="flex flex-row space-x-1">
      {Array.from({ length: 12 }, (_, i) => (
        <Skeleton key={i} className="size-5" />
      ))}
    </div>
  )
}

export default MonthlySchedule
