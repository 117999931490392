import Axios, { AxiosError, AxiosRequestConfig } from 'axios'

import { setupDefaultHeaders } from '@/lib/utils'

export const AXIOS_INSTANCE = Axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

const publicUrls = ['/users/reset_password/', '/users/reset_password_confirm/', '/users/activation/']

export const backendInstance = <T>(config: AxiosRequestConfig): Promise<T> => {
  if (!AXIOS_INSTANCE.defaults.headers.common['Authorization'] && !publicUrls.includes(config.url || '')) {
    setupDefaultHeaders()
  }

  const source = Axios.CancelToken.source()
  const promise = AXIOS_INSTANCE({ ...config, cancelToken: source.token })
    .then(({ data }) => data)
    .catch((error) => {
      throw error
    })

  // @ts-ignore
  promise.cancel = () => {
    source.cancel('Query was cancelled ')
  }

  return promise
}

export interface ErrorType<Error> extends AxiosError<Error> {}
