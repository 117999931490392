import { useContext, useEffect, useMemo, useState } from 'react'
import { useActionsTakenUpdate } from '@/client/backend/api/actions-taken/actions-taken'
import { auditsList, getAuditsListQueryKey } from '@/client/backend/api/audits/audits'
import { getInitiativesListQueryKey, initiativesList } from '@/client/backend/api/initiatives/initiatives'
import { getQuestionsListQueryKey, questionsList } from '@/client/backend/api/questions/questions'
import {
  getSitesActionsTakenListQueryKey,
  getSitesResponsesListQueryKey,
  getSitesRetrieveQueryKey,
  sitesActionsTakenList,
  sitesResponsesList,
  sitesRetrieve,
} from '@/client/backend/api/sites/sites'
import { ActionTaken } from '@/client/backend/models'
import { BeeoinitiativeContext, BeeoinitiativeContextType } from '@/context/beeoinitiative-context'
import { i18nKeys } from '@/locales/keys'
import BreadcrumbComponent from '@/pages/beeoinitiative/breadcrumb-component'
import AuditCard from '@/pages/beeoinitiative/site-detail/audit-card'
import EvaluateRisksOpportunitiesCard from '@/pages/beeoinitiative/site-detail/evaluate-risks-opportunities-card'
import GreenCard from '@/pages/beeoinitiative/site-detail/green-card'
import InitCardsCarousel from '@/pages/beeoinitiative/site-detail/init-cards-carousel'
import NoInitiativesCard from '@/pages/beeoinitiative/site-detail/no-initiatives-card'
import SiteCategoryBadge from '@/pages/beeoinitiative/site-detail/site-category-badge'
import TakenPlannedInitiativesCard from '@/pages/beeoinitiative/site-detail/taken-planned-initiatives'
import { useQuery } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import { Link, useParams } from 'react-router-dom'

import { getSiteCenter } from '@/lib/geo'
import { filterInitiativeOnSiteResponses } from '@/lib/initiative'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card } from '@/components/ui/card'
import CustomOutlineButton from '@/components/ui/custom-outline-button'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { useToast } from '@/components/ui/use-toast'
import SiteMap from '@/components/beeoinitiative/site-map'
import { Icons } from '@/components/icons'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

const TakenInitiativePage = () => {
  const { siteId } = useParams<{ siteId: string }>() as { siteId: string }

  const { data: site, isLoading: isLoadingSite } = useQuery({
    queryKey: [getSitesRetrieveQueryKey(siteId)],
    queryFn: () => sitesRetrieve(siteId, { include: 'initiative_score' }),
  })

  const { data: actionsTaken, isLoading: isLoadingSiteActions } = useQuery({
    queryKey: [getSitesActionsTakenListQueryKey(siteId), { include: 'initiative' }],
    queryFn: () => sitesActionsTakenList(siteId, { include: 'initiative' }),
  })

  const { data: questions } = useQuery({
    queryKey: [getQuestionsListQueryKey()],
    queryFn: () => questionsList(),
  })

  const { data: responses } = useQuery({
    queryKey: [getSitesResponsesListQueryKey(siteId)],
    queryFn: () => sitesResponsesList(siteId),
  })

  const { data: initiatives, isLoading: isLoadingInitiatives } = useQuery({
    queryKey: [getInitiativesListQueryKey()],
    queryFn: () => initiativesList(),
  })

  const { data: audits, isLoading: isLoadingAudits } = useQuery({
    queryKey: [getAuditsListQueryKey()],
    queryFn: () => auditsList(),
  })
  const { t } = useTranslation()
  const [seeMoreCard, setSeeMoreCard] = useState(false)
  const [actionTakenDone, setActionTakenDone] = useState<ActionTaken[]>([])
  const [actionTakenPlanned, setActionTakenPlanned] = useState<ActionTaken[]>([])
  useEffect(() => {
    if (actionsTaken) {
      setActionTakenDone(actionsTaken.filter((action) => action.status === 'DONE'))
      setActionTakenPlanned(actionsTaken.filter((action) => action.status === 'PENDING'))
    }
  }, [actionsTaken])

  const { newActionTaken, resetNewActionTaken } = useContext(BeeoinitiativeContext) as BeeoinitiativeContextType

  const mutation = useActionsTakenUpdate()
  const { toast } = useToast()
  const initiativesCount = useMemo(() => {
    if (initiatives && responses) {
      return initiatives?.filter((init) => filterInitiativeOnSiteResponses(init, responses)).length
    } else {
      return 0
    }
  }, [initiatives, responses])

  const [center, setCenter] = useState<L.LatLng | null>(null)

  useEffect(() => {
    if (site) {
      const siteCenter = getSiteCenter(site)
      setCenter(siteCenter)
    }
  }, [site])

  useEffect(() => {
    if (newActionTaken) {
      setActionTakenPlanned((prev) => {
        // Verify if the new action is already in the list
        if (!prev?.some((action) => action.id === newActionTaken.id)) {
          return [...(prev || []), newActionTaken]
        }
        return prev
      })
      resetNewActionTaken()
    }
  }, [newActionTaken])

  const spentBudget = actionsTaken?.map((action) => Number(action.spent_budget) || 0).reduce((a, b) => a + b, 0)
  const plannedCost = actionsTaken?.map((action) => Number(action.planned_cost) || 0).reduce((a, b) => a + b, 0)

  const moveToDone = (initiativeId: number) => {
    // First, find the initiative to move
    const initiativeToMove = actionTakenPlanned?.find((initiative) => initiative.id === initiativeId)

    if (!initiativeToMove) {
      return
    }

    // Optimistically update the state
    setActionTakenPlanned((prevPlanned) => prevPlanned?.filter((initiative) => initiative.id !== initiativeId))
    setActionTakenDone((prevDone) => (prevDone ? [...prevDone, initiativeToMove] : [initiativeToMove]))

    mutation.mutate(
      {
        id: initiativeId.toString(),
        data: { status: 'DONE' },
      },
      {
        onError: () => {
          // Revert the state changes if mutation fails
          setActionTakenPlanned((prevPlanned) => [...(prevPlanned || []), initiativeToMove])
          setActionTakenDone((prevDone) => prevDone?.filter((initiative) => initiative.id !== initiativeId))
          toast({ title: t(i18nKeys.beeoinitiative.site.error) })
        },
      }
    )
  }

  const siteCategory = {
    URBAN: t(i18nKeys.beeoinitiative.initiativesPage.siteCategory.urbanZone),
    AGRICULTURAL: t(i18nKeys.beeoinitiative.initiativesPage.siteCategory.agriculturalZone),
    FORESTER: t(i18nKeys.beeoinitiative.initiativesPage.siteCategory.forestZone),
    HYBRID: t(i18nKeys.beeoinitiative.initiativesPage.siteCategory.mixedZone),
  }

  const impactScoring = actionsTaken
    ?.map((action) => action.initiative)
    .map((initiative) => initiative.impact)
    .reduce((a, b) => (a ?? 0) + (b ?? 0), 0)

  const latestAudit = audits
    ?.filter((audit) => audit.date)
    .sort((a, b) => Number(a.date ?? 0) - Number(b.date ?? 0))
    .slice(-1)[0]

  const filteredInitsBasedOnResponses = initiatives?.filter((initiative) =>
    filterInitiativeOnSiteResponses(initiative, responses)
  )

  return (
    <div className="px-11">
      <BreadcrumbComponent
        breadcrumbItems={[
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoapps),
            href: '/',
          },
          {
            label: t(i18nKeys.beeoinitiative.dashboard.breadcrumb.beeoinitiative),
            href: getPath(Routes.BEEOINITIATIVE),
          },
          {
            label: t(i18nKeys.beeoinitiative.common.breadcrumb.sites),
            href: getPath(Routes.BEEOINITIATIVE_SITES),
          },
        ]}
        currentPage={site?.properties?.name}
      />
      <div className="flex items-center justify-between">
        {isLoadingSite ? (
          <Skeleton className="h-8 w-52" />
        ) : (
          <div className="flex items-center space-x-4">
            <PageHeader>
              <PageHeaderHeading className="hidden md:block">{site?.properties?.name}</PageHeaderHeading>
            </PageHeader>

            <SiteCategoryBadge
              category={
                siteCategory[site?.properties?.environment_category ?? '']?.charAt(0)?.toUpperCase() +
                siteCategory[site?.properties?.environment_category ?? '']?.slice(1)
              }
            />
          </div>
        )}
        <div className="pb-4">
          {isLoadingSite ? (
            <Skeleton className="h-10 w-52" />
          ) : (
            <Link to={getPath(Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE, { params: { siteId: site?.id as number } })}>
              <CustomOutlineButton
                icon={<Icons.PlusCircleIcon />}
                label={t(i18nKeys.beeoinitiative.site.newInitiative)}
              />
            </Link>
          )}
        </div>
      </div>

      <div>
        <div className="flex sm:flex-col md:flex-col md:space-x-0 md:space-y-5  lg:flex-row lg:space-x-5 lg:space-y-0 xl:flex-row xl:space-x-5 xl:space-y-0 2xl:flex-row 2xl:space-x-5 2xl:space-y-0">
          {isLoadingSiteActions ? (
            <Skeleton className="sm:h-[170px] sm:w-[512px] md:h-[170px] md:w-full lg:h-[170px] lg:w-[512px] xl:h-[170px] xl:w-[840px] 2xl:h-[170px] 2xl:w-[1171px]" />
          ) : (
            <Card className="flex grow flex-row items-center justify-between sm:h-[170px] sm:w-[512px] md:h-[170px] md:w-full lg:h-[170px] lg:w-[512px] xl:h-[170px] xl:w-[840px] 2xl:h-[170px] 2xl:w-full">
              <div className="flex flex-1 flex-col items-center justify-center space-y-4">
                <p className="text-xl font-semibold">{t(i18nKeys.beeoinitiative.site.initiatives)}</p>
                <p className="text-4xl font-semibold text-biodivTealSevenHundred">
                  {actionTakenDone?.length}/{actionsTaken?.length}
                </p>
              </div>
              <Separator orientation="vertical" className="h-3/5" />
              <div className="flex flex-1 flex-col items-center justify-center">
                <div className="flex items-center space-x-2">
                  <Icons.Euro className="size-4 text-biodivTealSevenHundred" />
                  <p className="pb-1 text-xl font-semibold">{t(i18nKeys.beeoinitiative.site.totalBudget)}</p>
                </div>
                <p className="pb-2">{t(i18nKeys.beeoinitiative.site.spentVSplanned)}</p>
                <p className="text-xl font-semibold text-biodivTealSevenHundred">
                  {spentBudget ?? 0}/{plannedCost ?? 0} €
                </p>
              </div>
              <Separator orientation="vertical" className="h-3/5" />
              <div className="flex flex-1 shrink flex-col items-center justify-center space-y-4">
                <div className="flex flex-row items-center space-x-2">
                  <Icons.Leaf className="size-4 text-biodivTealSevenHundred" />
                  <p className="text-xl font-semibold">Score</p>
                </div>
                <p className="text-4xl font-semibold text-biodivTealSevenHundred">
                  {impactScoring} {t(i18nKeys.beeoinitiative.site.pts)}
                </p>
              </div>
            </Card>
          )}
          <div>
            {isLoadingSite ? (
              <Skeleton className="sm:h-[170px] sm:w-[164px] md:h-[170px] md:w-full lg:h-[170px] lg:w-[164px] xl:h-[170px] xl:w-[252px] 2xl:h-[170px] 2xl:w-[383px]" />
            ) : (
              center && (
                <div className="sm:h-[170px] sm:w-[164px] md:h-[170px] md:w-full lg:h-[170px] lg:w-[164px] xl:h-[170px] xl:w-[252px] 2xl:h-[170px] 2xl:w-[383px]">
                  <SiteMap target={center} site={site} />
                </div>
              )
            )}
          </div>
        </div>
        <div className="py-4">
          <InitCardsCarousel
            site={site}
            filteredInitsBasedOnResponses={filteredInitsBasedOnResponses}
            initiativesCount={initiativesCount}
            questions={questions}
            responses={responses}
            isLoading={isLoadingInitiatives}
          />
        </div>
        <div className="xs:flex-col flex justify-center pb-8 sm:space-y-5 md:flex-col md:space-y-5  lg:flex-col lg:space-y-5 xl:flex-row xl:space-x-5  xl:space-y-0 2xl:flex-row 2xl:space-x-5 2xl:space-y-0">
          {actionTakenDone?.length ? (
            <TakenPlannedInitiativesCard
              title={
                actionTakenDone?.length +
                ' ' +
                t(i18nKeys.beeoinitiative.site.takenInitiative, {
                  count: actionTakenDone?.length,
                })
              }
              initiativeList={actionTakenDone}
              footer={seeMoreCard ? t(i18nKeys.beeoinitiative.site.seeLess) : t(i18nKeys.beeoinitiative.site.seeMore)}
              button={false}
              seeMoreCard={seeMoreCard}
              setSeeMoreCard={setSeeMoreCard}
              onMoveToDone={moveToDone}
              site={site}
              setActionTakenDone={setActionTakenDone}
              actionTakenDone={actionTakenDone}
              isLoading={isLoadingSiteActions}
            />
          ) : (
            <NoInitiativesCard
              title={`${actionTakenDone?.length} ${t(i18nKeys.beeoinitiative.site.takenInitiative, {
                count: actionTakenDone?.length,
              })}`}
              message={
                <>
                  <span>{t(i18nKeys.beeoinitiative.site.noTakenInits.text1)}</span>
                  <span className="font-semibold text-biodivTealSevenHundred">
                    {t(i18nKeys.beeoinitiative.site.noTakenInits.text2)}
                  </span>
                  <span>{t(i18nKeys.beeoinitiative.site.noTakenInits.text3)}</span>
                </>
              }
              isLoading={isLoadingSiteActions}
            />
          )}

          {actionTakenPlanned?.length ? (
            <TakenPlannedInitiativesCard
              title={
                actionTakenPlanned?.length +
                ' ' +
                t(i18nKeys.beeoinitiative.site.plannedInitiative, {
                  count: actionTakenPlanned?.length,
                })
              }
              initiativeList={actionTakenPlanned}
              footer={seeMoreCard ? t(i18nKeys.beeoinitiative.site.seeLess) : t(i18nKeys.beeoinitiative.site.seeMore)}
              button={true}
              seeMoreCard={seeMoreCard}
              setSeeMoreCard={setSeeMoreCard}
              onMoveToDone={moveToDone}
              site={site}
              setActionTakenDone={setActionTakenDone}
              actionTakenDone={actionTakenDone}
              isLoading={isLoadingSiteActions}
            />
          ) : (
            <NoInitiativesCard
              title={`${actionTakenPlanned?.length} ${t(i18nKeys.beeoinitiative.site.plannedInitiative, { count: actionTakenPlanned?.length })}`}
              message={
                <>
                  <span className="font-semibold text-biodivTealSevenHundred">
                    {t(i18nKeys.beeoinitiative.site.NoPlannedInits.text1)}
                  </span>
                  <span>{t(i18nKeys.beeoinitiative.site.NoPlannedInits.text2)}</span>
                </>
              }
              button={
                <CustomOutlineButton
                  icon={<Icons.PlusCircleIcon />}
                  label={t(i18nKeys.beeoinitiative.site.newInitiative)}
                />
              }
              buttonLink={getPath(Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE, {
                params: { siteId: site?.id as number },
              })}
              isLoading={isLoadingSiteActions}
            />
          )}
        </div>
        {actionTakenPlanned?.length !== 0 && actionTakenDone?.length !== 0 ? (
          <div className="grid grid-cols-2 gap-5 pb-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 2xl:grid-cols-2">
            <AuditCard latestAudit={latestAudit} isLoading={isLoadingAudits} />
            <GreenCard site={site} isLoading={isLoadingSite} />
          </div>
        ) : (
          <div className="pb-8">
            <EvaluateRisksOpportunitiesCard
              icon={<Icons.GreenCardIcon size={120} />}
              title={t(i18nKeys.beeoinitiative.site.imageText.title)}
              description1={t(i18nKeys.beeoinitiative.site.imageText.description1)}
              description2={t(i18nKeys.beeoinitiative.site.imageText.description2)}
              buttonText={t(i18nKeys.beeoinitiative.dashboard.evaluateRisks.button)}
              isLoading={isLoadingInitiatives}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default TakenInitiativePage
