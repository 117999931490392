import { Dispatch, SetStateAction, useState } from 'react'
import { Site } from '@/client/backend/models/site'
import { i18nKeys } from '@/locales/keys'
import { FileUp } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { isAdmin } from '@/lib/permission'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { ScrollArea } from '@/components/ui/scroll-area'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { Icons } from '@/components/icons'
import ImportSites from '@/components/import-sites'
import SiteForm from '@/components/site-form'

type SettingsSitesTableActionProps = {
  sites: Site[]
  setSites: Dispatch<SetStateAction<Site[]>>
  accountId?: number
}

const SettingsSitesTableAction = ({ sites, setSites, accountId }: SettingsSitesTableActionProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { user } = useAuth()
  return (
    <div className="flex flex-row">
      {isAdmin(user) && (
        <Dialog>
          <DialogTrigger asChild>
            <Button size="sm" className="ml-2 h-8 lg:flex">
              <FileUp className="mr-2 size-4" />
              Import sites
            </Button>
          </DialogTrigger>
          <DialogContent className="h-[90%] min-w-[90%] sm:max-w-[425px]">
            <ScrollArea>
              <DialogHeader>
                <DialogTitle>Import sites</DialogTitle>
                <DialogDescription>
                  Import all the sites you need. The CSV need to content name, lat, lng, biodiversity_radius
                </DialogDescription>

                <ImportSites accountId={accountId} />
              </DialogHeader>
            </ScrollArea>
          </DialogContent>
        </Dialog>
      )}

      {isAdmin(user) && (
        <Sheet open={open} onOpenChange={setOpen}>
          <SheetTrigger asChild>
            <Button size="sm" className="ml-2 h-8 lg:flex">
              <Icons.Plus className="mr-2 size-4" />
              {t(i18nKeys.global.common.buttons.addSite)}
            </Button>
          </SheetTrigger>
          <SheetContent side="right" className="overflow-y-scroll">
            <SheetHeader>
              <SheetTitle> {t(i18nKeys.global.settings.page.sites.sheet.add.title)}</SheetTitle>
              <SheetDescription>{t(i18nKeys.global.settings.page.sites.sheet.add.description)}</SheetDescription>
            </SheetHeader>
            <SiteForm sites={sites} setOpen={setOpen} setSites={setSites} accountId={accountId} />
          </SheetContent>
        </Sheet>
      )}
    </div>
  )
}

export default SettingsSitesTableAction
