import { useQuery } from '@tanstack/react-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type {
  BarChartByYear,
  BeeomonitoringGraphsCountVegetalsByPeriodList400,
  BeeomonitoringGraphsCountVegetalsBySplitList400,
  BeeomonitoringGraphsDiofurValuesByPeriodList400,
  BeeomonitoringGraphsHeavymetalCountByPeriodList400,
  BeeomonitoringGraphsHeavymetalValuesByPeriodList400,
  BeeomonitoringGraphsListVegetalsList400,
  BeeomonitoringGraphsMapVegetalsByCategoryList400,
  BeeomonitoringGraphsMapVegetalsByFamilyList400,
  BeeomonitoringGraphsPahValuesByPeriodList400,
  BeeomonitoringGraphsPcbValuesByPeriodList400,
  BeeomonitoringGraphsPestCountByPeriodList400,
  BeeomonitoringGraphsPestCountProportionsByPeriodList400,
  BeeomonitoringGraphsPestValuesByPeriodList400,
  ListByYear,
  PieChartByYear,
  StackedBarChartByYear,
} from '../../models'

export const beeomonitoringGraphsCountVegetalsByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<BarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/count_vegetals_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsCountVegetalsByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/count_vegetals_by_period/`] as const
}

export const getBeeomonitoringGraphsCountVegetalsByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsCountVegetalsByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsCountVegetalsByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsByPeriodList>>> = ({
    signal,
  }) => beeomonitoringGraphsCountVegetalsByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsCountVegetalsByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsByPeriodList>>
>
export type BeeomonitoringGraphsCountVegetalsByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsCountVegetalsByPeriodList400>

export const useBeeomonitoringGraphsCountVegetalsByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsCountVegetalsByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsCountVegetalsByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsCountVegetalsBySplitList = (id: string, signal?: AbortSignal) => {
  return backendInstance<PieChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/count_vegetals_by_split/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsCountVegetalsBySplitListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/count_vegetals_by_split/`] as const
}

export const getBeeomonitoringGraphsCountVegetalsBySplitListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsBySplitList>>,
  TError = ErrorType<BeeomonitoringGraphsCountVegetalsBySplitList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsBySplitList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsCountVegetalsBySplitListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsBySplitList>>> = ({
    signal,
  }) => beeomonitoringGraphsCountVegetalsBySplitList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsBySplitList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsCountVegetalsBySplitListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsBySplitList>>
>
export type BeeomonitoringGraphsCountVegetalsBySplitListQueryError =
  ErrorType<BeeomonitoringGraphsCountVegetalsBySplitList400>

export const useBeeomonitoringGraphsCountVegetalsBySplitList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsBySplitList>>,
  TError = ErrorType<BeeomonitoringGraphsCountVegetalsBySplitList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsCountVegetalsBySplitList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsCountVegetalsBySplitListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsDiofurValuesByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<BarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/diofur_values_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsDiofurValuesByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/diofur_values_by_period/`] as const
}

export const getBeeomonitoringGraphsDiofurValuesByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsDiofurValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsDiofurValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsDiofurValuesByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsDiofurValuesByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsDiofurValuesByPeriodList>>> = ({
    signal,
  }) => beeomonitoringGraphsDiofurValuesByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsDiofurValuesByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsDiofurValuesByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsDiofurValuesByPeriodList>>
>
export type BeeomonitoringGraphsDiofurValuesByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsDiofurValuesByPeriodList400>

export const useBeeomonitoringGraphsDiofurValuesByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsDiofurValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsDiofurValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsDiofurValuesByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsDiofurValuesByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsHeavymetalCountByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<BarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/heavymetal_count_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsHeavymetalCountByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/heavymetal_count_by_period/`] as const
}

export const getBeeomonitoringGraphsHeavymetalCountByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalCountByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsHeavymetalCountByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalCountByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsHeavymetalCountByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalCountByPeriodList>>> = ({
    signal,
  }) => beeomonitoringGraphsHeavymetalCountByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalCountByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsHeavymetalCountByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalCountByPeriodList>>
>
export type BeeomonitoringGraphsHeavymetalCountByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsHeavymetalCountByPeriodList400>

export const useBeeomonitoringGraphsHeavymetalCountByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalCountByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsHeavymetalCountByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalCountByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsHeavymetalCountByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsHeavymetalValuesByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<BarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/heavymetal_values_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsHeavymetalValuesByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/heavymetal_values_by_period/`] as const
}

export const getBeeomonitoringGraphsHeavymetalValuesByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsHeavymetalValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalValuesByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsHeavymetalValuesByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalValuesByPeriodList>>> = ({
    signal,
  }) => beeomonitoringGraphsHeavymetalValuesByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalValuesByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsHeavymetalValuesByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalValuesByPeriodList>>
>
export type BeeomonitoringGraphsHeavymetalValuesByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsHeavymetalValuesByPeriodList400>

export const useBeeomonitoringGraphsHeavymetalValuesByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsHeavymetalValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsHeavymetalValuesByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsHeavymetalValuesByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsListVegetalsList = (id: string, signal?: AbortSignal) => {
  return backendInstance<ListByYear[]>({ url: `/beeomonitoring/graphs/${id}/list_vegetals/`, method: 'GET', signal })
}

export const getBeeomonitoringGraphsListVegetalsListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/list_vegetals/`] as const
}

export const getBeeomonitoringGraphsListVegetalsListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsListVegetalsList>>,
  TError = ErrorType<BeeomonitoringGraphsListVegetalsList400>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsListVegetalsList>>, TError, TData>>
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsListVegetalsListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsListVegetalsList>>> = ({ signal }) =>
    beeomonitoringGraphsListVegetalsList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsListVegetalsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsListVegetalsListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsListVegetalsList>>
>
export type BeeomonitoringGraphsListVegetalsListQueryError = ErrorType<BeeomonitoringGraphsListVegetalsList400>

export const useBeeomonitoringGraphsListVegetalsList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsListVegetalsList>>,
  TError = ErrorType<BeeomonitoringGraphsListVegetalsList400>,
>(
  id: string,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsListVegetalsList>>, TError, TData>>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsListVegetalsListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsMapVegetalsByCategoryList = (id: string, signal?: AbortSignal) => {
  return backendInstance<PieChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/map_vegetals_by_category/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsMapVegetalsByCategoryListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/map_vegetals_by_category/`] as const
}

export const getBeeomonitoringGraphsMapVegetalsByCategoryListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByCategoryList>>,
  TError = ErrorType<BeeomonitoringGraphsMapVegetalsByCategoryList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByCategoryList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsMapVegetalsByCategoryListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByCategoryList>>> = ({
    signal,
  }) => beeomonitoringGraphsMapVegetalsByCategoryList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByCategoryList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsMapVegetalsByCategoryListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByCategoryList>>
>
export type BeeomonitoringGraphsMapVegetalsByCategoryListQueryError =
  ErrorType<BeeomonitoringGraphsMapVegetalsByCategoryList400>

export const useBeeomonitoringGraphsMapVegetalsByCategoryList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByCategoryList>>,
  TError = ErrorType<BeeomonitoringGraphsMapVegetalsByCategoryList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByCategoryList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsMapVegetalsByCategoryListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsMapVegetalsByFamilyList = (id: string, signal?: AbortSignal) => {
  return backendInstance<PieChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/map_vegetals_by_family/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsMapVegetalsByFamilyListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/map_vegetals_by_family/`] as const
}

export const getBeeomonitoringGraphsMapVegetalsByFamilyListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByFamilyList>>,
  TError = ErrorType<BeeomonitoringGraphsMapVegetalsByFamilyList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByFamilyList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsMapVegetalsByFamilyListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByFamilyList>>> = ({
    signal,
  }) => beeomonitoringGraphsMapVegetalsByFamilyList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByFamilyList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsMapVegetalsByFamilyListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByFamilyList>>
>
export type BeeomonitoringGraphsMapVegetalsByFamilyListQueryError =
  ErrorType<BeeomonitoringGraphsMapVegetalsByFamilyList400>

export const useBeeomonitoringGraphsMapVegetalsByFamilyList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByFamilyList>>,
  TError = ErrorType<BeeomonitoringGraphsMapVegetalsByFamilyList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsMapVegetalsByFamilyList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsMapVegetalsByFamilyListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsPahValuesByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<BarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/pah_values_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsPahValuesByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/pah_values_by_period/`] as const
}

export const getBeeomonitoringGraphsPahValuesByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPahValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPahValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPahValuesByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsPahValuesByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsPahValuesByPeriodList>>> = ({ signal }) =>
    beeomonitoringGraphsPahValuesByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsPahValuesByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsPahValuesByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsPahValuesByPeriodList>>
>
export type BeeomonitoringGraphsPahValuesByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsPahValuesByPeriodList400>

export const useBeeomonitoringGraphsPahValuesByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPahValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPahValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPahValuesByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsPahValuesByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsPcbValuesByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<BarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/pcb_values_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsPcbValuesByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/pcb_values_by_period/`] as const
}

export const getBeeomonitoringGraphsPcbValuesByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPcbValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPcbValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPcbValuesByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsPcbValuesByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsPcbValuesByPeriodList>>> = ({ signal }) =>
    beeomonitoringGraphsPcbValuesByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsPcbValuesByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsPcbValuesByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsPcbValuesByPeriodList>>
>
export type BeeomonitoringGraphsPcbValuesByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsPcbValuesByPeriodList400>

export const useBeeomonitoringGraphsPcbValuesByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPcbValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPcbValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPcbValuesByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsPcbValuesByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsPestCountByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<BarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/pest_count_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsPestCountByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/pest_count_by_period/`] as const
}

export const getBeeomonitoringGraphsPestCountByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPestCountByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPestCountByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPestCountByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsPestCountByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsPestCountByPeriodList>>> = ({ signal }) =>
    beeomonitoringGraphsPestCountByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsPestCountByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsPestCountByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsPestCountByPeriodList>>
>
export type BeeomonitoringGraphsPestCountByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsPestCountByPeriodList400>

export const useBeeomonitoringGraphsPestCountByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPestCountByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPestCountByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPestCountByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsPestCountByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsPestCountProportionsByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<StackedBarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/pest_count_proportions_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsPestCountProportionsByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/pest_count_proportions_by_period/`] as const
}

export const getBeeomonitoringGraphsPestCountProportionsByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPestCountProportionsByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPestCountProportionsByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPestCountProportionsByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsPestCountProportionsByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsPestCountProportionsByPeriodList>>> = ({
    signal,
  }) => beeomonitoringGraphsPestCountProportionsByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsPestCountProportionsByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsPestCountProportionsByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsPestCountProportionsByPeriodList>>
>
export type BeeomonitoringGraphsPestCountProportionsByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsPestCountProportionsByPeriodList400>

export const useBeeomonitoringGraphsPestCountProportionsByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPestCountProportionsByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPestCountProportionsByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPestCountProportionsByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsPestCountProportionsByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const beeomonitoringGraphsPestValuesByPeriodList = (id: string, signal?: AbortSignal) => {
  return backendInstance<BarChartByYear[]>({
    url: `/beeomonitoring/graphs/${id}/pest_values_by_period/`,
    method: 'GET',
    signal,
  })
}

export const getBeeomonitoringGraphsPestValuesByPeriodListQueryKey = (id: string) => {
  return [`/beeomonitoring/graphs/${id}/pest_values_by_period/`] as const
}

export const getBeeomonitoringGraphsPestValuesByPeriodListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPestValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPestValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPestValuesByPeriodList>>, TError, TData>
    >
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeomonitoringGraphsPestValuesByPeriodListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeomonitoringGraphsPestValuesByPeriodList>>> = ({ signal }) =>
    beeomonitoringGraphsPestValuesByPeriodList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeomonitoringGraphsPestValuesByPeriodList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeomonitoringGraphsPestValuesByPeriodListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeomonitoringGraphsPestValuesByPeriodList>>
>
export type BeeomonitoringGraphsPestValuesByPeriodListQueryError =
  ErrorType<BeeomonitoringGraphsPestValuesByPeriodList400>

export const useBeeomonitoringGraphsPestValuesByPeriodList = <
  TData = Awaited<ReturnType<typeof beeomonitoringGraphsPestValuesByPeriodList>>,
  TError = ErrorType<BeeomonitoringGraphsPestValuesByPeriodList400>,
>(
  id: string,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof beeomonitoringGraphsPestValuesByPeriodList>>, TError, TData>
    >
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeomonitoringGraphsPestValuesByPeriodListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
