import { useState } from 'react'
import { i18nKeys } from '@/locales/keys'
import impactsTableLoader from '@/pages/beeoimpact/impacts-tables/impacts-tables-page.loader'
import { HomeIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import SensitiveAreasTableMap from '@/components/beeoimpact/impacts/sensitive-areas-table-map'
import CallToACtion from '@/components/beeoimpact/sites/cta'
import SensitiveAreasTable from '@/components/beeoimpact/tables/sensitive-areas-table'
import SpeciesTable from '@/components/beeoimpact/tables/species-table'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'
import { H3 } from '@/components/typography'

const ImpactsTablesPage = () => {
  const { t } = useTranslation()
  const { site, cdda, natura2000, center } = useLoaderData() as Awaited<ReturnType<typeof impactsTableLoader>>

  const [selectedProtectedAreaId, setSelectedProtectedAreaId] = useState<number>(0)
  const [filtering, setFiltering] = useState<string>('')

  const [selectedPolygonId, setSelectedPolygonId] = useState<number>(0)

  const [clickRow, setClickRow] = useState<number>(0)
  const [clickRowSpecies, setClickRowSpecies] = useState<number>(0)

  return (
    <div className="container relative my-8">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon className="size-4" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOIMPACT_SITES)}>
            {t(i18nKeys.beeoimpact.common.breadcrumb.mySites)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOIMPACT_SITE, { params: { siteId: site.id } })}>
            {site.properties?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink>{t(i18nKeys.beeoimpact.common.breadcrumb.biodiversity)}</BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <div className="mb-4 flex flex-col justify-between">
        <PageHeader>
          <PageHeaderHeading>{t(i18nKeys.beeoimpact.common.breadcrumb.biodiversity)}</PageHeaderHeading>
        </PageHeader>
        <div className="pb-4">
          <CallToACtion
            href={`mailto:info@beeodiversity.com?Subject=${encodeURIComponent(t(i18nKeys.beeoimpact.site.cta.subject, { siteName: site.properties?.name }))}`}
          />
        </div>
      </div>

      <div className="flex flex-col justify-center ">
        <div className="h-[30rem] w-full rounded-md">
          {center && (
            <SensitiveAreasTableMap
              protectedAreas={natura2000}
              designatedAreas={cdda}
              center={center}
              selectedPolygonId={selectedPolygonId}
              setSelectedPolygonId={setSelectedPolygonId}
              setSelectedProtectedAreaId={setSelectedProtectedAreaId}
              selectedProtectedAreaId={selectedProtectedAreaId}
              clickRow={clickRow}
              setClickRow={setClickRow}
              clickRowSpecies={clickRowSpecies}
              setClickRowSpecies={setClickRowSpecies}
            />
          )}
        </div>
        <div className="mb-8">
          <div className="mb-4 flex flex-row items-end space-x-2">
            <H3>{t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.sensitiveAreas)}</H3>
            <RenderTooltip
              size={24}
              explanationText={t(
                i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.sensitiveAreasDefinition
              )}
            />
          </div>
          <Separator />
        </div>
        <Card className="w-full min-w-0">
          <CardContent className="py-8">
            <SensitiveAreasTable
              protectedAreas={natura2000}
              designatedAreas={cdda}
              site={site}
              selectedPolygonId={selectedPolygonId}
              clickRow={clickRow}
              setClickRow={setClickRow}
            />
          </CardContent>
        </Card>
        <div className="my-8">
          <H3 className="mb-4 leading-none">
            {t(i18nKeys.beeoimpact.biodiversityTable.biodiversitySpecies.table.species)}
          </H3>
          <Separator />
        </div>

        <Card className="w-full min-w-0">
          <CardContent>
            <SpeciesTable
              protectedAreas={natura2000}
              selectedProtectedAreaId={selectedProtectedAreaId}
              filtering={filtering}
              setFiltering={setFiltering}
              clickRowSpecies={clickRowSpecies}
              setClickRowSpecies={setClickRowSpecies}
              site={site}
            />
          </CardContent>
        </Card>
      </div>
    </div>
  )
}

export default ImpactsTablesPage
