import { useRef } from 'react'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Icons } from '@/components/icons'

const LandingPage = () => {
  const { t } = useTranslation()
  const screenRef = useRef(null)

  const scrollTo = (ref) => {
    window.scrollTo({
      top: ref.offsetHeight,
      behavior: 'smooth',
    })
  }
  return (
    <div className="container relative">
      <div className="flex h-screen flex-col justify-start pt-32" ref={screenRef}>
        <div className="flex flex-row justify-center">
          <div className="flex w-5/12 flex-col justify-center space-y-3">
            <h2 className="text-5xl">{t(i18nKeys.global.common.landingPage.mainTitle)}</h2>
            <div className="w-full space-y-2 pt-4">
              <h3 className="pt-2">{t(i18nKeys.global.common.landingPage.subTitle1)}</h3>
              <h3>{t(i18nKeys.global.common.landingPage.subTitle2)}</h3>
            </div>
            <div className="pt-8">
              <Button onClick={() => scrollTo(screenRef.current)}>
                {t(i18nKeys.global.common.landingPage.buttonLearnMore)}
              </Button>
            </div>
          </div>
          <div className="h-64 w-4/12 ">
            <img
              src="https://beeodiversitystorage.blob.core.windows.net/public/site_image-sm.jpg"
              alt="site_image"
              className="rounded-sm"
            />
          </div>
        </div>
      </div>
      <div className="flex h-screen flex-col items-center">
        <h2 className="w-8/12 space-x-6 pb-2 text-5xl">{t(i18nKeys.global.common.landingPage.subTitle1)}</h2>
        <div className="flex flex-row items-start justify-center">
          <div className="flex h-4/6 w-6/12 flex-row items-center justify-end space-x-12 pr-20 pt-36">
            <div className="flex w-48  flex-col space-y-4">
              <div className="flex flex-row items-center">
                <Icons.FlaskConical className=" size-6" />
                <h3 className=" font-bold">{t(i18nKeys.global.common.landingPage.pesticides)}</h3>
              </div>

              <h3 className="font-bold">{t(i18nKeys.global.common.landingPage.pesticidesText)}</h3>
            </div>
            <div className="flex w-48 flex-col space-y-4 ">
              <div className="flex flex-row items-center space-x-1">
                <Icons.AlertTriangle className=" w-6" />
                <h3 className=" font-bold">{t(i18nKeys.global.common.landingPage.risks)}</h3>
              </div>
              <h3 className=" font-bold">{t(i18nKeys.global.common.landingPage.risksText)}</h3>
            </div>
          </div>
          <div className="flex h-4/6 w-6/12 items-center">
            <div>
              <img
                src="https://beeodiversitystorage.blob.core.windows.net/public/pesticides-table-2.png"
                className="w-8/12 translate-x-10 translate-y-48  brightness-50 "
                alt="site_elements_example"
              />
              <img
                src="https://beeodiversitystorage.blob.core.windows.net/public/pesticides-table-2.png"
                className="relative w-8/12 drop-shadow-lg "
                alt="site_elements_example"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-screen flex-col items-center">
        <h2 className="w-8/12 space-x-6 pb-2 text-5xl">{t(i18nKeys.global.common.landingPage.subTitle2)}</h2>
        <div className="flex flex-row items-start justify-center">
          <div className="flex h-4/6 w-6/12 flex-row items-start justify-end space-x-12 pr-20 pt-36">
            <div className="flex w-48 flex-col space-y-4">
              <div className="flex flex-row items-center space-x-2">
                <Icons.Europe />
                <h3 className="font-bold">{t(i18nKeys.global.common.landingPage.euTaxonomy)}</h3>
              </div>
              <h3 className="font-bold">{t(i18nKeys.global.common.landingPage.euTaxonomyText)}</h3>
            </div>
            <div className="flex w-48 flex-col space-y-4 pb-6">
              <div className="flex flex-row items-end justify-start">
                <span className="w-6">
                  <Icons.MapPin />
                </span>
                <h3 className="font-bold">{t(i18nKeys.global.common.landingPage.nationalAreas)}</h3>
              </div>
              <h3 className="pl-2 font-bold">{t(i18nKeys.global.common.landingPage.nationalAreasText)}</h3>
            </div>
          </div>
          <div className="flex h-4/6 w-6/12 items-center">
            <div>
              <img
                src="https://beeodiversitystorage.blob.core.windows.net/public/diversity_pesticides_summary.png"
                className="z-10 w-8/12 translate-x-10  translate-y-48 brightness-50"
                alt="site_elements_example"
              />
              <img
                src="https://beeodiversitystorage.blob.core.windows.net/public/diversity_pesticides_summary.png"
                className="relative z-50 w-8/12 drop-shadow-lg"
                alt="site_elements_example"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-screen flex-col items-center">
        <h2 className=" space-x-6 pb-2 text-5xl">{t(i18nKeys.global.common.landingPage.benefits)} </h2>
        <div className="flex flex-row items-start justify-center">
          <div className="flex h-4/6 w-7/12 flex-row items-start justify-end space-x-8 pr-20 pt-24">
            <div className="flex  flex-col items-center justify-end pt-4">
              <div className="flex w-48 flex-row items-center space-x-2">
                <h3>
                  <Icons.Square />
                </h3>
                <h3 className=" text-sm font-bold">{t(i18nKeys.global.common.landingPage.multiSite)}</h3>
              </div>
              <div className="mt-1 flex w-48  flex-row space-x-2 pt-16">
                <div className="flex flex-row items-center">
                  <h3>
                    <Icons.Square />
                  </h3>
                </div>
                <h3 className="text-sm font-bold">{t(i18nKeys.global.common.landingPage.simpleAutomatic)}</h3>
              </div>
              <div className="mt-2 flex w-48  flex-row space-x-2 pt-20">
                <div className="flex flex-row">
                  <h3 className=" pt-0.5 text-lg font-bold">
                    <Icons.Square />
                  </h3>
                </div>
                <h3 className="pt-0.5 text-sm font-bold">{t(i18nKeys.global.common.landingPage.resolution)}</h3>
              </div>
            </div>
            <div>
              <div className="flex flex-col items-start justify-end pt-4">
                <div className="flex w-56 flex-row space-x-2">
                  <h3 className=" pt-0.5 text-lg font-bold">
                    <Icons.Square />
                  </h3>
                  <h3 className="text-sm font-bold">{t(i18nKeys.global.common.landingPage.effectivePrioritization)}</h3>
                </div>
                <div className="flex w-56 flex-row space-x-2 pt-12">
                  <div className="flex flex-row ">
                    <h3 className="pt-0.5 text-lg font-bold">
                      <Icons.Square />
                    </h3>
                  </div>
                  <h3 className="text-sm font-bold">{t(i18nKeys.global.common.landingPage.usefulDataEU)}</h3>
                </div>
                <div className="flex w-56 flex-row  space-x-2 pt-12">
                  <div className="flex flex-row ">
                    <h3 className="pt-2 text-lg font-bold">
                      <Icons.Square />
                    </h3>
                  </div>
                  <h3 className="pt-2 text-sm font-bold">
                    {t(i18nKeys.global.common.landingPage.reliableMonitoringData)}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="flex h-4/6 w-8/12 items-center">
            <div>
              <img
                src="https://beeodiversitystorage.blob.core.windows.net/public/allsites-1.png"
                className="z-10 w-8/12 translate-x-10  translate-y-48 brightness-50"
                alt="site_elements_example"
              />
              <img
                src="https://beeodiversitystorage.blob.core.windows.net/public/allsites-1.png"
                className="relative z-50 w-8/12 drop-shadow-lg"
                alt="site_elements_example"
              />
            </div>
          </div>
        </div>
        <a
          href="mailto:info@beeodiversity.com?Subject=Access to Beeoimpact"
          className="flex h-12 w-36 items-center justify-center rounded-lg bg-teal-400 text-white"
        >
          {t(i18nKeys.global.common.landingPage.getAccess)}
        </a>
      </div>
    </div>
  )
}
export default LandingPage
