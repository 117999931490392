import { locales } from '@/locales'
import i18n, { ResourceLanguage } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { z } from 'zod'
import { zodI18nMap } from 'zod-i18n-map'
import zodEn from 'zod-i18n-map/locales/en/zod.json'
import zodFr from 'zod-i18n-map/locales/fr/zod.json'
import zodNl from 'zod-i18n-map/locales/nl/zod.json'

import { AvailableLanguage } from '@/types/available-language'
import { STORAGE_KEYS } from '@/lib/constants/local-storage'

const zodI18n = {
  en: zodEn,
  fr: zodFr,
  nl: zodNl,
}

const getRessources = (languages: AvailableLanguage[]) =>
  languages.reduce(
    (acc, language) => ({
      ...acc,
      [language]: {
        translation: { ...locales[language] },
        zod: { ...zodI18n[language], ...locales[language]['global']['validation'] },
      },
    }),
    {} as Record<AvailableLanguage, ResourceLanguage>
  )

export const getI18nInstance = () => {
  const languages = [AvailableLanguage.EN, AvailableLanguage.FR, AvailableLanguage.NL]
  const lastSelectedLanguage = localStorage.getItem(STORAGE_KEYS.LAST_SELECTED_LANGUAGE)

  z.setErrorMap(zodI18nMap)

  i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
      lng: lastSelectedLanguage || AvailableLanguage.EN,
      resources: getRessources(languages),
      fallbackLng: AvailableLanguage.EN,
      interpolation: {
        escapeValue: false,
      },
    })

  return i18n
}
