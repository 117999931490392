import { useState } from 'react'
import { Audit, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Separator } from '@/components/ui/separator'
import {
  Sheet,
  SheetContent,
  SheetDescription,
  SheetHeader,
  SheetPortal,
  SheetTitle,
  SheetTrigger,
} from '@/components/ui/sheet'
import { toast } from '@/components/ui/use-toast'
import AuditForm from '@/components/beeoaudit/audit-form'

type AuditFormSliderProps = {
  audit?: Audit
  site: Site
  onAuditSave: (newAudit: Audit) => void
  children: React.ReactNode
}

const AuditFormSlider = ({ children, audit, site, onAuditSave }: AuditFormSliderProps) => {
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)
  const [isFormDirty, setIsFormDirty] = useState(false)
  // Prevent closing the sheet if the form is dirty
  const onOpenChange = (newOpenStatus) => {
    if (isFormDirty && newOpenStatus == false) {
      toast({
        title: t(i18nKeys.beeoaudit.auditForm.notification.preventClose),
        description: t(i18nKeys.beeoaudit.auditForm.notification.preventCloseDescription),
      })
      return
    }
    setIsOpen(newOpenStatus)
  }
  return (
    <Sheet open={isOpen} onOpenChange={onOpenChange}>
      <SheetTrigger asChild>{children}</SheetTrigger>
      <SheetPortal>
        <SheetContent className="w-[500px] max-w-[500px] overflow-auto sm:w-[540px]" side="right">
          <SheetHeader className="pb-4">
            <SheetTitle>{t(i18nKeys.beeoaudit.auditForm.addAudit)}</SheetTitle>
            <SheetDescription>{t(i18nKeys.beeoaudit.auditForm.addAuditDescription)}</SheetDescription>
          </SheetHeader>
          <Separator className="mb-6" />
          <AuditForm
            audit={audit}
            site={site}
            onAuditSave={onAuditSave}
            setIsFormDirty={setIsFormDirty}
            setIsOpen={setIsOpen}
          />
        </SheetContent>
      </SheetPortal>
    </Sheet>
  )
}

export default AuditFormSlider
