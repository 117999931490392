import { useTranslation } from 'react-i18next'

import { PERIODS } from '@/lib/initiative'
import { cn } from '@/lib/utils'

interface MonthsProps {
  selectedMonths?: string[]
  onMonthClick?: (key: string) => void
  className?: string
}

const Months = ({ selectedMonths = [], onMonthClick = () => {}, className }: MonthsProps) => {
  const { t } = useTranslation()
  const months = PERIODS
  const onClick = (key) => () => onMonthClick?.(key)
  return (
    <div className={cn('flex flex-row flex-wrap content-start gap-2', className)}>
      {Object.keys(months).map((key) => (
        <div
          key={key}
          onClick={onClick(key)}
          className={cn(
            'flex size-8 cursor-pointer items-center justify-center rounded border border-[#919191] text-xs font-bold uppercase',
            {
              'bg-[#1F6F6C]': selectedMonths?.includes(key),
              'border-[#1F6F6C]': selectedMonths?.includes(key),
            }
          )}
        >
          {selectedMonths?.includes(key) ? (
            <div className="text-white">{t(months[key].labelKey)[0]}</div>
          ) : (
            <span className="text-[#919191]">{t(months[key].labelKey)[0]}</span>
          )}
        </div>
      ))}
    </div>
  )
}

export default Months
