import { BarChartByYear, ListByYear, ListValues, PieChartByYear, StackedBarChartByYear } from '@/client/backend/models'

import { BarChartValueWithColor, ValuePieChart } from '@/types/graph'
import { COLORS } from '@/lib/constants/constants'

export const getPiePeriodData = (data: PieChartByYear[], periodKey: string, selectedYear: string): ValuePieChart[] => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)
  return (
    period?.items.map((item, index) => ({
      id: index,
      label: item.name,
      value: item.value,
      color: COLORS[index % COLORS.length],
    })) || []
  )
}

export const getBarPeriodData = (
  data: BarChartByYear[],
  periodKey: string,
  selectedYear: string
): BarChartValueWithColor[] => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)
  return (
    (period?.items.map((item, index) => ({
      ...item,
      color: COLORS[index % COLORS.length],
      period: periodKey,
      isHidden: false,
    })) as BarChartValueWithColor[]) || []
  )
}

export const getBarStackedPeriodData = (
  data: StackedBarChartByYear[],
  periodKey: string,
  selectedYear: string
): Record<string, number>[] => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)

  const result =
    period?.items.reduce(
      (acc, item) => {
        const key = item.category || 'other'
        acc[key] = item.value
        return acc
      },
      { data: 0 } as Record<string, number>
    ) || {}

  return [result]
}

export const getBarSubstancePeriodData = (
  data: BarChartByYear[],
  periodKey: string,
  selectedYear: string
): { fullData: BarChartValueWithColor[]; filteredItems: BarChartValueWithColor[] } => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)
  const fullData: BarChartValueWithColor[] =
    period?.items.map((item) => ({
      ...item,
      color: item.name?.toLowerCase().startsWith('sum') ? COLORS[0] : COLORS[1],
      period: periodKey,
      isHidden: false,
    })) || []

  const filteredItems = fullData.filter((item) => item.to_chart)

  return {
    fullData, // Full object transformed to BarChartValueWithColor
    filteredItems, // Only filtered items
  }
}

export const getBarDiofurData = (
  data: BarChartByYear[],
  periodKey: string,
  selectedYear: string
): {
  groupedData: Record<string, BarChartValueWithColor[]>
  sumItems: BarChartValueWithColor[]
} => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)

  const groupedData =
    period?.items.reduce(
      (acc, item) => {
        const key = item.type || 'other'
        if (!acc[key]) {
          acc[key] = []
        }
        acc[key].push({
          ...item,
          color: COLORS[acc[key].length % COLORS.length],
          period: periodKey,
          isHidden: false,
        })
        return acc
      },
      {} as Record<string, BarChartValueWithColor[]>
    ) || {}

  const sumItems: BarChartValueWithColor[] = Object.entries(groupedData)
    .map(([, items]) => {
      return items.filter((item) => item.name?.toLowerCase().startsWith('sum'))
    })
    .flat()

  return { groupedData, sumItems }
}

export const getListPeriodData = (data: ListByYear[], periodKey: string, selectedYear: string): ListValues[] => {
  const currentYearData = data.find((item) => item.year === selectedYear)
  const period = currentYearData?.periods.find((item) => item.key === periodKey)
  return period?.items || []
}
