import { Dispatch, SetStateAction } from 'react'
import {
  cultureMappingsDestroy,
  cultureMappingsPartialUpdate,
} from '@/client/backend/api/culture-mappings/culture-mappings'
import { CultureMapping } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { CheckCircle, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'

type CultureMappingsTableRowActionProps = {
  mapping: CultureMapping
  mappings: CultureMapping[]
  setMappings: Dispatch<SetStateAction<CultureMapping[]>>
}
const CultureMappingsTableRowAction = ({ mapping, mappings, setMappings }: CultureMappingsTableRowActionProps) => {
  const { t } = useTranslation()

  const deleteMapping = async (mapping: CultureMapping) => {
    await cultureMappingsDestroy(mapping.id)
    setMappings(mappings.filter((currentMapping) => mapping.id !== currentMapping.id))
  }

  const validateMapping = async (mapping: CultureMapping) => {
    await cultureMappingsPartialUpdate(mapping.id, { validated: true })
    setMappings(
      mappings.map((currentMapping) => {
        if (mapping.id === currentMapping.id) {
          return { ...currentMapping, validated: true }
        }
        return currentMapping
      })
    )
  }

  return (
    <div className="flex items-center justify-center">
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline" className="hidden border-0 lg:flex">
            <CheckCircle className="size-3" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Validate {mapping.phyto_culture.name} with {mapping.pac_culture.source_culture_name} ?
            </AlertDialogTitle>
            <AlertDialogDescription>{t(i18nKeys.global.settings.page.sites.dialog.description)}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t(i18nKeys.global.settings.page.sites.dialog.button.cancel)}</AlertDialogCancel>

            <Button onClick={() => validateMapping(mapping)}>Validate</Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline" className="hidden border-0 lg:flex">
            <Trash2 className="size-3" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              Delete {mapping.phyto_culture.name} with {mapping.pac_culture.source_culture_name} ?
            </AlertDialogTitle>
            <AlertDialogDescription>{t(i18nKeys.global.settings.page.sites.dialog.description)}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t(i18nKeys.global.settings.page.sites.dialog.button.cancel)}</AlertDialogCancel>

            <Button variant="destructive" onClick={() => deleteMapping(mapping)}>
              {t(i18nKeys.global.settings.page.sites.dialog.button.delete)}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default CultureMappingsTableRowAction
