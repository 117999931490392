import { useEffect, useRef } from 'react'
import L, { LatLngExpression, Map } from 'leaflet'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'

import { FeatureType } from '@/types/feature-type'
import { LightSites } from '@/types/light-site'
import { getMapIcon } from '@/lib/map'
import { hasAccesTo } from '@/lib/permission'
import { useAuth } from '@/hooks/useAuth'
import { H4 } from '@/components/typography'

const CENTER = { lat: 41.8503, lng: 3.3517 }
const ComponentResize = () => {
  const map = useMap()

  setTimeout(() => {
    map.invalidateSize()
  }, 0)

  return null
}

type SitesMapProps = {
  sites: LightSites[]
  hoveredSiteId?: number
  showTooltip: boolean
}
const SitesMap = ({ sites, hoveredSiteId, showTooltip = true }: SitesMapProps) => {
  const mapRef = useRef<Map>(null)
  const markerRefs = useRef({})
  const { user } = useAuth()

  const openPopup = (id) => {
    if (markerRefs.current[id]) {
      markerRefs.current[id].openPopup()
    }
  }
  useEffect(() => {
    if (hoveredSiteId) {
      openPopup(hoveredSiteId)
    }
  }, [hoveredSiteId])

  const worldBounds = new L.LatLngBounds(new L.LatLng(-88, -180), new L.LatLng(88, 180))

  const getRiskColor = (score) => {
    if (score < 30) return 'green'
    if (score >= 30 && score <= 70) return 'orange'
    return 'red'
  }

  return (
    <MapContainer
      className="z-0 size-full shadow-inner"
      center={CENTER}
      zoom={6}
      dragging={true}
      scrollWheelZoom={false}
      ref={mapRef}
      maxBounds={worldBounds}
      maxBoundsViscosity={1}
    >
      <ComponentResize />
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {sites?.map((site) => {
        const coordinates = site.center.coordinates ?? []
        const color = getRiskColor(site.score_biodiversity)
        return (
          <Marker
            key={site.id}
            icon={getMapIcon(color)}
            position={{ lat: coordinates[1], lng: coordinates[0] } as LatLngExpression}
            eventHandlers={{
              mouseover: (event) => event.target.openPopup(),
              mouseout: (event) => event.target.closePopup(),
            }}
            ref={(ref) => (markerRefs.current[site.id] = ref)}
          >
            <Popup>
              <H4 className="mt-0">{site?.name}</H4>
              {showTooltip && (
                <ul>
                  {hasAccesTo(user?.account?.features ?? [], FeatureType.BIODIVERSITY) && (
                    <li>Biodiversity risk : {site.score_biodiversity?.toFixed(0)} % </li>
                  )}
                  {hasAccesTo(user?.account?.features ?? [], FeatureType.PESTICIDES) && (
                    <li>Pesticides risk : {site.score_pesticides?.toFixed(0)} %</li>
                  )}
                </ul>
              )}
            </Popup>
          </Marker>
        )
      })}
    </MapContainer>
  )
}

export default SitesMap
