import { CLCPlot } from '@/client/backend/models'
import { LatLng } from 'leaflet'
import { Polygon, Tooltip } from 'react-leaflet'

import { invertCoordinates } from '@/lib/geo'

interface SiteLocationMapProps {
  clc: CLCPlot
}
const SiteLocationMap = ({ clc }: SiteLocationMapProps) => {
  const getNewGeometry = (clc: CLCPlot): LatLng[] | LatLng[][] | null => {
    const coordinates = clc.intersection?.['coordinates']
    const polygonType = clc.intersection?.['type'] as string

    if (polygonType === 'Polygon') {
      return invertCoordinates(coordinates?.[0])
    } else if (polygonType === 'MultiPolygon') {
      if (clc?.intersection?.['coordinates']) {
        return clc?.intersection?.['coordinates'].flat(1).map((polygon) => invertCoordinates(polygon))
      }
    }
    return null
  }

  const newGeom = getNewGeometry(clc)
  if (!newGeom) {
    return null
  }

  const fillColor = clc.type.color
  const options = {
    color: 'white',
    fillColor: fillColor ? fillColor : 'white',
    fillOpacity: 0.5,
    weight: 1,
    opacity: 2,
  }
  return (
    <Polygon pathOptions={options} positions={newGeom} className="outline-none" pane="markerPane">
      <Tooltip sticky>
        <ul>{clc.type.name_level3}</ul>
      </Tooltip>
    </Polygon>
  )
}

export default SiteLocationMap
