import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'

const CallToACtion = ({ href }) => {
  const { t } = useTranslation()
  return (
    <Card className="flex w-full  flex-col justify-between bg-stone-50">
      <CardHeader className="flex flex-col">
        <CardTitle>{t(i18nKeys.beeoimpact.site.cta.title)}</CardTitle>
        <CardTitle className="text-base text-gray-500">{t(i18nKeys.beeoimpact.site.cta.text)}</CardTitle>
      </CardHeader>
      <CardContent>
        {' '}
        <a href={href}>
          <Button className="text-base">{t(i18nKeys.beeoimpact.site.cta.button)}</Button>
        </a>
      </CardContent>
    </Card>
  )
}
export default CallToACtion
