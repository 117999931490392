import { SpeciesSample } from '@/client/backend/models'
import { GbifMarker } from '@/pages/beeoimpact/general-analysis/general-analysis'

import { getPoint } from '@/lib/geo'

export const getGbifMarkers = (gbif: SpeciesSample[]) => {
  return gbif.map(
    (specie) =>
      ({
        id: specie.id,
        name: specie.canonical_name,
        marker: getPoint(specie.gbif_location?.coordinates),
        category: specie.taxon_kingdom,
      }) as GbifMarker
  )
}
