import { Site } from '@/client/backend/models/site'
import L, { LatLng, LatLngBounds, LatLngExpression } from 'leaflet'

export const getSiteCenter = (site: Site): LatLng => {
  const coordinates = site.geometry?.coordinates?.[0]

  const bounds = getBounds(coordinates)
  return bounds.getCenter()
}

export const getBounds = (coordinates?: number[][]): L.LatLngBounds => {
  if (!coordinates || coordinates.length === 0) {
    return L.latLngBounds([0, 0], [0, 0])
  }

  const invertedCoordinates = L.GeoJSON.coordsToLatLngs(coordinates) as LatLng[]
  const polygon = L.polygon(invertedCoordinates)
  return polygon.getBounds()
}

export const invertCoordinates = (coordinates: number[][] | number[][][] | undefined): LatLng[] => {
  if (!coordinates || coordinates.length === 0) {
    return [{ lat: 0, lng: 0 }] as LatLng[]
  }
  return L.GeoJSON.coordsToLatLngs(coordinates) as LatLng[]
}

export const DEFAULT_RADIUS = 1500

export const getPoint = (coordinates: number[] | undefined): LatLngExpression => {
  if (!coordinates || coordinates.length === 0) {
    return { lat: 0, lng: 0 } as LatLng
  }
  const lat = coordinates[1]
  const lng = coordinates[0]

  return { lat, lng } as LatLngExpression
}

export const DEFAULT_CENTER = { lat: 45.7831, lng: 3.0824 }

/**
 * Calculates the bounding box (LatLngBounds) from a given center point and radius.
 *
 * @param center - The central point (LatLng) from which the bounds are calculated.
 * @param radiusInMeters - The radius in meters around the center point to calculate the bounds.
 * @param bufferRatio - An optional buffer ratio to pad the bounds. A ratio of 0.1 extends the bounds by 10% in each direction. (default: 0.1)
 * @returns The calculated bounding box (LatLngBounds) with the specified radius and buffer.
 */
export const getBoundsFromCenterAndRadius = (
  center: LatLng,
  radiusInMeters: number,
  bufferRatio: number = 0.1
): LatLngBounds => {
  return center.toBounds(radiusInMeters * 2).pad(bufferRatio)
}
