import { useEffect, useState } from 'react'
import {
  getSitesAuditsListQueryKey,
  getSitesRetrieveQueryKey,
  sitesAuditsList,
  sitesRetrieve,
} from '@/client/backend/api/sites/sites'
import { Audit } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { HomeIcon, MapPin, Target } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Circle, MapContainer, TileLayer } from 'react-leaflet'
import { useParams } from 'react-router'

import { DEFAULT_RADIUS, getSiteCenter } from '@/lib/geo'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'
import AuditFormSlider from '@/components/beeoaudit/audit-form-slider'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb'
import { Icons } from '@/components/icons'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { H3 } from '@/components/typography'

import AuditsTable from '../../../../components/audits-table'

const SiteDetailsAuditPage = () => {
  const { t } = useTranslation()
  const { siteId } = useParams<{ siteId: string }>() as { siteId: string }
  const [siteAudits, setSiteAudits] = useState<Audit[]>([])

  const { data: site, isLoading } = useQuery({
    queryFn: () => sitesRetrieve(siteId),
    queryKey: [getSitesRetrieveQueryKey(siteId)],
  })

  const { data: siteAuditsQueryResults = [], isLoading: isTableLoading } = useQuery({
    queryFn: () => sitesAuditsList(siteId),
    queryKey: [getSitesAuditsListQueryKey(siteId)],
  })

  useEffect(() => {
    if (!isTableLoading && Array.isArray(siteAuditsQueryResults)) {
      setSiteAudits(siteAuditsQueryResults)
    }
  }, [siteAuditsQueryResults, isTableLoading])

  const onAuditCreate = (newAudit) => {
    setSiteAudits((prev) => [newAudit, ...prev])
  }

  const onAuditSave = (savedAudit) => {
    const updatedAudits = siteAudits.map((audit) => (audit.id === savedAudit.id ? savedAudit : audit))
    setSiteAudits(updatedAudits)
  }

  const onAuditDelete = (deletedAudit) => {
    const updatedAudits = siteAudits.filter((audit) => audit.id !== deletedAudit.id)
    setSiteAudits(updatedAudits)
  }

  if (!site) return null
  const center = getSiteCenter(site)

  return (
    <div className="container relative my-8">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon className="size-4" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOAUDIT_SITES)}>
            {t(i18nKeys.beeoimpact.common.breadcrumb.mySites)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          {isLoading ? (
            <Skeleton className="h-4 w-20" />
          ) : (
            <BreadcrumbLink href={getPath(Routes.BEEOAUDIT_SITE, { params: { siteId: site?.id } })}>
              {site?.properties?.name}
            </BreadcrumbLink>
          )}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="flex flex-col justify-between">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>
              {isLoading ? <Skeleton className="h-4 w-20" /> : site?.properties?.name}
            </PageHeaderHeading>
          </PageHeader>
        </div>
      </div>
      <div className="flex flex-row">
        <Card className="w-1/3">
          <CardHeader>
            <CardTitle>{t(i18nKeys.beeoaudit.siteDetails.info)}</CardTitle>
            <CardDescription>{t(i18nKeys.beeoaudit.siteDetails.desc)}</CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col justify-evenly space-y-4 overflow-auto rounded-r-md ">
              <div className="flex flex-row space-x-4">
                <Target className="text-muted-foreground" />
                <div>
                  <p>{isLoading ? <Skeleton className="h-4 w-20" /> : site?.properties?.name}</p>
                  <p className="text-muted-foreground">{t(i18nKeys.beeoaudit.siteDetails.name)}</p>
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <MapPin />
                <div>
                  <p>{isLoading ? <Skeleton className="h-4 w-20" /> : site.properties?.center?.coordinates?.[0]}</p>
                  <p className="text-muted-foreground">{t(i18nKeys.beeoaudit.siteDetails.longitude)}</p>
                </div>
              </div>
              <div className="flex flex-row space-x-4">
                <MapPin />
                <div>
                  <p>{isLoading ? <Skeleton className="h-4 w-20" /> : site.properties?.center?.coordinates?.[1]}</p>
                  <p className="text-muted-foreground">{t(i18nKeys.beeoaudit.siteDetails.latitude)}</p>
                </div>
              </div>
            </div>
          </CardContent>
        </Card>
        <Card className="ml-4 flex w-1/3 cursor-pointer items-center rounded-lg hover:shadow-lg">
          <AuditFormSlider site={site} onAuditSave={onAuditCreate}>
            <CardContent className="flex w-full flex-col items-center justify-center text-center">
              <Icons.PlusCircleIcon className="size-16" />
              <H3>{t(i18nKeys.beeoaudit.siteDetails.addAudit)}</H3>
              <p className="mt-6">{t(i18nKeys.beeoaudit.siteDetails.addAuditDesc)}</p>
            </CardContent>
          </AuditFormSlider>
        </Card>
        <Card className="ml-4 w-1/3 border-none">
          <div>
            <MapContainer
              className="3xl:h-[400px] z-0 h-96 rounded-2xl hover:cursor-pointer sm:h-48 md:h-[300px] xl:h-[300px]"
              center={center}
              zoom={12}
              dragging={true}
              scrollWheelZoom={false}
              zoomControl={false}
              attributionControl={false}
            >
              <Circle center={center} color="blue" fillColor="none" weight={1} radius={DEFAULT_RADIUS} />
              <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </MapContainer>
          </div>
        </Card>
      </div>
      <AuditsTable
        site={site}
        onAuditDelete={onAuditDelete}
        onAuditSave={onAuditSave}
        siteAudits={siteAudits}
        isLoading={isTableLoading}
      />
    </div>
  )
}

export default SiteDetailsAuditPage
