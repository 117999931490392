import { useEffect, useMemo, useState } from 'react'
import {
  beeomonitoringGraphsPestCountByPeriodList,
  beeomonitoringGraphsPestCountProportionsByPeriodList,
  beeomonitoringGraphsPestValuesByPeriodList,
  getBeeomonitoringGraphsPestCountByPeriodListQueryKey,
  getBeeomonitoringGraphsPestCountProportionsByPeriodListQueryKey,
  getBeeomonitoringGraphsPestValuesByPeriodListQueryKey,
} from '@/client/backend/api/graphs/graphs'
import {
  beeomonitoringSitesSiteInfosRetrieve,
  getBeeomonitoringSitesSiteInfosRetrieveQueryKey,
} from '@/client/backend/api/sites/sites'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { HomeIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { BarChartValueWithColor } from '@/types/graph'
import { COLORS, COLORS_REVERSED } from '@/lib/constants/constants'
import { getBarPeriodData, getBarStackedPeriodData } from '@/lib/graph'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Skeleton } from '@/components/ui/skeleton'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb'
import CardBarChart from '@/components/card-barchart'
import CardBarChartSkeleton from '@/components/card-barchart-skeleton'
import CardBarChartStacked from '@/components/card-barchart-stacked'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { LI, UL } from '@/components/typography'

const PesticidesMonitoringPage = () => {
  const [searchParams] = useSearchParams()

  const year = searchParams.get('year') ?? '2023'
  const period = searchParams.get('period') ?? 'P4'

  const { t } = useTranslation()
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedPeriod, setSelectedPeriod] = useState(period)
  const [years, setYears] = useState<string[]>(['2023'])
  const [periods, setPeriods] = useState<string[]>([])

  const [countPesticides, setCountPesticides] = useState<BarChartValueWithColor[]>([])
  const [countPesticidesValues, setCountPesticidesValues] = useState<BarChartValueWithColor[]>([])
  const [countPesticidesProportions, setCountPesticidesProportions] = useState<Record<string, number>[]>([])

  useEffect(() => {
    setSelectedYear(year ?? selectedYear)
    setSelectedPeriod(period ?? selectedPeriod)
  }, [year, period])

  const { siteId } = useParams<{ siteId: string }>() as { siteId: string }

  const { data: site, isLoading: isLoadingSite } = useQuery({
    queryKey: [getBeeomonitoringSitesSiteInfosRetrieveQueryKey(siteId)],
    queryFn: () => beeomonitoringSitesSiteInfosRetrieve(siteId, undefined),
  })

  const { data: dataCountPesticides, isLoading: isLoadingCountPesticides } = useQuery({
    queryKey: [getBeeomonitoringGraphsPestCountByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsPestCountByPeriodList(siteId, undefined),
  })

  const { data: dataCountPesticidesValues, isLoading: isLoadingCountPesticidesValues } = useQuery({
    queryKey: [getBeeomonitoringGraphsPestValuesByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsPestValuesByPeriodList(siteId, undefined),
  })

  const { data: dataStackedBarChart, isLoading: isLoadingStackedBarChart } = useQuery({
    queryKey: [getBeeomonitoringGraphsPestCountProportionsByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsPestCountProportionsByPeriodList(siteId, undefined),
  })

  const allYears = useMemo(() => {
    const yearsCategories = dataCountPesticides?.map((item) => item.year) || []
    const yearsFamilies = dataCountPesticidesValues?.map((item) => item.year) || []
    const yearsProportions = dataStackedBarChart?.map((item) => item.year) || []

    return Array.from(new Set([...yearsCategories, ...yearsFamilies, ...yearsProportions])).sort()
  }, [dataCountPesticides, dataCountPesticidesValues, dataStackedBarChart])

  useEffect(() => {
    if (allYears.length > 0) {
      setYears(allYears)
      setSelectedYear(year ?? allYears[allYears.length - 1])
    }
  }, [allYears])

  const allPeriods = useMemo(() => {
    const periodsCountHms = dataCountPesticides?.find((item) => item.year === selectedYear)?.periods || []
    const periodsCountHmsValues = dataCountPesticidesValues?.find((item) => item.year === selectedYear)?.periods || []
    const periodsDataStackedBarChart = dataStackedBarChart?.find((item) => item.year === selectedYear)?.periods || []
    return Array.from(
      new Set([...periodsCountHms, ...periodsCountHmsValues, ...periodsDataStackedBarChart].map((item) => item.key))
    ).sort()
  }, [dataCountPesticides, dataCountPesticidesValues, selectedYear])

  useEffect(() => {
    if (allPeriods.length > 0) {
      setPeriods(allPeriods)
      setSelectedPeriod(allPeriods.includes(period) ? period : allPeriods[allPeriods.length - 1])
    }
  }, [allPeriods])

  useEffect(() => {
    if (selectedYear && selectedPeriod) {
      setCountPesticides(getBarPeriodData(dataCountPesticides ?? [], selectedPeriod, selectedYear))
      setCountPesticidesValues(getBarPeriodData(dataCountPesticidesValues ?? [], selectedPeriod, selectedYear))
      setCountPesticidesProportions(getBarStackedPeriodData(dataStackedBarChart ?? [], selectedPeriod, selectedYear))
    }
  }, [dataCountPesticides, dataCountPesticidesValues, selectedPeriod, selectedYear])
  return (
    <div className="container relative my-8">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon className="size-4" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOMONITORING_SITES)}>
            {t(i18nKeys.beeoimpact.common.breadcrumb.mySites)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOMONITORING_SITE, { params: { siteId: site?.id as number } })}>
            {isLoadingSite ? <Skeleton className="h-4 w-[200px]" /> : site?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            href={getPath(Routes.BEEOMONITORING_SITE_BIODIVERSITY, { params: { siteId: site?.id as number } })}
          >
            {t(i18nKeys.beeomonitoring.common.breadcrumb.pesticides)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="flex flex-col justify-between">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>
              {isLoadingSite ? (
                <div className="flex items-end justify-start">
                  <Skeleton className="mr-2 h-[34px] w-[400px]" />
                  <Skeleton className="h-[24px] w-[80px]" />
                </div>
              ) : (
                site && (
                  <div>
                    {site.name} <span className="text-sm font-medium text-muted-foreground"># {site.key}</span>
                  </div>
                )
              )}
            </PageHeaderHeading>
            <div className="flex flex-row">
              {isLoadingSite ? (
                <>
                  <div>
                    <Skeleton className="h-[22px] w-[62px]" />
                  </div>

                  <div>
                    <Skeleton className="ml-2 h-[22px] w-[110px]" />
                  </div>
                </>
              ) : (
                site?.tags?.map((tag) => (
                  <Badge key={tag} className="mr-4 rounded-none bg-muted text-foreground hover:bg-foreground/10">
                    {tag}
                  </Badge>
                ))
              )}
            </div>
          </PageHeader>
          <div className="flex items-center space-x-2">
            {isLoadingSite || isLoadingCountPesticides || isLoadingCountPesticidesValues ? (
              <>
                <Skeleton className="h-10 w-44" />
                <Skeleton className="h-10 w-44" />
              </>
            ) : (
              <>
                <Select value={selectedYear} onValueChange={(e) => setSelectedYear(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedYear} placeholder={selectedYear} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {years.map((year) => (
                        <SelectItem key={year} value={year}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Select value={selectedPeriod} onValueChange={(e) => setSelectedPeriod(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedPeriod} placeholder={`${selectedPeriod}`} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {periods.map((period, index) => (
                        <SelectItem key={index} value={period}>
                          {period}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        <Card>
          <CardHeader>
            <CardTitle>{t(i18nKeys.beeomonitoring.common.definition)}</CardTitle>
            <CardDescription>{t(i18nKeys.beeomonitoring.pesticides.description)}</CardDescription>
          </CardHeader>
          <CardContent>
            <p className="leading-6">{t(i18nKeys.beeomonitoring.pesticides.definition.content.para1)}</p>
            <p className="mt-4 leading-6">{t(i18nKeys.beeomonitoring.pesticides.definition.content.para2)}</p>
            <p className="mt-4 leading-6">{t(i18nKeys.beeomonitoring.pesticides.definition.content.para3)}</p>
          </CardContent>
        </Card>
        <div className="mt-4 grid grid-cols-2 gap-4">
          {isLoadingCountPesticides ? (
            <CardBarChartSkeleton />
          ) : (
            countPesticides.map((item, index) => (
              <CardBarChart
                key={index}
                data={[item]}
                title={t(i18nKeys.beeomonitoring.pesticides.graph.count_barchart.title)}
                subtitle={t(i18nKeys.beeomonitoring.pesticides.graph.count_barchart.description)}
                colors={COLORS}
                showLegend
                legendY={t(i18nKeys.beeomonitoring.pesticides.graph.count_barchart.legend.y)}
                rounded={1}
              />
            ))
          )}

          {isLoadingStackedBarChart ? (
            Array.from({ length: 3 }).map((_, index) => (
              <div key={index}>
                <CardBarChartSkeleton withDescription={false} />
              </div>
            ))
          ) : (
            <CardBarChartStacked
              title={t(i18nKeys.beeomonitoring.pesticides.graph.count_stacked_barchart.title)}
              subtitle={t(i18nKeys.beeomonitoring.pesticides.graph.count_stacked_barchart.description)}
              legendY={t(i18nKeys.beeomonitoring.pesticides.graph.count_stacked_barchart.legend.y)}
              data={countPesticidesProportions ?? {}}
              colors={COLORS_REVERSED}
            />
          )}
        </div>
        {countPesticidesValues.length !== 0 && (
          <div className="mt-4">
            <Card>
              <CardHeader>
                <CardTitle>{t(i18nKeys.beeomonitoring.pesticides.legend.title)}</CardTitle>
                <CardDescription>{t(i18nKeys.beeomonitoring.pesticides.legend.description)}</CardDescription>
              </CardHeader>
              <CardContent className="text-sm">
                <UL className="mt-0">
                  <LI>{t(i18nKeys.beeomonitoring.pesticides.legend.details.para1)}</LI>
                  <LI>{t(i18nKeys.beeomonitoring.pesticides.legend.details.para2)}</LI>
                  <LI>{t(i18nKeys.beeomonitoring.pesticides.legend.details.para3)}</LI>
                  <LI>{t(i18nKeys.beeomonitoring.pesticides.legend.details.para4)}</LI>
                </UL>
              </CardContent>
            </Card>
          </div>
        )}
        <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {isLoadingCountPesticidesValues
            ? Array.from({ length: 3 }).map((_) => <CardBarChartSkeleton withDescription={false} />)
            : countPesticidesValues.map((item, index) => (
                <CardBarChart
                  key={index}
                  data={[item]}
                  title={item.name}
                  colors={COLORS}
                  showLegend
                  lmrValue={item.lmr}
                  loqValue={item.loq}
                  legendY={t(i18nKeys.beeomonitoring.pesticides.graph.legend.y)}
                  authorized={item.authorized}
                  rounded={3}
                />
              ))}
        </div>
      </div>
    </div>
  )
}

export default PesticidesMonitoringPage
