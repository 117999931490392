import { Navigate, Outlet } from 'react-router-dom'

import { Group } from '@/types/group'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import Loader from '@/components/loader'

type ProtectedRouteProps = {
  groups?: Group[]
}

const ProtectedRoute = ({ groups = [Group.BEEO_INTERNAL] }: ProtectedRouteProps) => {
  const { user } = useAuth()

  if (!user) {
    return <Loader />
  }

  const isAdmin = groups.some((item1) => user.groups.some((item2) => item2.name.toLowerCase() === item1.toLowerCase()))

  if (!isAdmin) {
    return <Navigate to={getPath(Routes.ERROR_404)} replace />
  }

  return <Outlet />
}

export default ProtectedRoute
