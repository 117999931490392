import { useQuery } from '@tanstack/react-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { PacCulture, PacCulturesListParams } from '../../models'

export const pacCulturesList = (params?: PacCulturesListParams, signal?: AbortSignal) => {
  return backendInstance<PacCulture[]>({ url: `/pac-cultures/`, method: 'GET', params, signal })
}

export const getPacCulturesListQueryKey = (params?: PacCulturesListParams) => {
  return [`/pac-cultures/`, ...(params ? [params] : [])] as const
}

export const getPacCulturesListQueryOptions = <
  TData = Awaited<ReturnType<typeof pacCulturesList>>,
  TError = ErrorType<unknown>,
>(
  params?: PacCulturesListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof pacCulturesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPacCulturesListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pacCulturesList>>> = ({ signal }) =>
    pacCulturesList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pacCulturesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PacCulturesListQueryResult = NonNullable<Awaited<ReturnType<typeof pacCulturesList>>>
export type PacCulturesListQueryError = ErrorType<unknown>

export const usePacCulturesList = <TData = Awaited<ReturnType<typeof pacCulturesList>>, TError = ErrorType<unknown>>(
  params?: PacCulturesListParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof pacCulturesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPacCulturesListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const pacCulturesRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<PacCulture>({ url: `/pac-cultures/${id}/`, method: 'GET', signal })
}

export const getPacCulturesRetrieveQueryKey = (id: number) => {
  return [`/pac-cultures/${id}/`] as const
}

export const getPacCulturesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof pacCulturesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof pacCulturesRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getPacCulturesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof pacCulturesRetrieve>>> = ({ signal }) =>
    pacCulturesRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof pacCulturesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type PacCulturesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof pacCulturesRetrieve>>>
export type PacCulturesRetrieveQueryError = ErrorType<unknown>

export const usePacCulturesRetrieve = <
  TData = Awaited<ReturnType<typeof pacCulturesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof pacCulturesRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getPacCulturesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
