import { useCallback, useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'

import { AvailableLanguage } from '@/types/available-language'
import { STORAGE_KEYS } from '@/lib/constants/local-storage'
import { setLanguageHeader } from '@/lib/utils'

export const useLanguage = () => {
  const { i18n } = useTranslation()
  const [language, setLanguage] = useState<AvailableLanguage>(i18n.language as AvailableLanguage)
  const queryClient = useQueryClient()

  const changeLanguage = useCallback(
    (lng: AvailableLanguage) => {
      setLanguageHeader(lng)
      i18n.changeLanguage(lng)
      localStorage.setItem(STORAGE_KEYS.LAST_SELECTED_LANGUAGE, lng)
      //invalidate all queries to refetch data with the new language selected, do we want to do this? or do we specify the queries that we want to invalidate?
      queryClient.invalidateQueries()
    },
    [i18n]
  )

  useEffect(() => {
    setLanguage(i18n.language as AvailableLanguage)
  }, [i18n.language])

  useEffect(() => {
    document.documentElement.setAttribute('lang', language)
  }, [language])

  return { language, changeLanguage }
}
