const Loader = () => {
  return (
    <div className="absolute top-0 flex size-full">
      <div className="m-auto">
        <div className="size-32 animate-spin rounded-full border-y-2 border-gray-900"></div>
      </div>
    </div>
  )
}

export default Loader
