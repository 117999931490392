import { i18nKeys } from '@/locales/keys'
import dataVisualisationLoader from '@/pages/admin/data-visualisation.loader'
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { useTranslation } from 'react-i18next'
import { useLoaderData } from 'react-router'

import { Separator } from '@/components/ui/separator'

const DataVisualisation = () => {
  const { t } = useTranslation()
  const { totalSubstancesByRegion } = useLoaderData() as Awaited<ReturnType<typeof dataVisualisationLoader>>
  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium">{t(i18nKeys.global.settings.page.dataVisualisation.title)}</h3>
        <p className="text-sm text-muted-foreground">
          {t(i18nKeys.global.settings.page.dataVisualisation.description)}
        </p>
      </div>
      <Separator />

      <div>
        <h4 className="text-lg font-medium">Nombre de substances par région</h4>
        <p className="text-sm text-muted-foreground">Nombre de substances par région pour l'année 2020</p>
      </div>
      <div className="h-[36rem]">
        <ResponsiveBar
          data={totalSubstancesByRegion as unknown as BarDatum[]}
          colorBy="indexValue"
          colors={['#93c5fd', '#60a5fa', '#3b82f6', '#2563eb', '#1d4ed8']}
          keys={['total_substances']}
          indexBy="region_acheteur"
          margin={{ top: 10, right: 50, bottom: 190, left: 60 }}
          layout="vertical"
          enableLabel={false}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 90,
          }}
        />
      </div>
    </div>
  )
}

export default DataVisualisation
