import { PlotCulture } from '@/client/backend/models'
import * as turf from '@turf/turf'
import { Polygon, Tooltip } from 'react-leaflet'

interface SiteParcelProps {
  landplot: PlotCulture
  colors: Record<string, string>
}

const SiteLandPlot = ({ landplot, colors }: SiteParcelProps) => {
  const fillColor = colors[landplot.culture]

  const parcelOptions = {
    color: 'white',
    fillColor,
    opacity: 0.5,
    fillOpacity: 0.5,
    weight: 1,
  }

  const coordinates = landplot.intersection?.['coordinates']
  const newGeom = coordinates?.[0].map((coord) => [coord[1], coord[0]])

  return coordinates ? (
    <Polygon key={landplot.id} pathOptions={parcelOptions} positions={newGeom} pane="markerPane">
      <Tooltip sticky>
        <ul>
          <li>{landplot.culture_description}</li>
          <li>{(turf.area(landplot.intersection) / 10000).toFixed(2)} ha</li>
        </ul>
      </Tooltip>
    </Polygon>
  ) : null
}

export default SiteLandPlot
