import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Link, useLocation } from 'react-router-dom'

import { hasGroupPermission } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'
import { buttonVariants } from '@/components/ui/button'
import Loader from '@/components/loader'

const SettingsSidebar = () => {
  const { user } = useAuth()
  const { pathname } = useLocation()
  const { t } = useTranslation()

  if (!user) return <Loader />

  const items = [
    {
      title: t(i18nKeys.global.settings.menu.profile),
      href: getPath(Routes.PROFILE),
      canAccess: true,
    },
    {
      title: t(i18nKeys.global.settings.menu.users),
      href: getPath(Routes.ACCOUNT_USERS),
      canAccess: hasGroupPermission(user),
    },
    {
      title: t(i18nKeys.global.settings.menu.sites),
      href: getPath(Routes.ACCOUNT_SITES),
      canAccess: hasGroupPermission(user),
    },
    { title: t(i18nKeys.global.settings.menu.security), href: getPath(Routes.SECURITY), canAccess: true },
  ]

  return (
    <nav className="flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1">
      {items.map((item) => {
        return (
          item.canAccess && (
            <Link
              key={item.href}
              to={item.href}
              className={cn(
                buttonVariants({ variant: 'ghost' }),
                pathname === item.href ? 'bg-muted hover:bg-muted' : 'hover:bg-transparent hover:underline',
                'justify-start'
              )}
            >
              {item.title}
            </Link>
          )
        )
      })}
    </nav>
  )
}

export default SettingsSidebar
