import {
  beeomonitoringGraphsPahValuesByPeriodList,
  getBeeomonitoringGraphsPahValuesByPeriodListQueryKey,
} from '@/client/backend/api/graphs/graphs'
import {
  beeomonitoringSitesSiteInfosRetrieve,
  getBeeomonitoringSitesSiteInfosRetrieveQueryKey,
} from '@/client/backend/api/sites/sites'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'

import SubstancePageView from '@/components/substance-pageview'

const PahPolluantsMonitoringPage = () => {
  const { t } = useTranslation()
  const { siteId } = useParams<{ siteId: string }>() as { siteId: string }

  const siteData = useQuery({
    queryKey: [getBeeomonitoringSitesSiteInfosRetrieveQueryKey(siteId)],
    queryFn: () => beeomonitoringSitesSiteInfosRetrieve(siteId, undefined),
  })

  const dataValuesPahsByPeriodData = useQuery({
    queryKey: [getBeeomonitoringGraphsPahValuesByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsPahValuesByPeriodList(siteId, undefined),
  })

  return (
    <SubstancePageView
      title={t(i18nKeys.beeomonitoring.pah.graph.title)}
      subtitle={t(i18nKeys.beeomonitoring.pah.graph.description)}
      description={
        <Trans
          i18nKey={i18nKeys.beeomonitoring.pah.definition.content.para_1}
          components={{ p: <p className="mt-4 leading-6" /> }}
        />
      }
      table_title={t(i18nKeys.beeomonitoring.pah.table.title)}
      table_subtitle={t(i18nKeys.beeomonitoring.pah.table.description)}
      siteData={siteData}
      substanceData={dataValuesPahsByPeriodData}
      type="PAH"
    />
  )
}

export default PahPolluantsMonitoringPage
