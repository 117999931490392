import { Dispatch, SetStateAction, useState } from 'react'
import { accountsDestroy } from '@/client/backend/api/accounts/accounts'
import { Account } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { isAxiosError } from 'axios'
import { Pen, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import { toast } from '@/components/ui/use-toast'
import AccountForm from '@/components/account-form'

type AccountsTableRowActionProps = {
  account: Account
  accounts: Account[]
  setAccounts: Dispatch<SetStateAction<Account[]>>
}
const AccountsTableRowAction = ({ account, accounts, setAccounts }: AccountsTableRowActionProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  const deleteAccount = async (accountToDelete: Account) => {
    try {
      if (!accountToDelete.id) return
      await accountsDestroy(accountToDelete.id)
      toast({
        title: t(i18nKeys.global.admin.page.accounts.dialog.toast.success),
      })
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.admin.page.accounts.dialog.toast.error),
          description: error.response.data[0],
        })
      }
    }
    const updatedAccounts = accounts.filter((site) => site.id !== accountToDelete.id)
    setAccounts(updatedAccounts)
  }

  return (
    <div className="flex items-center justify-center">
      <Sheet open={open} onOpenChange={setOpen}>
        <SheetTrigger asChild>
          <Button size="icon" variant="outline" className="ml-auto hidden border-0 lg:flex">
            <Pen className="size-3" />
          </Button>
        </SheetTrigger>
        <SheetContent side="right" className="overflow-y-scroll">
          <SheetHeader>
            <SheetTitle> {t(i18nKeys.global.admin.page.accounts.sheet.edit.title)}</SheetTitle>
            <SheetDescription>{t(i18nKeys.global.admin.page.accounts.sheet.edit.description)}</SheetDescription>
          </SheetHeader>
          <AccountForm account={account} accounts={accounts} setAccounts={setAccounts} setOpen={setOpen} />
        </SheetContent>
      </Sheet>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline" className="ml-auto hidden border-0 lg:flex">
            <Trash2 className="size-3" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>
              {t(i18nKeys.global.admin.page.accounts.dialog.title, { name: account.name })}
            </AlertDialogTitle>
            <AlertDialogDescription>{t(i18nKeys.global.admin.page.accounts.dialog.description)}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t(i18nKeys.global.admin.page.accounts.dialog.button.cancel)}</AlertDialogCancel>

            <Button variant="destructive" onClick={() => deleteAccount(account)}>
              {t(i18nKeys.global.admin.page.accounts.dialog.button.delete)}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default AccountsTableRowAction
