import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'

const PrivateRoute = () => {
  const { isLogged } = useAuth()
  const location = useLocation()

  return !isLogged ? (
    <Navigate
      to={getPath(Routes.AUTH_LOGIN)}
      state={{
        from: location,
      }}
    />
  ) : (
    <Outlet />
  )
}

export default PrivateRoute
