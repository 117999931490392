import { useMutation } from '@tanstack/react-query'
import type { MutationFunction, UseMutationOptions, UseMutationResult } from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { TokenObtainPair, TokenRefresh, TokenVerify } from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

/**
 * Takes a set of user credentials and returns an access and refresh JSON web
token pair to prove the authentication of those credentials.
 */
export const authJwtCreateCreate = (tokenObtainPair: NonReadonly<TokenObtainPair>) => {
  return backendInstance<TokenObtainPair>({
    url: `/auth/jwt/create/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: tokenObtainPair,
  })
}

export const getAuthJwtCreateCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authJwtCreateCreate>>,
    TError,
    { data: NonReadonly<TokenObtainPair> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof authJwtCreateCreate>>,
  TError,
  { data: NonReadonly<TokenObtainPair> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authJwtCreateCreate>>,
    { data: NonReadonly<TokenObtainPair> }
  > = (props) => {
    const { data } = props ?? {}

    return authJwtCreateCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthJwtCreateCreateMutationResult = NonNullable<Awaited<ReturnType<typeof authJwtCreateCreate>>>
export type AuthJwtCreateCreateMutationBody = NonReadonly<TokenObtainPair>
export type AuthJwtCreateCreateMutationError = ErrorType<unknown>

export const useAuthJwtCreateCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authJwtCreateCreate>>,
    TError,
    { data: NonReadonly<TokenObtainPair> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof authJwtCreateCreate>>,
  TError,
  { data: NonReadonly<TokenObtainPair> },
  TContext
> => {
  const mutationOptions = getAuthJwtCreateCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const authJwtRefreshCreate = (tokenRefresh: NonReadonly<TokenRefresh>) => {
  return backendInstance<TokenRefresh>({
    url: `/auth/jwt/refresh/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: tokenRefresh,
  })
}

export const getAuthJwtRefreshCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authJwtRefreshCreate>>,
    TError,
    { data: NonReadonly<TokenRefresh> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof authJwtRefreshCreate>>,
  TError,
  { data: NonReadonly<TokenRefresh> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof authJwtRefreshCreate>>,
    { data: NonReadonly<TokenRefresh> }
  > = (props) => {
    const { data } = props ?? {}

    return authJwtRefreshCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthJwtRefreshCreateMutationResult = NonNullable<Awaited<ReturnType<typeof authJwtRefreshCreate>>>
export type AuthJwtRefreshCreateMutationBody = NonReadonly<TokenRefresh>
export type AuthJwtRefreshCreateMutationError = ErrorType<unknown>

export const useAuthJwtRefreshCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authJwtRefreshCreate>>,
    TError,
    { data: NonReadonly<TokenRefresh> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof authJwtRefreshCreate>>,
  TError,
  { data: NonReadonly<TokenRefresh> },
  TContext
> => {
  const mutationOptions = getAuthJwtRefreshCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
/**
 * Takes a token and indicates if it is valid.  This view provides no
information about a token's fitness for a particular use.
 */
export const authJwtVerifyCreate = (tokenVerify: TokenVerify) => {
  return backendInstance<TokenVerify>({
    url: `/auth/jwt/verify/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: tokenVerify,
  })
}

export const getAuthJwtVerifyCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authJwtVerifyCreate>>,
    TError,
    { data: TokenVerify },
    TContext
  >
}): UseMutationOptions<Awaited<ReturnType<typeof authJwtVerifyCreate>>, TError, { data: TokenVerify }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof authJwtVerifyCreate>>, { data: TokenVerify }> = (
    props
  ) => {
    const { data } = props ?? {}

    return authJwtVerifyCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type AuthJwtVerifyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof authJwtVerifyCreate>>>
export type AuthJwtVerifyCreateMutationBody = TokenVerify
export type AuthJwtVerifyCreateMutationError = ErrorType<unknown>

export const useAuthJwtVerifyCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof authJwtVerifyCreate>>,
    TError,
    { data: TokenVerify },
    TContext
  >
}): UseMutationResult<Awaited<ReturnType<typeof authJwtVerifyCreate>>, TError, { data: TokenVerify }, TContext> => {
  const mutationOptions = getAuthJwtVerifyCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
