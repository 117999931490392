import L from 'leaflet'

import dotImage from '../assets/defaultMapMarker.png'
import pinOn from '../assets/pin-on.png'

export const getMapIcon = (color = 'grey') => {
  const iconUrlMap = {
    newDot: dotImage,
    pin: pinOn,
    green: 'https://beeodiversitystorage.blob.core.windows.net/public/greencircle.png',
    orange: 'https://beeodiversitystorage.blob.core.windows.net/public/orangecircle.png',
    red: 'https://beeodiversitystorage.blob.core.windows.net/public/redcircle.png',
    grey: 'https://beeodiversitystorage.blob.core.windows.net/public/greycircle.png',
  }
  const iconUrl = iconUrlMap[color] || iconUrlMap['grey']

  return L.icon({
    iconUrl,
    iconSize: [14, 14],
    iconAnchor: [8, 8],
    popupAnchor: [-2, -6],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    shadowSize: [15, 15],
  })
}

export const hoverSiteIcon = (color = 'grey') => {
  const iconUrlMap = {
    pin: pinOn,
  }
  const iconUrl = iconUrlMap[color] || iconUrlMap['grey']

  return L.icon({
    iconUrl,
    iconSize: [24, 30],
    iconAnchor: [8, 8],
    popupAnchor: [-2, -6],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
    shadowSize: [15, 15],
  })
}
