import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { AvailableCountry } from '@/types/available-country'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

type CountrySelectorProps = {
  country: AvailableCountry
  setCountry: React.Dispatch<React.SetStateAction<AvailableCountry>>
  keyt: string
  countries: AvailableCountry[]
}

const CountrySelector = ({ country, setCountry, keyt, countries }: CountrySelectorProps) => {
  const { t } = useTranslation()

  const changeCountry = (country: AvailableCountry) => {
    setCountry(country)
    if (keyt) {
      localStorage.setItem(keyt, country)
    }
  }

  return (
    <Select value={country} onValueChange={(e: AvailableCountry) => changeCountry(e)}>
      <SelectTrigger className="border-none">
        <SelectValue aria-label={t(i18nKeys.global.common.countries[AvailableCountry.FR])}>
          <div className="flex items-center justify-center">{t(i18nKeys.global.common.countries[country])}</div>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {countries.map((country, index) => {
          return (
            <SelectItem key={index} value={country}>
              <div className=" flex items-center justify-center">{t(i18nKeys.global.common.countries[country])}</div>
            </SelectItem>
          )
        })}
      </SelectContent>
    </Select>
  )
}

export default CountrySelector
