import { Dispatch, SetStateAction, useMemo } from 'react'
import { CultureMapping, PhytoCulture } from '@/client/backend/models'
import { ColumnDef } from '@tanstack/react-table'
import { Check } from 'lucide-react'

import { Checkbox } from '@/components/ui/checkbox'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

type CulturePhytoTableProps = {
  data: PhytoCulture[]
  setSelectedPhyto: Dispatch<SetStateAction<PhytoCulture[]>>
  selectedPhyto: PhytoCulture[]
  mappings: CultureMapping[]
}
const CulturePhytoTable = ({ data, setSelectedPhyto, selectedPhyto, mappings }: CulturePhytoTableProps) => {
  const columns = useMemo<ColumnDef<PhytoCulture>[]>(() => {
    return [
      {
        id: 'select',
        header: ({ table }) => (
          <Checkbox
            checked={table.getIsAllPageRowsSelected()}
            onCheckedChange={(value) => {
              table.toggleAllPageRowsSelected(!!value)
              // Set selectedPac to all or none based on the toggle
              const updatedSelectedPhyto = value ? table.getRowModel().rows.map((row) => row.original) : []
              setSelectedPhyto(updatedSelectedPhyto)
            }}
            aria-label="Select all"
            className="translate-y-[2px]"
          />
        ),
        cell: ({ row }) => (
          <Checkbox
            checked={row.getIsSelected()}
            onCheckedChange={(value) => {
              const isSelected = !!value
              const updatedSelectedPhyto = isSelected
                ? [...selectedPhyto, row.original]
                : selectedPhyto.filter((item) => item.id !== row.original.id) // Compare by ID instead of object reference

              setSelectedPhyto(updatedSelectedPhyto)
              row.toggleSelected(isSelected)
            }}
            aria-label="Select row"
            className="translate-y-[2px]"
          />
        ),
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: 'id',
        header: ({ column }) => <DataTableColumnHeader column={column} children="" />,
        cell: ({ row }) => <div className="w-[80px]">{row.getValue('id')}</div>,
        enableSorting: false,
        enableHiding: false,
      },
      {
        accessorKey: 'name',
        id: 'name',
        header: ({ column }) => <DataTableColumnHeader column={column} children="name" className="ml-4" />,
        cell: ({ row }) => (
          <div className="flex space-x-2">
            <span className="min-w-[24px] max-w-[500px] truncate font-medium">{row.getValue('name')}</span>
          </div>
        ),
      },
      {
        id: 'done',
        cell: ({ row }) => {
          const isInMappings = mappings.some((mapping) => mapping.phyto_culture.id === row.original.id)
          return (
            <div className="flex space-x-2">
              <span className="max-w-[500px] truncate font-medium">
                {isInMappings ? <Check className="size-4 text-green-500" /> : null}
              </span>
            </div>
          )
        },
      },
    ]
  }, [selectedPhyto, setSelectedPhyto, mappings])

  return <DataTable columns={columns} data={data} filterIdColumn="name" className="min-h-[400px]" />
}

export default CulturePhytoTable
