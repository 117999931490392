import React from 'react'
import { ListValues } from '@/client/backend/models'

import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

type CardLongListProps = {
  item: ListValues
  title: string
  description: string
}

const CardLongList = ({ item, title, description }: CardLongListProps) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {item.values.sort().map((value, index) => {
            return (
              <React.Fragment key={index}>
                <li>
                  {value.latin_name}{' '}
                  <p className="py-0 text-xs italic text-muted-foreground">{value.vernacular_name}</p>
                </li>
              </React.Fragment>
            )
          })}
        </ul>
      </CardContent>
    </Card>
  )
}

export default CardLongList
