import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Card } from '@/components/ui/card'

type CardMarketingProps = {
  title: string
}

const CardMarketing = ({ title }: CardMarketingProps) => {
  const { t } = useTranslation()
  return (
    <Card className="relative w-full max-w-md overflow-hidden rounded-lg">
      <div className="absolute inset-0 bg-biodivTealSevenHundred" />
      <div className="relative  flex size-full flex-col items-center justify-center gap-4 p-6 text-center text-white">
        <h3 className="text-sm">{title}</h3>
        <a href="#" className="text-white underline underline-offset-2 hover:font-bold">
          {t(i18nKeys.beeomonitoring.common.contact)}
        </a>
      </div>
    </Card>
  )
}

export default CardMarketing
