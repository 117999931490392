export const dateUpdateFormat = (dateInput: string | Date | null): string => {
  // If the input is null, use today's date
  if (dateInput === null) {
    dateInput = new Date()
  }

  // Convert the input to a Date object if it's a string
  const date = typeof dateInput === 'string' ? new Date(dateInput) : dateInput

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    return '' // Return an empty string or any other default value for invalid dates
  }

  // Format the date as DD.MM.YYYY
  const day = String(date.getDate()).padStart(2, '0')
  const month = String(date.getMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getFullYear()

  return `${day}.${month}.${year}`
}
