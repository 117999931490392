/**
 * * `biodiversity` - Biodiversity
 * `pesticides` - Pesticides
 * `initiative` - Initiative
 * `potential` - Potential
 * `monitoring` - Monitoring
 */
export type FeaturesEnum = (typeof FeaturesEnum)[keyof typeof FeaturesEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const FeaturesEnum = {
  biodiversity: 'biodiversity',
  pesticides: 'pesticides',
  initiative: 'initiative',
  potential: 'potential',
  monitoring: 'monitoring',
} as const
