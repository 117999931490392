import { i18nKeys } from '@/locales/keys'
import { MapPin } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'

import { hasGroupPermission } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'

import { H4 } from './typography'

const NoSitesMessage = ({ className }: { className?: string }) => {
  const { t } = useTranslation()
  const { user } = useAuth()
  return (
    <div className={cn('my-20 flex w-full flex-col justify-center text-center', className)}>
      <MapPin className="mb-2 flex self-center text-muted-foreground" size={30} />
      <H4 className="font-bold">{t(i18nKeys.global.common.errors.no_sites.message)}</H4>
      <p>
        {hasGroupPermission(user) ? (
          <Trans
            i18nKey={i18nKeys.global.common.errors.no_sites.cta_with_permission}
            components={{
              adminLink: <NavLink to={getPath(Routes.ACCOUNT_SITES)} className="underline underline-offset-2" />,
            }}
          />
        ) : (
          t(i18nKeys.global.common.errors.no_sites.cta_without_permission)
        )}
      </p>
    </div>
  )
}

export default NoSitesMessage
