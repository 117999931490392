import { Dispatch, SetStateAction, useMemo } from 'react'
import { LocationSensitiveAreaEnum, SensitiveAreaOut, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { Badge } from '@/components/ui/badge'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { DataTablePagination } from '@/components/data-table/data-table-pagination'
import RenderTooltip from '@/components/render-tooltip/render-tooltip'

type MixedSensitiveArea = SensitiveAreaOut & { type: 'N2000' | 'CDDA'; radius: number | undefined }

type SensitiveAreasTableProps = {
  designatedAreas: SensitiveAreaOut[]
  protectedAreas: SensitiveAreaOut[]
  selectedPolygonId: number
  clickRow: number
  setClickRow: Dispatch<SetStateAction<number>>
  site: Site
}
const SensitiveAreasTable = ({
  designatedAreas,
  protectedAreas,
  site,
  selectedPolygonId,
  clickRow,
  setClickRow,
}: SensitiveAreasTableProps) => {
  const { t } = useTranslation()

  const protectedAndDesignatedAreasType =
    protectedAreas !== null && designatedAreas !== null
      ? ([
          ...protectedAreas.map((p) => ({
            ...p,
            type: 'N2000',
            radius: site.properties?.biodiversity_radius,
          })),
          ...designatedAreas.map((d) => ({
            ...d,
            type: 'CDDA',
            radius: site.properties?.biodiversity_radius,
          })),
        ] as MixedSensitiveArea[])
      : []
  const locationsColors = {
    [LocationSensitiveAreaEnum.ADJACENT]: 'w-max bg-green-300',
    [LocationSensitiveAreaEnum.IN]: 'w-max bg-blue-300',
    [LocationSensitiveAreaEnum.OUT]: 'w-max bg-gray-300',
  }

  const columns: ColumnDef<MixedSensitiveArea>[] = [
    {
      id: 'type',
      header: t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.type),
      cell: ({ row }) => (
        <Badge
          className={
            row.original.type === 'CDDA'
              ? 'flex h-6 w-12 justify-center bg-indigo-600 text-center text-xs text-white'
              : 'flex h-6 w-12 justify-center bg-orange-400 text-center text-xs text-white'
          }
        >
          {row.original.type === 'N2000' ? 'N2000' : 'CDDA'}
        </Badge>
      ),
    },
    {
      id: 'radius',
      header: t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.radius),
      cell: ({ row }) => row.original.radius,
    },
    {
      id: 'name',
      header: t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.name),
      cell: ({ row }) => row.original.name,
    },
    {
      id: 'location',
      header: t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.position),
      cell: ({ row }) => (
        <Badge className={locationsColors[row.original.location]}>
          {t(i18nKeys.beeoimpact.biodiversityTable.location[row.original.location])}
        </Badge>
      ),
    },
    {
      id: 'surface_ha',
      header: () => (
        <div className="flex items-center gap-x-2">
          {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surface)}{' '}
          <RenderTooltip
            size={20}
            explanationText={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.surfaceDefinition)}
          />
        </div>
      ),
      cell: ({ row }) => <div className=" w-20 text-end">{row.original.surface_ha?.toFixed(2)}</div>,
    },
    {
      id: 'distance_m',
      header: () => (
        <div className="flex items-center gap-x-2">
          {t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.distance)}{' '}
          <RenderTooltip
            size={20}
            explanationText={t(i18nKeys.beeoimpact.biodiversityTable.biodiversityReserves.table.distanceDefinition)}
          />
        </div>
      ),
      cell: ({ row }) => {
        const distance = row.original.distance_m as number
        return <div className="w-20 text-end">{distance !== 0 ? distance.toFixed(2) : ''}</div>
      },
    },
  ]

  const protectedAndDesignatedAreas = useMemo(() => protectedAndDesignatedAreasType, [])

  const table = useReactTable({
    data: protectedAndDesignatedAreas,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  })

  const clickRowHighlightMap = (clickRow: number) => {
    setClickRow((prevClickRow) => (prevClickRow === clickRow ? 0 : clickRow))
  }

  return (
    <>
      <div className="mt-8 flex flex-col justify-between border">
        <div className="w-full">
          <Table className="border">
            <TableHeader className="border">
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id}>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    )
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    onClick={() => clickRowHighlightMap(row.original.id)}
                    key={row.id}
                    data-state={row.getIsSelected() && 'selected'}
                    className={`${
                      row.original.type === 'CDDA' && selectedPolygonId === row.original.id ? 'bg-sky-100' : ''
                    } ${
                      row.original.type === 'N2000' && selectedPolygonId === row.original.id ? 'bg-orange-100' : ''
                    } ${row.original.type === 'CDDA' && clickRow === row.original.id ? 'bg-sky-100' : ''} ${
                      row.original.type === 'N2000' && clickRow === row.original.id ? 'bg-orange-100' : ''
                    } 
                     cursor-pointer
                `}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    {t(i18nKeys.global.common.datatable.emptyResults)}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <div className="mt-4 p-4">
          <DataTablePagination table={table} />
        </div>
      </div>
    </>
  )
}

export default SensitiveAreasTable
