import { PaginatedSiteList } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Bar, BarChart, CartesianGrid, ResponsiveContainer, XAxis, YAxis } from 'recharts'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { ChartContainer, ChartTooltip } from '@/components/ui/chart'
import { Separator } from '@/components/ui/separator'
import { Icons } from '@/components/icons'

interface MostActiveSitesProps {
  actionsTakenInitiatives?: PaginatedSiteList
}

const MostActiveSites = ({ actionsTakenInitiatives }: MostActiveSitesProps) => {
  const { t } = useTranslation()

  const actionsTakenInitiativesFiltered = actionsTakenInitiatives?.results?.features ?? []

  const actionsWithSiteNames = actionsTakenInitiativesFiltered.map((it) => ({
    ...it,
    actions_taken:
      it?.properties?.actions_taken?.map((action) => ({
        ...action,
        name: it?.properties?.name,
      })) ?? [],
  }))

  const numberOfDoneInitiativesPerSite = actionsWithSiteNames
    .map((site) => ({
      id: site.id,
      siteName: site?.properties?.name,
      done: site.actions_taken.filter((action) => action.status === 'DONE').length,
    }))
    .slice(0, 5)
    .sort((a, b) => b.done - a.done)

  const chartConfig = {
    percentage: {
      label: t(i18nKeys.beeoinitiative.dashboard.takenInits.title),
    },
  }

  const ArrowColumn = ({ data }) => {
    return (
      <div className="flex flex-col justify-evenly pb-10">
        {data.map((site, i) => (
          <Link to={getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } })} key={i} className="pl-14 ">
            <Icons.MoveRight className="cursor-pointer " />
          </Link>
        ))}
      </div>
    )
  }
  const CustomYAxisLabel = ({ data }) => {
    return (
      <g>
        <foreignObject className="size-full">
          <div className="flex h-full flex-col items-start sm:pb-8 sm:pt-2 md:pb-8 md:pt-2 lg:pb-6 lg:pt-4 xl:pb-8  xl:pt-3 2xl:pb-6 2xl:pt-4">
            {data.map((site, i) => (
              <Link
                to={getPath(Routes.BEEOINITIATIVE_SITE, { params: { siteId: site.id } })}
                key={i}
                className="
                w-24 grow truncate hover:underline
              "
                title={site.siteName}
              >
                {site.siteName}
              </Link>
            ))}
          </div>
        </foreignObject>
      </g>
    )
  }
  const CustomTooltip = ({ active, payload }: { active?; payload? }) => {
    if (active && payload && payload.length) {
      const { done } = payload[0].payload
      const color = 'hsl(var(--biodiv-limeFiveHundred))'

      return (
        <div className="rounded-md bg-muted p-2 shadow-md">
          <div className="flex items-center justify-evenly">
            <div className="mr-2 size-2 rounded-full" style={{ backgroundColor: color }} />
            <span>{chartConfig.percentage.label.toUpperCase()}</span>
            <span className="ml-5 text-muted-foreground">{done.toFixed()}</span>
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <div>
      <div className="flex flex-wrap ">
        <div className="min-w-0 flex-1 pb-4">
          <ChartContainer config={chartConfig} className="max-h-64 w-full text-sm">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart barSize={12} accessibilityLayer data={numberOfDoneInitiativesPerSite} layout="vertical">
                <CartesianGrid strokeDasharray="3 3" vertical={true} horizontal={false} />
                <XAxis
                  type="number"
                  dataKey="done"
                  tickLine={false}
                  axisLine={false}
                  ticks={Array.from(
                    { length: Math.ceil(Math.max(...numberOfDoneInitiativesPerSite.map((item) => item.done)) + 1) },
                    (_, i) => i
                  )}
                />
                <YAxis
                  dataKey="siteName"
                  type="category"
                  tickLine={false}
                  tickMargin={1}
                  tickFormatter={(value) => value.slice(0, 15)}
                  axisLine={{ stroke: 'hsl(var(--separator-gray))', strokeWidth: 0.5 }}
                  width={100}
                  tick={<CustomYAxisLabel data={numberOfDoneInitiativesPerSite} />}
                />
                <ChartTooltip cursor={false} content={<CustomTooltip />} />
                <Bar dataKey="done" className="w-full fill-biodivLimeFiveHundred" radius={[0, 20, 20, 0]} />
              </BarChart>
            </ResponsiveContainer>
          </ChartContainer>
        </div>
        <ArrowColumn data={numberOfDoneInitiativesPerSite} />
      </div>
      <Separator />
    </div>
  )
}

export default MostActiveSites
