import { NavLink } from 'react-router-dom'

import { Button } from '@/components/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { Separator } from '@/components/ui/separator'
import { Icons } from '@/components/icons'

export interface NavItemProps {
  to: string
  icon?: React.ReactNode
  label: string
}

interface SidebarProps {
  isOpen: boolean
  onOpenChange: (isOpen: boolean) => void
  icon: React.ReactNode
  title: string
  links: NavItemProps[]
}

const NavItem = ({ to, icon, label }: NavItemProps) => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive
          ? 'flex flex-row gap-x-5 bg-biodivTealSevenHundred h-[34px] py-1.5 items-center pt-1 sticky z-50 pb-[5px] rounded-r-md w-[186px] pl-8 text-white -ml-5'
          : 'flex flex-row items-center gap-x-5 pl-3 pb-[11px] py-1.5 hover:bg-biodivStoneHundred-hover hover:-ml-5 hover:pl-8 hover:w-[186px] hover:rounded-r-md h-[34px]'
      }
      to={to}
    >
      <div className="size-4 pl-1">{icon}</div>
      <p className="pl-1 "> {label}</p>
    </NavLink>
  )
}

const Sidebar = ({ isOpen, onOpenChange, icon, title, links }: SidebarProps) => {
  return (
    <Collapsible open={isOpen} onOpenChange={onOpenChange} className="">
      <CollapsibleTrigger asChild>
        <div className="flex cursor-pointer items-start justify-between px-[36px] pb-[12px]  pt-[31px]">
          <div className="flex flex-row items-end space-x-4 ">
            {icon}
            <h4 className="select-none text-sm font-semibold ">{title}</h4>
          </div>
          <Button variant="link" size="sm">
            {isOpen ? <Icons.ChevronUp className="w-4" /> : <Icons.ChevronDown className="w-4" />}
            <span className="sr-only">Toggle</span>
          </Button>
        </div>
      </CollapsibleTrigger>
      <CollapsibleContent className="flex flex-col  px-5 pb-[20px] ">
        {links.map((link, index) => (
          <NavItem key={index} to={link.to} icon={link.icon} label={link.label} />
        ))}
      </CollapsibleContent>
      <Separator className="w-[200px] bg-separatorGray " />
    </Collapsible>
  )
}

export default Sidebar
