import { Audit, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'

import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'
import { Icons } from '@/components/icons'

import AuditsTableRowActions from './audits-table-row-actions'

const DataTableColumnHeaderStyled = (props) => <DataTableColumnHeader {...props} className="-mr-4" />

const HeaderTooltip = ({ children, tooltipContent }) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>{children}</TooltipTrigger>
        <TooltipContent>
          <p>{tooltipContent}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

type AuditsTableProps = {
  site: Site
  siteAudits: Audit[]
  onAuditSave: (newAudit: Audit) => void
  onAuditDelete: (deletedAudit: Audit) => void
  isLoading: boolean
}

const AuditsTable = ({ site, siteAudits, onAuditSave, onAuditDelete, isLoading }: AuditsTableProps) => {
  const { t } = useTranslation()
  const columns: ColumnDef<Audit>[] = [
    {
      accessorKey: 'date',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column} children={t(i18nKeys.beeoaudit.siteDetails.auditTable.date)} />
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.date}</span>
      },
    },
    {
      accessorKey: 'vegetal_cover_percentage',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column}>
          <div className="w-[100px] text-wrap">{t(i18nKeys.beeoaudit.siteDetails.auditTable.pcVgCover)}</div>
        </DataTableColumnHeaderStyled>
      ),
      cell: ({ row }) => {
        return (
          <div className="max-w-[500px] truncate text-center font-medium">{row.original.vegetal_cover_percentage}%</div>
        )
      },
    },
    {
      accessorKey: 'surface_biotope_coefficient',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column}>
          <div className="w-[120px] text-wrap">{t(i18nKeys.beeoaudit.siteDetails.auditTable.surfaceCoefBiotop)}</div>
        </DataTableColumnHeaderStyled>
      ),
      cell: ({ row }) => {
        return (
          <div className="max-w-[500px] truncate text-wrap text-center font-medium">
            {row.original.surface_biotope_coefficient}
          </div>
        )
      },
    },
    {
      accessorKey: 'indigenous_vegetal_percentage',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column}>
          <div className="w-[120px] text-wrap">{t(i18nKeys.beeoaudit.siteDetails.auditTable.pcVgIndigen)}</div>
        </DataTableColumnHeaderStyled>
      ),
      cell: ({ row }) => {
        return (
          <div className="max-w-[500px] truncate text-center font-medium">
            {row.original.indigenous_vegetal_percentage}%
          </div>
        )
      },
    },
    {
      accessorKey: 'amount_vegetal_species',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column}>
          <HeaderTooltip tooltipContent={t(i18nKeys.beeoaudit.siteDetails.auditTable.amountVg)}>
            <Icons.Sprout className="size-4" />
          </HeaderTooltip>
        </DataTableColumnHeaderStyled>
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.amount_vegetal_species}</span>
      },
    },
    {
      accessorKey: 'amount_trees',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column}>
          <HeaderTooltip tooltipContent={t(i18nKeys.beeoaudit.siteDetails.auditTable.amountTrees)}>
            <Icons.Trees className="size-4" />
          </HeaderTooltip>
        </DataTableColumnHeaderStyled>
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.amount_trees}</span>
      },
    },
    {
      accessorKey: 'amount_birds',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column}>
          <HeaderTooltip tooltipContent={t(i18nKeys.beeoaudit.siteDetails.auditTable.amountBirds)}>
            <Icons.Bird className="size-4" />
          </HeaderTooltip>
        </DataTableColumnHeaderStyled>
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.amount_birds}</span>
      },
    },
    {
      accessorKey: 'amount_mammals',
      header: ({ column }) => (
        <DataTableColumnHeader column={column}>
          <HeaderTooltip tooltipContent={t(i18nKeys.beeoaudit.siteDetails.auditTable.amountMammals)}>
            <Icons.Rabbit className="size-4" />
          </HeaderTooltip>
        </DataTableColumnHeader>
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.amount_mammals}</span>
      },
    },
    {
      accessorKey: 'amount_insects',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column}>
          <HeaderTooltip tooltipContent={t(i18nKeys.beeoaudit.siteDetails.auditTable.amountInsects)}>
            <Icons.Bug className="size-4" />
          </HeaderTooltip>
        </DataTableColumnHeaderStyled>
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.amount_insects}</span>
      },
    },
    {
      accessorKey: 'amount_reptiles',
      header: ({ column }) => (
        <DataTableColumnHeaderStyled column={column}>
          <HeaderTooltip tooltipContent={t(i18nKeys.beeoaudit.siteDetails.auditTable.amountReptiles)}>
            <Icons.Turtle className="size-4" />
          </HeaderTooltip>
        </DataTableColumnHeaderStyled>
      ),
      cell: ({ row }) => {
        return <span className="max-w-[500px] truncate font-medium">{row.original.amount_reptiles}</span>
      },
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return (
          <AuditsTableRowActions
            site={site}
            onAuditDelete={onAuditDelete}
            onAuditSave={onAuditSave}
            audit={row.original}
          />
        )
      },
    },
  ]
  return (
    <DataTable
      displayActions={false}
      columns={columns}
      data={siteAudits}
      isLoading={isLoading}
      filterIdColumn="date"
      className="mt-4"
    />
  )
}

export default AuditsTable
