import { Route } from '@/lib/routes/types'

export enum Routes {
  SERVICE_UNAVAILABLE = 'SERVICE_UNAVAILABLE',
  AUTH = 'AUTH',
  AUTH_LOGIN = 'AUTH_LOGIN',
  AUTH_FORGOT_PASSWORD = 'AUTH_FORGOT_PASSWORD',
  AUTH_RESET_PASSWORD = 'AUTH_RESET_PASSWORD',
  AUTH_ACTIVATE_USER = 'AUTH_ACTIVATE_USER',
  LANDING_PAGE = 'LANDING_PAGE',
  BEEOIMPACT = 'BEEOIMPACT',
  BEEOIMPACT_DASHBOARD = 'BEEOIMPACT_DASHBOARD',
  BEEOIMPACT_SITES = 'BEEOIMPACT_SITES',
  BEEOIMPACT_SITE = 'BEEOIMPACT_SITE',
  BEEOIMPACT_SITE_IMPACTS = 'BEEOIMPACT_SITE_IMPACTS',
  BEEOIMPACT_SITE_PESTICIDES = 'BEEOIMPACT_SITE_PESTICIDES',
  BEEOIMPACT_SITE_GENERAL_ANALYSIS = 'BEEOIMPACT_SITE_GENERAL_ANALYSIS',
  BEEOINITIATIVE = 'BEEOINITIATIVE',
  BEEOINITIATIVE_DASHBOARD = 'BEEOINITIATIVE_DASHBOARD',
  BEEOINITIATIVE_SITES = 'BEEOINITIATIVE_SITES',
  BEEOINITIATIVE_SITE = 'BEEOINITIATIVE_SITE',
  BEEOINITIATIVE_INITIATIVES = 'BEEOINITIATIVE_INITIATIVES',
  BEEOINITIATIVE_INITIATIVES_BY_SITE = 'BEEOINITIATIVE_INITIATIVES_BY_SITE',
  BEEOINITIATIVE_INITIATIVE_DETAIL = 'BEEOINITIATIVE_INITIATIVE_DETAIL',
  BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE = 'BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE',
  BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE_BY_ACTIONTAKEN = 'BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE_BY_ACTIONTAKEN',
  BEEOINITIATIVE_INITIATIVE_DETAIL_BY_INITIATIVE = 'BEEOINITIATIVE_INITIATIVE_DETAIL_BY_INITIATIVE',
  ADMIN = 'ADMIN',
  ADMIN_ACCOUNTS = 'ADMIN_ACCOUNTS',
  ADMIN_ACCOUNTS_USERS = 'ADMIN_ACCOUNTS_USERS',
  ADMIN_ACCOUNTS_SITES = 'ADMIN_ACCOUNTS_SITES',
  ADMIN_DATA_VISUALISATION = 'ADMIN_DATA_VISUALISATION',
  ADMIN_MAPPING = 'ADMIN_MAPPING',
  SETTINGS = 'SETTINGS',
  ERRORS = 'ERRORS',
  ERROR_403 = 'ERROR_403',
  ERROR_404 = 'ERROR_404',
  ERROR_503 = 'ERROR_503',
  PROFILE = 'PROFILE',
  ACCOUNT_USERS = 'ACCOUNT_USERS',
  ACCOUNT_SITES = 'ACCOUNT_SITES',
  SECURITY = 'SECURITY',
  BEEOIMPACT_METHODOLOGY = 'BEEOIMPACT_METHODOLOGY',
  BEEOMONITORING = 'BEEOMONITORING',
  BEEOMONITORING_DASHBOARD = 'BEEOMONITORING_DASHBOARD',
  BEEOMONITORING_SITES = 'BEEOMONITORING_SITES',
  BEEOMONITORING_SITE = 'BEEOMONITORING_SITE',
  BEEOMONITORING_SITE_METALS = 'BEEOMONITORING_SITE_METALS',
  BEEOMONITORING_SITE_PESTICIDES = 'BEEOMONITORING_SITE_PESTICIDES',
  BEEOMONITORING_SITE_BIODIVERSITY = 'BEEOMONITORING_SITE_BIODIVERSITY',
  BEEOAUDIT = 'BEEOAUDIT',
  BEEOAUDIT_SITES = 'BEEOAUDIT_SITES',
  BEEOAUDIT_SITE = 'BEEOAUDIT_SITE',
  BEEOMONITORING_SITE_PAH_POLLUANTS = 'BEEOMONITORING_SITE_PAH_POLLUANTS',
  BEEOMONITORING_SITE_PCB_POLLUANTS = 'BEEOMONITORING_SITE_PCB_POLLUANTS',
  BEEOMONITORING_SITE_DIOXINES_POLLUANTS = 'BEEOMONITORING_SITE_DIOXINES_POLLUANTS',
}

export const routeConfig: { [key: string]: Route } = {
  [Routes.SERVICE_UNAVAILABLE]: {
    title: Routes.SERVICE_UNAVAILABLE,
    path: '/service-unavailable',
  },
  [Routes.LANDING_PAGE]: {
    title: Routes.LANDING_PAGE,
    path: '/',
  },
  [Routes.AUTH]: {
    title: Routes.AUTH,
    path: '/auth',
  },
  [Routes.AUTH_LOGIN]: {
    title: Routes.AUTH_LOGIN,
    path: '/login',
    parent: Routes.AUTH,
  },
  [Routes.AUTH_RESET_PASSWORD]: {
    title: Routes.AUTH_RESET_PASSWORD,
    path: '/reset-password/:id/:token',
    parent: Routes.AUTH,
  },
  [Routes.AUTH_FORGOT_PASSWORD]: {
    title: Routes.AUTH_FORGOT_PASSWORD,
    path: '/forgot-password',
    parent: Routes.AUTH,
  },
  [Routes.AUTH_ACTIVATE_USER]: {
    title: Routes.AUTH_ACTIVATE_USER,
    path: '/activate/:id/:token',
    parent: Routes.AUTH,
  },
  [Routes.BEEOIMPACT]: {
    title: Routes.BEEOIMPACT,
    path: '/beeo-impact',
  },
  [Routes.BEEOIMPACT_DASHBOARD]: {
    title: Routes.BEEOIMPACT_DASHBOARD,
    path: '/dashboard',
    parent: Routes.BEEOIMPACT,
  },
  [Routes.BEEOIMPACT_SITES]: {
    title: Routes.BEEOIMPACT_SITES,
    path: '/sites',
    parent: Routes.BEEOIMPACT,
  },
  [Routes.BEEOIMPACT_SITE]: {
    title: Routes.BEEOIMPACT_SITE,
    path: '/:siteId',
    parent: Routes.BEEOIMPACT_SITES,
  },
  [Routes.BEEOIMPACT_SITE_IMPACTS]: {
    title: Routes.BEEOIMPACT_SITE_IMPACTS,
    path: '/impacts',
    parent: Routes.BEEOIMPACT_SITE,
  },
  [Routes.BEEOIMPACT_SITE_PESTICIDES]: {
    title: Routes.BEEOIMPACT_SITE_PESTICIDES,
    path: '/pesticides',
    parent: Routes.BEEOIMPACT_SITE,
  },
  [Routes.BEEOIMPACT_SITE_GENERAL_ANALYSIS]: {
    title: Routes.BEEOIMPACT_SITE_GENERAL_ANALYSIS,
    path: '/general-analysis',
    parent: Routes.BEEOIMPACT_SITE,
  },
  [Routes.BEEOINITIATIVE]: {
    title: Routes.BEEOINITIATIVE,
    path: '/beeo-initiative',
  },
  [Routes.BEEOINITIATIVE_DASHBOARD]: {
    title: Routes.BEEOINITIATIVE_DASHBOARD,
    path: '/dashboard',
    parent: Routes.BEEOINITIATIVE,
  },
  [Routes.ADMIN]: {
    title: Routes.ADMIN,
    path: '/admin',
  },
  [Routes.ADMIN_ACCOUNTS]: {
    title: Routes.ADMIN_ACCOUNTS,
    path: '/accounts',
    parent: Routes.ADMIN,
  },
  [Routes.ADMIN_ACCOUNTS_USERS]: {
    title: Routes.ADMIN_ACCOUNTS_USERS,
    path: '/:accountId/users',
    parent: Routes.ADMIN_ACCOUNTS,
  },
  [Routes.ADMIN_ACCOUNTS_SITES]: {
    title: Routes.ADMIN_ACCOUNTS_SITES,
    path: '/:accountId/sites',
    parent: Routes.ADMIN_ACCOUNTS,
  },
  [Routes.ADMIN_MAPPING]: {
    title: Routes.ADMIN_MAPPING,
    path: '/mapping',
    parent: Routes.ADMIN,
  },
  [Routes.ADMIN_DATA_VISUALISATION]: {
    title: Routes.ADMIN_DATA_VISUALISATION,
    path: '/data-visualisation',
    parent: Routes.ADMIN,
  },
  [Routes.BEEOINITIATIVE_SITES]: {
    title: Routes.BEEOINITIATIVE_SITES,
    path: '/sites',
    parent: Routes.BEEOINITIATIVE,
  },
  [Routes.BEEOINITIATIVE_SITE]: {
    title: Routes.BEEOINITIATIVE_SITE,
    path: '/:siteId',
    parent: Routes.BEEOINITIATIVE_SITES,
  },
  [Routes.BEEOINITIATIVE_INITIATIVES]: {
    title: Routes.BEEOINITIATIVE_INITIATIVES,
    path: '/initiatives',
    parent: Routes.BEEOINITIATIVE,
  },
  [Routes.BEEOINITIATIVE_INITIATIVE_DETAIL]: {
    title: Routes.BEEOINITIATIVE_INITIATIVE_DETAIL,
    path: '/initiative-details',
    parent: Routes.BEEOINITIATIVE,
  },
  [Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE]: {
    title: Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE,
    path: '/:siteId/:initiativeId',
    parent: Routes.BEEOINITIATIVE_INITIATIVE_DETAIL,
  },
  [Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE_BY_ACTIONTAKEN]: {
    title: Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_SITE_BY_INITIATIVE_BY_ACTIONTAKEN,
    path: '/:initiativeId/:actionTakenId',
    parent: Routes.BEEOINITIATIVE_SITE,
  },
  [Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_INITIATIVE]: {
    title: Routes.BEEOINITIATIVE_INITIATIVE_DETAIL_BY_INITIATIVE,
    path: '/:initiativeId',
    parent: Routes.BEEOINITIATIVE_INITIATIVE_DETAIL,
  },
  [Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE]: {
    title: Routes.BEEOINITIATIVE_INITIATIVES_BY_SITE,
    path: '/:siteId',
    parent: Routes.BEEOINITIATIVE_INITIATIVES,
  },
  [Routes.SETTINGS]: {
    title: Routes.SETTINGS,
    path: '/settings',
  },
  [Routes.ERRORS]: {
    title: Routes.ERRORS,
    path: '/errors',
  },
  [Routes.ERROR_403]: {
    title: Routes.ERROR_403,
    path: '/403',
    parent: Routes.ERRORS,
  },
  [Routes.ERROR_404]: {
    title: Routes.ERROR_404,
    path: '/404',
    parent: Routes.ERRORS,
  },
  [Routes.ERROR_503]: {
    title: Routes.ERROR_503,
    path: '/503',
    parent: Routes.ERRORS,
  },
  [Routes.PROFILE]: {
    title: Routes.PROFILE,
    path: '/profile',
    parent: Routes.SETTINGS,
  },
  [Routes.ACCOUNT_USERS]: {
    title: Routes.ACCOUNT_USERS,
    path: '/users',
    parent: Routes.SETTINGS,
  },
  [Routes.ACCOUNT_SITES]: {
    title: Routes.ACCOUNT_SITES,
    path: '/sites',
    parent: Routes.SETTINGS,
  },
  [Routes.SECURITY]: {
    title: Routes.SECURITY,
    path: '/security',
    parent: Routes.SETTINGS,
  },
  [Routes.BEEOIMPACT_METHODOLOGY]: {
    title: Routes.BEEOIMPACT_METHODOLOGY,
    path: '/methodology',
    parent: Routes.BEEOIMPACT,
  },
  [Routes.BEEOMONITORING]: {
    title: Routes.BEEOMONITORING,
    path: '/beeomonitoring',
  },
  [Routes.BEEOMONITORING_DASHBOARD]: {
    title: Routes.BEEOMONITORING_DASHBOARD,
    path: '/dashboard',
    parent: Routes.BEEOMONITORING,
  },
  [Routes.BEEOMONITORING_SITES]: {
    title: Routes.BEEOMONITORING_SITES,
    path: '/sites',
    parent: Routes.BEEOMONITORING,
  },
  [Routes.BEEOMONITORING_SITE]: {
    title: Routes.BEEOMONITORING_SITE,
    path: '/:siteId',
    parent: Routes.BEEOMONITORING_SITES,
  },
  [Routes.BEEOMONITORING_SITE_METALS]: {
    title: Routes.BEEOMONITORING_SITE_METALS,
    path: '/metals',
    parent: Routes.BEEOMONITORING_SITE,
  },
  [Routes.BEEOMONITORING_SITE_PESTICIDES]: {
    title: Routes.BEEOMONITORING_SITE_PESTICIDES,
    path: '/pesticides',
    parent: Routes.BEEOMONITORING_SITE,
  },
  [Routes.BEEOMONITORING_SITE_BIODIVERSITY]: {
    title: Routes.BEEOMONITORING_SITE_BIODIVERSITY,
    path: '/biodiversity',
    parent: Routes.BEEOMONITORING_SITE,
  },
  [Routes.BEEOMONITORING_SITE_PAH_POLLUANTS]: {
    title: Routes.BEEOMONITORING_SITE_PAH_POLLUANTS,
    path: '/pah',
    parent: Routes.BEEOMONITORING_SITE,
  },
  [Routes.BEEOMONITORING_SITE_PCB_POLLUANTS]: {
    title: Routes.BEEOMONITORING_SITE_PCB_POLLUANTS,
    path: '/pcb',
    parent: Routes.BEEOMONITORING_SITE,
  },
  [Routes.BEEOMONITORING_SITE_DIOXINES_POLLUANTS]: {
    title: Routes.BEEOMONITORING_SITE_DIOXINES_POLLUANTS,
    path: '/dioxines-and-furans',
    parent: Routes.BEEOMONITORING_SITE,
  },
  [Routes.BEEOAUDIT]: {
    title: Routes.BEEOAUDIT,
    path: '/beeoaudit',
  },
  [Routes.BEEOAUDIT_SITES]: {
    title: Routes.BEEOAUDIT_SITES,
    path: '/sites',
    parent: Routes.BEEOAUDIT,
  },
  [Routes.BEEOAUDIT_SITE]: {
    title: Routes.BEEOAUDIT_SITE,
    path: '/:siteId',
    parent: Routes.BEEOAUDIT,
  },
}
