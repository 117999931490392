import { usersSetPasswordCreate } from '@/client/backend/api/users/users'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import { goToLoginPage } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'

const changePasswordFormSchema = z
  .object({
    currentPassword: z.string().min(6),
    newPassword: z.string().nonempty(),
    confirmNewPassword: z.string().nonempty(),
  })
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ['confirmNewPassword'],
    params: {
      i18n: 'passwordsNoMatch',
    },
  })

type ChangePasswordFormValues = z.infer<typeof changePasswordFormSchema>

const ChangePasswordForm = () => {
  const { t } = useTranslation()
  const { signout } = useAuth()
  const form = useForm<ChangePasswordFormValues>({
    resolver: zodResolver(changePasswordFormSchema),
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    shouldUnregister: false,
    mode: 'onBlur',
  })

  const onSubmit = async (data: ChangePasswordFormValues) => {
    try {
      await usersSetPasswordCreate({
        new_password: data.confirmNewPassword,
        current_password: data.currentPassword,
      })

      toast({
        title: t(i18nKeys.global.settings.page.changePassword.toast.success),
      })
      signout()
      goToLoginPage()
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.settings.page.changePassword.toast.error),
          description: error.response.data[0],
        })
      }
    }
  }
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <FormField
          control={form.control}
          name="currentPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.beeoimpact.common.account.currentPasswordTitle)}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="newPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.beeoimpact.common.account.newPasswordTitle)}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="confirmNewPassword"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.beeoimpact.common.account.confirmPasswordTitle)}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit">{t(i18nKeys.beeoimpact.common.account.buttonSave)}</Button>
      </form>
    </Form>
  )
}

export default ChangePasswordForm
