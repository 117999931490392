import { useState } from 'react'
import { Account, FeaturesEnum } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef } from '@tanstack/react-table'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { Badge } from '@/components/ui/badge'
import { Checkbox } from '@/components/ui/checkbox'
import AccountsTableAction from '@/components/accounts-table-action'
import AccountsTableRowAction from '@/components/accounts-table-row-action'
import { DataTable } from '@/components/data-table/data-table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'

type UsersTableProps = {
  data: Account[]
}

const AccountsTable = ({ data }: UsersTableProps) => {
  const { t } = useTranslation()
  const [accounts, setAccounts] = useState<Account[]>(data)
  const columns: ColumnDef<Account>[] = [
    {
      id: 'select',
      header: ({ table }) => (
        <Checkbox
          checked={table.getIsAllPageRowsSelected()}
          onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
          aria-label="Select all"
          className="translate-y-[2px]"
        />
      ),
      cell: ({ row }) => (
        <Checkbox
          checked={row.getIsSelected()}
          onCheckedChange={(value) => row.toggleSelected(!!value)}
          aria-label="Select row"
          className="translate-y-[2px]"
        />
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'id',
      header: ({ column }) => <DataTableColumnHeader column={column} />,
      cell: ({ row }) => <div className="w-[10px]">{row.getValue('id')}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'name',
      accessorKey: 'name',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.admin.page.accounts.table.header.name)} />
      ),
      cell: ({ row }) => <div className="w-[50px]">{row.getValue('name')}</div>,
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'nb_sites',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.admin.page.accounts.table.header.nbSites)} />
      ),
      cell: ({ row }) => (
        <div className="w-[40px]">
          <Link
            className="hover:underline"
            to={getPath(Routes.ADMIN_ACCOUNTS_SITES, { params: { accountId: row.original.id } })}
          >
            {row.getValue('nb_sites')}
          </Link>
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'nb_users',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.global.admin.page.accounts.table.header.nbUsers)} />
      ),
      cell: ({ row }) => (
        <div className="w-[40px]">
          <Link
            className="hover:underline"
            to={getPath(Routes.ADMIN_ACCOUNTS_USERS, { params: { accountId: row.original.id } })}
          >
            {row.getValue('nb_users')}
          </Link>
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'features',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.global.admin.page.accounts.table.header.Produits)}
        />
      ),
      cell: ({ row }) => {
        const features: FeaturesEnum[] = row.getValue('features')

        const sortedFeatures = features.sort((a, b) => {
          if (a < b) return -1
          if (a > b) return 1
          return 0
        })

        const colors = {
          [FeaturesEnum.biodiversity]: 'bg-blue-300',
          [FeaturesEnum.initiative]: 'bg-orange-300',
          [FeaturesEnum.pesticides]: 'bg-purple-300',
        }
        return (
          <span className="w-[200px] truncate font-medium">
            {Object.entries(sortedFeatures).map(([key, value]) => {
              return (
                <Badge key={key} className={cn('mr-2', colors[value])}>
                  {value}
                </Badge>
              )
            })}
          </span>
        )
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      id: 'actions',
      cell: ({ row }) => {
        return <AccountsTableRowAction account={row.original} accounts={accounts} setAccounts={setAccounts} />
      },
    },
  ]

  return (
    <DataTable
      columns={columns}
      data={accounts}
      action={<AccountsTableAction accounts={accounts} setAccounts={setAccounts} />}
      filterIdColumn="name"
    />
  )
}

export default AccountsTable
