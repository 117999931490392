import { Dispatch, SetStateAction } from 'react'
import { ProbablePesticide } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ColumnDef, flexRender, getCoreRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table'
import { Droplet, Info } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import { SpeciesType } from '@/types/species-type'
import GlobalEcoscoreColor from '@/lib/global-ecoscore'
import { toTitleCase } from '@/lib/string'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import { DataTableColumnHeader } from '@/components/data-table/data-table-column-header'
import { DataTablePagination } from '@/components/data-table/data-table-pagination'
import { SpeciesIcon } from '@/components/icons-components/active-matters-icons'

interface PesticidesTableProps {
  activeMatters: ProbablePesticide[]
  setSelectedPesticide: Dispatch<SetStateAction<ProbablePesticide>>
  selectedPesticide?: ProbablePesticide
}

const PesticidesTable = ({ activeMatters, setSelectedPesticide, selectedPesticide }: PesticidesTableProps) => {
  const { t } = useTranslation()

  const columns: ColumnDef<ProbablePesticide>[] = [
    {
      accessorKey: 'activeMatter',
      id: 'activeMatter',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.pesticidesTable.activeMatters.table.header.activeMatter)}
        />
      ),
      cell: ({ row }) => (
        <div className="w-[220px]">
          <span className="font-medium underline underline-offset-2 ">{toTitleCase(row.original.name)}</span>
        </div>
      ),
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'waterRisk',
      id: 'waterRisk',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.pesticidesTable.activeMatters.table.header.waterRisk)}
        />
      ),
      cell: ({ row }) => {
        const isWaterRisk = row.original.ecoscores.ecoscore_water && row.original.ecoscores.ecoscore_water
        const color = isWaterRisk ? 'text-red-500 bg-red-100' : 'text-green-500 bg-green-100'
        return (
          <div className={`inline-flex size-8 items-center justify-center rounded-full ${color}`}>
            <Droplet className={`size-5 ${color}`} />
          </div>
        )
      },

      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'toxicity',
      id: 'toxicity',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.pesticidesTable.activeMatters.table.header.toxicity)}
        />
      ),
      cell: ({ row }) => {
        return (
          <div>
            <GlobalEcoscoreColor ecoscore_global={row.original.ecoscores.ecoscore_global} />
          </div>
        )
      },

      enableSorting: false,
      enableHiding: false,
    },

    {
      accessorKey: 'bees',
      id: 'bees',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.pesticidesTable.speciesGroups.bees)} />
      ),
      cell: ({ row }) => {
        return (
          <div>
            <div className="flex items-center">
              <span className="mr-2">
                <SpeciesIcon value={row.original.ecoscores.ecoscore_bees} species={SpeciesType.Bees} />
              </span>
            </div>
          </div>
        )
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'birds',
      id: 'birds',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.pesticidesTable.speciesGroups.birds)} />
      ),
      cell: ({ row }) => {
        return (
          <div>
            <div className="flex items-center">
              <span className="mr-2">
                <SpeciesIcon value={row.original.ecoscores.ecoscore_birds} species={SpeciesType.Birds} />
              </span>
            </div>
          </div>
        )
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'fishes',
      id: 'fishes',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.pesticidesTable.speciesGroups.fishes)} />
      ),
      cell: ({ row }) => {
        return (
          <div>
            <div className="flex items-center">
              <div className="mr-2">
                <SpeciesIcon value={row.original.ecoscores.ecoscore_fishes} species={SpeciesType.Fishes} />
              </div>
            </div>
          </div>
        )
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'mammals',
      id: 'mammals',
      header: ({ column }) => (
        <DataTableColumnHeader
          column={column}
          children={t(i18nKeys.beeoimpact.pesticidesTable.speciesGroups.mammals)}
        />
      ),
      cell: ({ row }) => {
        return (
          <div>
            <div className="flex items-center">
              <div className="mr-2">
                <SpeciesIcon value={row.original.ecoscores.ecoscore_mammals} species={SpeciesType.Mammals} />
              </div>
            </div>
          </div>
        )
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'worms',
      id: 'worms',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.pesticidesTable.speciesGroups.worms)} />
      ),
      cell: ({ row }) => {
        return (
          <div>
            <div className="flex items-center">
              <SpeciesIcon value={row.original.ecoscores.ecoscore_worms} species={SpeciesType.Worms} />
            </div>
          </div>
        )
      },
      enableSorting: false,
      enableHiding: false,
    },
    {
      accessorKey: 'soil',
      id: 'soil',
      header: ({ column }) => (
        <DataTableColumnHeader column={column} children={t(i18nKeys.beeoimpact.pesticidesTable.speciesGroups.soil)} />
      ),
      cell: ({ row }) => {
        return (
          <div>
            <div className="flex items-center">
              <SpeciesIcon value={row.original.ecoscores.ecoscore_soil} species={SpeciesType.Soil} />
            </div>
          </div>
        )
      },
      enableSorting: false,
      enableHiding: false,
    },
  ]

  const table = useReactTable({
    data: activeMatters,
    columns,
    autoResetPageIndex: false,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getSortedRowModel(),
  })

  const clickedRow = (pesticideClicked: ProbablePesticide) => {
    setSelectedPesticide(pesticideClicked)
    window.scrollTo({ top: 200, behavior: 'smooth' })
  }

  return (
    <div className="mt-8 flex w-full flex-col justify-between">
      <div className="w-full">
        <div className="flex items-center justify-end space-x-2 text-xs text-muted-foreground">
          <Info className="w-5" />
          <p>{t(i18nKeys.beeoimpact.pesticidesTable.activeMatters.table.information)}</p>
        </div>
        <Table className="border">
          <TableHeader className="border">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  )
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row, index) => (
                <TableRow
                  key={index}
                  className={`${row.original === selectedPesticide ? 'bg-red-50' : ''} cursor-pointer`}
                  onClick={() => clickedRow(row.original)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {t(i18nKeys.global.common.datatable.emptyResults)}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="mt-4 p-4">
        <DataTablePagination table={table} />
      </div>
    </div>
  )
}

export default PesticidesTable
