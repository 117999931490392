import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'

const CardPieChartSkeleton = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Skeleton className="h-6 w-32" />
        </CardTitle>

        <Skeleton className="h-4 w-48" />
      </CardHeader>
      <CardContent>
        <div className="flex h-[360px] w-full items-center justify-center">
          <Skeleton className="size-full" />
        </div>

        <div className="mt-4 flex min-h-[50px] flex-wrap justify-center">
          {Array.from({ length: 5 }).map((_, index) => (
            <div key={index} className="mr-4 flex items-center text-xs text-muted-foreground">
              <Skeleton className="mr-2 size-3 rounded-full" />
              <Skeleton className="h-4 w-12" />
            </div>
          ))}
        </div>
      </CardContent>
    </Card>
  )
}

export default CardPieChartSkeleton
