interface AnimalCardProps {
  icon: React.ReactNode
  name: string
  number: number
}

const AnimalCard = ({ icon, name, number }: AnimalCardProps) => {
  return (
    <div className="flex w-[135px] items-end justify-start space-x-2 px-2">
      <div className="size-6 text-biodivTealSevenHundred">{icon}</div>
      <div className="flex space-x-1 text-xs">
        <p className=" font-semibold">{number}</p>
        <p className="">{name}</p>
      </div>
    </div>
  )
}
export default AnimalCard
