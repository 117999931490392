import { Card } from '@/components/ui/card'

interface AuditMiniCardProps {
  name: string
  number: number | string
}

const AuditMiniCard = ({ name, number }: AuditMiniCardProps) => {
  return (
    <Card className="flex max-h-[68px] w-full items-center justify-between space-x-2 border border-biodivTealSevenHundred px-5 py-6 lg:max-h-[60px] xl:max-h-[68px] 2xl:max-h-[60px]">
      <p className=" whitespace-normal text-sm">{name}</p>
      <p className="whitespace-nowrap text-right text-2xl font-semibold">{number}</p>
    </Card>
  )
}

export default AuditMiniCard
