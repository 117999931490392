import { responsesCreate } from '@/client/backend/api/responses/responses'
import { Question, Response, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel } from '@/components/ui/form'
import { Separator } from '@/components/ui/separator'
import { Switch } from '@/components/ui/switch'
import { toast } from '@/components/ui/use-toast'

interface SiteQuestionsProps {
  site?: Site
  questions: Question[]
  responses: Response[]
  readOnly?: boolean
  onSubmit?: (responses: Response[]) => void
}

const SiteQuestions = ({ site, questions, responses, readOnly, onSubmit: onFormSubmit }: SiteQuestionsProps) => {
  const form = useForm()
  const { t } = useTranslation()

  const isChecked = (questionId) => {
    const response = responses.find((response) => {
      return response.question === questionId
    })
    return response?.answer === 'Oui'
  }

  const onSubmit = async (data: Record<string, boolean>) => {
    if (site) {
      const newResponses = await Promise.all(
        Object.entries(data).map(([question, answer]) => {
          return responsesCreate({
            site: site.id,
            question: parseInt(question),
            answer: answer ? 'Oui' : 'Non',
          })
        })
      )
      onFormSubmit?.(newResponses)

      toast({
        title: t(i18nKeys.beeoinitiative.site.questions.success),
      })
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mt-4 w-full space-y-6">
        <div className="space-y-4">
          {questions.map((question) => {
            return (
              <FormField
                key={question.id}
                control={form.control}
                name={question.id.toString()}
                defaultValue={isChecked(question.id)}
                render={({ field }) => (
                  <FormItem className="flex flex-row items-center justify-between">
                    <div className="space-y-0.5">
                      <FormLabel>{question.question}</FormLabel>
                    </div>
                    <FormControl>
                      <Switch
                        checked={field.value}
                        onCheckedChange={field.onChange}
                        disabled={readOnly}
                        aria-readonly={readOnly}
                      />
                    </FormControl>
                  </FormItem>
                )}
              />
            )
          })}
        </div>
        {!readOnly && (
          <>
            <Separator />
            <div className="flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2">
              <Button type="submit">{t(i18nKeys.beeoinitiative.site.questions.button)}</Button>
            </div>
          </>
        )}
      </form>
    </Form>
  )
}

export default SiteQuestions
