import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { PatchedResponse, Response } from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const responsesList = (signal?: AbortSignal) => {
  return backendInstance<Response[]>({ url: `/responses/`, method: 'GET', signal })
}

export const getResponsesListQueryKey = () => {
  return [`/responses/`] as const
}

export const getResponsesListQueryOptions = <
  TData = Awaited<ReturnType<typeof responsesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof responsesList>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getResponsesListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof responsesList>>> = ({ signal }) => responsesList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof responsesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ResponsesListQueryResult = NonNullable<Awaited<ReturnType<typeof responsesList>>>
export type ResponsesListQueryError = ErrorType<unknown>

export const useResponsesList = <
  TData = Awaited<ReturnType<typeof responsesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof responsesList>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getResponsesListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const responsesCreate = (response: NonReadonly<Response>) => {
  return backendInstance<Response>({
    url: `/responses/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: response,
  })
}

export const getResponsesCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof responsesCreate>>,
    TError,
    { data: NonReadonly<Response> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof responsesCreate>>,
  TError,
  { data: NonReadonly<Response> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof responsesCreate>>, { data: NonReadonly<Response> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return responsesCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ResponsesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof responsesCreate>>>
export type ResponsesCreateMutationBody = NonReadonly<Response>
export type ResponsesCreateMutationError = ErrorType<unknown>

export const useResponsesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof responsesCreate>>,
    TError,
    { data: NonReadonly<Response> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof responsesCreate>>,
  TError,
  { data: NonReadonly<Response> },
  TContext
> => {
  const mutationOptions = getResponsesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const responsesRetrieve = (id: string, signal?: AbortSignal) => {
  return backendInstance<Response>({ url: `/responses/${id}/`, method: 'GET', signal })
}

export const getResponsesRetrieveQueryKey = (id: string) => {
  return [`/responses/${id}/`] as const
}

export const getResponsesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof responsesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof responsesRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getResponsesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof responsesRetrieve>>> = ({ signal }) =>
    responsesRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof responsesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ResponsesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof responsesRetrieve>>>
export type ResponsesRetrieveQueryError = ErrorType<unknown>

export const useResponsesRetrieve = <
  TData = Awaited<ReturnType<typeof responsesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof responsesRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getResponsesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const responsesUpdate = (id: string, response: NonReadonly<Response>) => {
  return backendInstance<Response>({
    url: `/responses/${id}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: response,
  })
}

export const getResponsesUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof responsesUpdate>>,
    TError,
    { id: string; data: NonReadonly<Response> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof responsesUpdate>>,
  TError,
  { id: string; data: NonReadonly<Response> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof responsesUpdate>>,
    { id: string; data: NonReadonly<Response> }
  > = (props) => {
    const { id, data } = props ?? {}

    return responsesUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ResponsesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof responsesUpdate>>>
export type ResponsesUpdateMutationBody = NonReadonly<Response>
export type ResponsesUpdateMutationError = ErrorType<unknown>

export const useResponsesUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof responsesUpdate>>,
    TError,
    { id: string; data: NonReadonly<Response> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof responsesUpdate>>,
  TError,
  { id: string; data: NonReadonly<Response> },
  TContext
> => {
  const mutationOptions = getResponsesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const responsesPartialUpdate = (id: string, patchedResponse: NonReadonly<PatchedResponse>) => {
  return backendInstance<Response>({
    url: `/responses/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedResponse,
  })
}

export const getResponsesPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof responsesPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedResponse> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof responsesPartialUpdate>>,
  TError,
  { id: string; data: NonReadonly<PatchedResponse> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof responsesPartialUpdate>>,
    { id: string; data: NonReadonly<PatchedResponse> }
  > = (props) => {
    const { id, data } = props ?? {}

    return responsesPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ResponsesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof responsesPartialUpdate>>>
export type ResponsesPartialUpdateMutationBody = NonReadonly<PatchedResponse>
export type ResponsesPartialUpdateMutationError = ErrorType<unknown>

export const useResponsesPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof responsesPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedResponse> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof responsesPartialUpdate>>,
  TError,
  { id: string; data: NonReadonly<PatchedResponse> },
  TContext
> => {
  const mutationOptions = getResponsesPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const responsesDestroy = (id: string) => {
  return backendInstance<void>({ url: `/responses/${id}/`, method: 'DELETE' })
}

export const getResponsesDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof responsesDestroy>>, TError, { id: string }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof responsesDestroy>>, TError, { id: string }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof responsesDestroy>>, { id: string }> = (props) => {
    const { id } = props ?? {}

    return responsesDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type ResponsesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof responsesDestroy>>>

export type ResponsesDestroyMutationError = ErrorType<unknown>

export const useResponsesDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof responsesDestroy>>, TError, { id: string }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof responsesDestroy>>, TError, { id: string }, TContext> => {
  const mutationOptions = getResponsesDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
