import { i18nKeys } from '@/locales/keys'
import { ChevronDown, User } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo, isAdmin } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'
import { Icons } from '@/components/icons'
import Loader from '@/components/loader'

const UserNav = () => {
  const { t } = useTranslation()
  const { user, signout, stopImpersonate, isImpersonate } = useAuth()
  const navigate = useNavigate()

  if (!user) return <Loader />

  const dropdownMenuItems = [
    {
      label: t(i18nKeys.global.common.user.menu.settings),
      icon: <Icons.Settings className="mr-2 size-4" />,
      link: getPath(Routes.PROFILE),
      canAccess: true,
    },
    {
      label: t(i18nKeys.global.common.user.menu.admin),
      icon: <Icons.Lock className="mr-2 size-4" />,
      link: getPath(Routes.ADMIN),
      canAccess: isAdmin(user),
    },
  ]

  const disconnect = () => {
    stopImpersonate()
    if (user) {
      if (hasAccesTo(user.features, FeatureType.INITIATIVE)) {
        navigate(getPath(Routes.BEEOINITIATIVE_DASHBOARD))
      } else {
        navigate(getPath(Routes.BEEOIMPACT_DASHBOARD))
      }
    }
  }
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            className="relative rounded-full border-none bg-transparent hover:bg-white"
          >
            <User className="size-6" />
            <ChevronDown className="size-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="mt-3 w-56" align="end" forceMount>
          <DropdownMenuLabel className="font-normal">
            <div className="flex flex-col space-y-1">
              <p className="text-sm font-medium leading-none">
                {user?.first_name} {user?.last_name}
              </p>
              <p className="text-xs leading-none text-muted-foreground">{user?.email}</p>
            </div>
          </DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            {dropdownMenuItems.map((item, index) => {
              if (item.canAccess) {
                return (
                  <Link to={item.link} key={index}>
                    <DropdownMenuItem>
                      {item.icon}
                      <span>{item.label}</span>
                    </DropdownMenuItem>
                  </Link>
                )
              }
            })}
          </DropdownMenuGroup>
          <DropdownMenuSeparator />

          {isImpersonate ? (
            <DropdownMenuItem onClick={() => disconnect()}>
              <Icons.Logout className="mr-2 size-4" />
              <span>{t(i18nKeys.global.common.user.menu.disconnect)}</span>
            </DropdownMenuItem>
          ) : (
            <DropdownMenuItem onClick={() => signout()}>
              <Icons.Logout className="mr-2 size-4" />
              <span>{t(i18nKeys.global.common.user.menu.logout)}</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  )
}

export default UserNav
