import { useEffect, useMemo, useState } from 'react'
import {
  beeomonitoringGraphsHeavymetalCountByPeriodList,
  beeomonitoringGraphsHeavymetalValuesByPeriodList,
  getBeeomonitoringGraphsHeavymetalCountByPeriodListQueryKey,
  getBeeomonitoringGraphsHeavymetalValuesByPeriodListQueryKey,
} from '@/client/backend/api/graphs/graphs'
import {
  beeomonitoringSitesSiteInfosRetrieve,
  getBeeomonitoringSitesSiteInfosRetrieveQueryKey,
} from '@/client/backend/api/sites/sites'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { HomeIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { BarChartType } from '@/types/barchart-type'
import { BarChartValueWithColor } from '@/types/graph'
import { COLORS } from '@/lib/constants/constants'
import { getBarPeriodData } from '@/lib/graph'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Skeleton } from '@/components/ui/skeleton'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb'
import CardBarChart from '@/components/card-barchart'
import CardBarChartSkeleton from '@/components/card-barchart-skeleton'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'

const MetalsMonitoringPage = () => {
  const [searchParams] = useSearchParams()

  const year = searchParams.get('year') ?? '2023'
  const period = searchParams.get('period') ?? 'P4'

  const { t } = useTranslation()
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedPeriod, setSelectedPeriod] = useState(period)

  const [years, setYears] = useState<string[]>([])

  const [periods, setPeriods] = useState<string[]>([])

  const [countHms, setCountHms] = useState<BarChartValueWithColor[]>([])
  const [countHmsValues, setCountHmsValues] = useState<BarChartValueWithColor[]>([])

  useEffect(() => {
    setSelectedYear(year ?? selectedYear)
    setSelectedPeriod(period ?? selectedPeriod)
  }, [year, period])

  const { siteId } = useParams<{ siteId: string }>() as { siteId: string }

  const { data: site, isLoading: isLoadingSite } = useQuery({
    queryKey: [getBeeomonitoringSitesSiteInfosRetrieveQueryKey(siteId)],
    queryFn: () => beeomonitoringSitesSiteInfosRetrieve(siteId, undefined),
  })

  const { data: dataCountHms, isLoading: isLoadingCountHms } = useQuery({
    queryKey: [getBeeomonitoringGraphsHeavymetalCountByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsHeavymetalCountByPeriodList(siteId, undefined),
  })

  const { data: dataCountHmsValues, isLoading: isLoadingCountHmsValues } = useQuery({
    queryKey: [getBeeomonitoringGraphsHeavymetalValuesByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsHeavymetalValuesByPeriodList(siteId, undefined),
  })

  const allYears = useMemo(() => {
    const yearsCategories = dataCountHms?.map((item) => item.year) || []
    const yearsFamilies = dataCountHmsValues?.map((item) => item.year) || []
    return Array.from(new Set([...yearsCategories, ...yearsFamilies])).sort()
  }, [dataCountHms, dataCountHmsValues])

  useEffect(() => {
    if (allYears.length > 0) {
      setYears(allYears)
      setSelectedYear(year ?? allYears[allYears.length - 1])
    }
  }, [allYears])

  const allPeriods = useMemo(() => {
    const periodsCountHms = dataCountHms?.find((item) => item.year === selectedYear)?.periods || []
    const periodsCountHmsValues = dataCountHmsValues?.find((item) => item.year === selectedYear)?.periods || []
    return Array.from(new Set([...periodsCountHms, ...periodsCountHmsValues].map((item) => item.key))).sort()
  }, [dataCountHms, dataCountHmsValues, selectedYear])

  useEffect(() => {
    if (allPeriods.length > 0) {
      setPeriods(allPeriods)
      setSelectedPeriod(allPeriods.includes(period) ? period : allPeriods[allPeriods.length - 1])
    }
  }, [allPeriods])

  useEffect(() => {
    if (selectedYear && selectedPeriod) {
      setCountHms(getBarPeriodData(dataCountHms ?? [], selectedPeriod, selectedYear))
      setCountHmsValues(getBarPeriodData(dataCountHmsValues ?? [], selectedPeriod, selectedYear))
    }
  }, [dataCountHms, dataCountHmsValues, selectedPeriod, selectedYear])

  return (
    <div className="container relative my-8">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon className="size-4" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOMONITORING_SITES)}>
            {t(i18nKeys.beeoimpact.common.breadcrumb.mySites)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOMONITORING_SITE, { params: { siteId: site?.id as number } })}>
            {isLoadingSite ? <Skeleton className="h-4 w-[200px]" /> : site?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            href={getPath(Routes.BEEOMONITORING_SITE_BIODIVERSITY, { params: { siteId: site?.id as number } })}
          >
            {t(i18nKeys.beeomonitoring.common.breadcrumb.metals)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="flex flex-col justify-between">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>
              {isLoadingSite ? (
                <div className="flex items-end justify-start">
                  <Skeleton className="mr-2 h-[34px] w-[400px]" />
                  <Skeleton className="h-[24px] w-[80px]" />
                </div>
              ) : (
                site && (
                  <div>
                    {site.name} <span className="text-sm font-medium text-muted-foreground"># {site.key}</span>
                  </div>
                )
              )}
            </PageHeaderHeading>
            <div className="flex flex-row">
              {isLoadingSite ? (
                <>
                  <div>
                    <Skeleton className="h-[22px] w-[62px]" />
                  </div>

                  <div>
                    <Skeleton className="ml-2 h-[22px] w-[110px]" />
                  </div>
                </>
              ) : (
                site?.tags?.map((tag) => (
                  <Badge key={tag} className="mr-4 rounded-none bg-muted text-foreground hover:bg-foreground/10">
                    {tag}
                  </Badge>
                ))
              )}
            </div>
          </PageHeader>
          <div className="flex items-center space-x-2">
            {isLoadingSite || isLoadingCountHms || isLoadingCountHmsValues ? (
              <>
                <Skeleton className="h-10 w-44" />
                <Skeleton className="h-10 w-44" />
              </>
            ) : (
              <>
                <Select value={selectedYear} onValueChange={(e) => setSelectedYear(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedYear} placeholder={selectedYear} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {years.map((year) => (
                        <SelectItem key={year} value={year}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Select value={selectedPeriod} onValueChange={(e) => setSelectedPeriod(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedPeriod} placeholder={`${selectedPeriod}`} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {periods.map((period, index) => (
                        <SelectItem key={index} value={period}>
                          {period}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-2">
          {isLoadingCountHms ? (
            <CardBarChartSkeleton />
          ) : (
            countHms.map((item) => (
              <CardBarChart
                data={[item]}
                title={t(i18nKeys.beeomonitoring.metals.graph.count_barchart.title)}
                subtitle={t(i18nKeys.beeomonitoring.metals.graph.count_barchart.description)}
                colors={COLORS}
                showLegend
                legendY={t(i18nKeys.beeomonitoring.metals.graph.count_barchart.legend.y)}
                rounded={1}
              />
            ))
          )}
          <Card>
            <CardHeader>
              <CardTitle>{t(i18nKeys.beeomonitoring.common.definition)}</CardTitle>
              <CardDescription>{t(i18nKeys.beeomonitoring.metals.description)}</CardDescription>
            </CardHeader>
            <CardContent>
              <p className="leading-6">{t(i18nKeys.beeomonitoring.metals.definition.content.para1)}</p>
              <p className="mt-4 leading-6">{t(i18nKeys.beeomonitoring.metals.definition.content.para2)}</p>
              <p className="mt-4 leading-6">{t(i18nKeys.beeomonitoring.metals.definition.content.para3)}</p>
            </CardContent>
          </Card>
        </div>
        <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          {isLoadingCountHmsValues
            ? Array.from({ length: 3 }).map((_) => <CardBarChartSkeleton withDescription={false} />)
            : countHmsValues.map((item) => (
                <CardBarChart
                  data={[item]}
                  title={item.name}
                  colors={COLORS}
                  showLegend
                  lmrValue={item.lmr}
                  loqValue={item.loq}
                  legendY={t(i18nKeys.beeomonitoring.metals.graph.legend.y)}
                  type={BarChartType.HM}
                />
              ))}
        </div>
      </div>
    </div>
  )
}

export default MetalsMonitoringPage
