import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { Initiative, PatchedInitiative, SiteIDName } from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const initiativesList = (signal?: AbortSignal) => {
  return backendInstance<Initiative[]>({ url: `/initiatives/`, method: 'GET', signal })
}

export const getInitiativesListQueryKey = () => {
  return [`/initiatives/`] as const
}

export const getInitiativesListQueryOptions = <
  TData = Awaited<ReturnType<typeof initiativesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof initiativesList>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInitiativesListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof initiativesList>>> = ({ signal }) => initiativesList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof initiativesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InitiativesListQueryResult = NonNullable<Awaited<ReturnType<typeof initiativesList>>>
export type InitiativesListQueryError = ErrorType<unknown>

export const useInitiativesList = <
  TData = Awaited<ReturnType<typeof initiativesList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof initiativesList>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInitiativesListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const initiativesCreate = (initiative: NonReadonly<Initiative>) => {
  return backendInstance<Initiative>({
    url: `/initiatives/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: initiative,
  })
}

export const getInitiativesCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof initiativesCreate>>,
    TError,
    { data: NonReadonly<Initiative> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof initiativesCreate>>,
  TError,
  { data: NonReadonly<Initiative> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof initiativesCreate>>,
    { data: NonReadonly<Initiative> }
  > = (props) => {
    const { data } = props ?? {}

    return initiativesCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InitiativesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof initiativesCreate>>>
export type InitiativesCreateMutationBody = NonReadonly<Initiative>
export type InitiativesCreateMutationError = ErrorType<unknown>

export const useInitiativesCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof initiativesCreate>>,
    TError,
    { data: NonReadonly<Initiative> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof initiativesCreate>>,
  TError,
  { data: NonReadonly<Initiative> },
  TContext
> => {
  const mutationOptions = getInitiativesCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const initiativesRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<Initiative>({ url: `/initiatives/${id}/`, method: 'GET', signal })
}

export const getInitiativesRetrieveQueryKey = (id: number) => {
  return [`/initiatives/${id}/`] as const
}

export const getInitiativesRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof initiativesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof initiativesRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInitiativesRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof initiativesRetrieve>>> = ({ signal }) =>
    initiativesRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof initiativesRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InitiativesRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof initiativesRetrieve>>>
export type InitiativesRetrieveQueryError = ErrorType<unknown>

export const useInitiativesRetrieve = <
  TData = Awaited<ReturnType<typeof initiativesRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof initiativesRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInitiativesRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const initiativesUpdate = (id: number, initiative: NonReadonly<Initiative>) => {
  return backendInstance<Initiative>({
    url: `/initiatives/${id}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: initiative,
  })
}

export const getInitiativesUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof initiativesUpdate>>,
    TError,
    { id: number; data: NonReadonly<Initiative> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof initiativesUpdate>>,
  TError,
  { id: number; data: NonReadonly<Initiative> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof initiativesUpdate>>,
    { id: number; data: NonReadonly<Initiative> }
  > = (props) => {
    const { id, data } = props ?? {}

    return initiativesUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InitiativesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof initiativesUpdate>>>
export type InitiativesUpdateMutationBody = NonReadonly<Initiative>
export type InitiativesUpdateMutationError = ErrorType<unknown>

export const useInitiativesUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof initiativesUpdate>>,
    TError,
    { id: number; data: NonReadonly<Initiative> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof initiativesUpdate>>,
  TError,
  { id: number; data: NonReadonly<Initiative> },
  TContext
> => {
  const mutationOptions = getInitiativesUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const initiativesPartialUpdate = (id: number, patchedInitiative: NonReadonly<PatchedInitiative>) => {
  return backendInstance<Initiative>({
    url: `/initiatives/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedInitiative,
  })
}

export const getInitiativesPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof initiativesPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedInitiative> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof initiativesPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedInitiative> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof initiativesPartialUpdate>>,
    { id: number; data: NonReadonly<PatchedInitiative> }
  > = (props) => {
    const { id, data } = props ?? {}

    return initiativesPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type InitiativesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof initiativesPartialUpdate>>>
export type InitiativesPartialUpdateMutationBody = NonReadonly<PatchedInitiative>
export type InitiativesPartialUpdateMutationError = ErrorType<unknown>

export const useInitiativesPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof initiativesPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedInitiative> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof initiativesPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedInitiative> },
  TContext
> => {
  const mutationOptions = getInitiativesPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const initiativesDestroy = (id: number) => {
  return backendInstance<void>({ url: `/initiatives/${id}/`, method: 'DELETE' })
}

export const getInitiativesDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof initiativesDestroy>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof initiativesDestroy>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof initiativesDestroy>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return initiativesDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type InitiativesDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof initiativesDestroy>>>

export type InitiativesDestroyMutationError = ErrorType<unknown>

export const useInitiativesDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof initiativesDestroy>>, TError, { id: number }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof initiativesDestroy>>, TError, { id: number }, TContext> => {
  const mutationOptions = getInitiativesDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
export const initiativesSitesList = (id: number, signal?: AbortSignal) => {
  return backendInstance<SiteIDName[]>({ url: `/initiatives/${id}/sites/`, method: 'GET', signal })
}

export const getInitiativesSitesListQueryKey = (id: number) => {
  return [`/initiatives/${id}/sites/`] as const
}

export const getInitiativesSitesListQueryOptions = <
  TData = Awaited<ReturnType<typeof initiativesSitesList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof initiativesSitesList>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getInitiativesSitesListQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof initiativesSitesList>>> = ({ signal }) =>
    initiativesSitesList(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof initiativesSitesList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type InitiativesSitesListQueryResult = NonNullable<Awaited<ReturnType<typeof initiativesSitesList>>>
export type InitiativesSitesListQueryError = ErrorType<unknown>

export const useInitiativesSitesList = <
  TData = Awaited<ReturnType<typeof initiativesSitesList>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof initiativesSitesList>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getInitiativesSitesListQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
