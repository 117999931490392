import { lazy, Suspense, useMemo } from 'react'
import { LucideProps } from 'lucide-react'
import dynamicIconImports from 'lucide-react/dynamicIconImports'

interface IconProps extends Omit<LucideProps, 'ref'> {
  name?: string
  color: string
}

const CategoryIcon = ({ name, color, ...props }: IconProps) =>
  useMemo(() => {
    if (!name) return null
    const LucideIcon = lazy(dynamicIconImports[name])

    return (
      <Suspense fallback={<div style={{ background: '#ddd', width: 16, height: 16 }} />}>
        <LucideIcon color={color} {...props} />
      </Suspense>
    )
  }, [name, color])

export default CategoryIcon
