import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { ActionsTakenRetrieveParams, ActionTaken, ActionTakenUpdate, PatchedActionTakenUpdate } from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const actionsTakenRetrieve = (id: string, params?: ActionsTakenRetrieveParams, signal?: AbortSignal) => {
  return backendInstance<ActionTaken>({ url: `/actions-taken/${id}/`, method: 'GET', params, signal })
}

export const getActionsTakenRetrieveQueryKey = (id: string, params?: ActionsTakenRetrieveParams) => {
  return [`/actions-taken/${id}/`, ...(params ? [params] : [])] as const
}

export const getActionsTakenRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof actionsTakenRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params?: ActionsTakenRetrieveParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof actionsTakenRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getActionsTakenRetrieveQueryKey(id, params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof actionsTakenRetrieve>>> = ({ signal }) =>
    actionsTakenRetrieve(id, params, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof actionsTakenRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type ActionsTakenRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof actionsTakenRetrieve>>>
export type ActionsTakenRetrieveQueryError = ErrorType<unknown>

export const useActionsTakenRetrieve = <
  TData = Awaited<ReturnType<typeof actionsTakenRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: string,
  params?: ActionsTakenRetrieveParams,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof actionsTakenRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getActionsTakenRetrieveQueryOptions(id, params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const actionsTakenUpdate = (id: string, actionTakenUpdate: NonReadonly<ActionTakenUpdate>) => {
  return backendInstance<ActionTakenUpdate>({
    url: `/actions-taken/${id}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: actionTakenUpdate,
  })
}

export const getActionsTakenUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof actionsTakenUpdate>>,
    TError,
    { id: string; data: NonReadonly<ActionTakenUpdate> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof actionsTakenUpdate>>,
  TError,
  { id: string; data: NonReadonly<ActionTakenUpdate> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof actionsTakenUpdate>>,
    { id: string; data: NonReadonly<ActionTakenUpdate> }
  > = (props) => {
    const { id, data } = props ?? {}

    return actionsTakenUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ActionsTakenUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof actionsTakenUpdate>>>
export type ActionsTakenUpdateMutationBody = NonReadonly<ActionTakenUpdate>
export type ActionsTakenUpdateMutationError = ErrorType<unknown>

export const useActionsTakenUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof actionsTakenUpdate>>,
    TError,
    { id: string; data: NonReadonly<ActionTakenUpdate> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof actionsTakenUpdate>>,
  TError,
  { id: string; data: NonReadonly<ActionTakenUpdate> },
  TContext
> => {
  const mutationOptions = getActionsTakenUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const actionsTakenPartialUpdate = (
  id: string,
  patchedActionTakenUpdate: NonReadonly<PatchedActionTakenUpdate>
) => {
  return backendInstance<ActionTakenUpdate>({
    url: `/actions-taken/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedActionTakenUpdate,
  })
}

export const getActionsTakenPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof actionsTakenPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedActionTakenUpdate> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof actionsTakenPartialUpdate>>,
  TError,
  { id: string; data: NonReadonly<PatchedActionTakenUpdate> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof actionsTakenPartialUpdate>>,
    { id: string; data: NonReadonly<PatchedActionTakenUpdate> }
  > = (props) => {
    const { id, data } = props ?? {}

    return actionsTakenPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type ActionsTakenPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof actionsTakenPartialUpdate>>>
export type ActionsTakenPartialUpdateMutationBody = NonReadonly<PatchedActionTakenUpdate>
export type ActionsTakenPartialUpdateMutationError = ErrorType<unknown>

export const useActionsTakenPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof actionsTakenPartialUpdate>>,
    TError,
    { id: string; data: NonReadonly<PatchedActionTakenUpdate> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof actionsTakenPartialUpdate>>,
  TError,
  { id: string; data: NonReadonly<PatchedActionTakenUpdate> },
  TContext
> => {
  const mutationOptions = getActionsTakenPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
