import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import Error from '@/components/error'

const Page404 = () => {
  const { t } = useTranslation()
  return (
    <div className="container relative">
      <Error
        status={404}
        title={t(i18nKeys.global.common.errors[404].title)}
        description={t(i18nKeys.global.common.errors[404].description)}
      />
    </div>
  )
}

export default Page404
