import { User } from '@/client/backend/models'

import { FeatureType } from '@/types/feature-type'
import { Group } from '@/types/group'

export const hasGroupPermission = (user: User | null) => {
  if (!user) {
    return false
  }

  return user.groups?.some(
    (group) =>
      group.name === Group.ACCOUNT_MANAGER.toLocaleLowerCase().toString() ||
      group.name === Group.BEEO_INTERNAL.toLocaleLowerCase().toString()
  )
}

export const isAdmin = (user) => {
  if (!user) {
    return false
  }

  return user.groups?.some((group) => group.name === Group.BEEO_INTERNAL.toLocaleLowerCase().toString())
}

export const hasAccesTo = (features: readonly string[], featureType: FeatureType) => {
  return features.includes(featureType)
}
