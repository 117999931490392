import { auditsDestroy } from '@/client/backend/api/audits/audits'
import { Audit, Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { AlertDialogAction } from '@radix-ui/react-alert-dialog'
import { isAxiosError } from 'axios'
import { Pen, Trash2 } from 'lucide-react'
import { useTranslation } from 'react-i18next'

import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import AuditFormSlider from '@/components/beeoaudit/audit-form-slider'

type AuditsTableRowActionsProps = {
  site: Site
  audit: Audit
  onAuditSave: (newAudit: Audit) => void
  onAuditDelete: (deletedAudit: Audit) => void
}

const AuditsTableRowActions = ({ site, audit, onAuditSave, onAuditDelete }: AuditsTableRowActionsProps) => {
  const { t } = useTranslation()

  const deleteAudit = async (audit: Audit) => {
    try {
      await auditsDestroy(audit.id)
      toast({
        title: t(i18nKeys.beeoaudit.siteDetails.deletedSuccess),
      })
      onAuditDelete(audit)
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.admin.page.accounts.dialog.toast.error),
          description: error.response.data[0],
        })
      }
    }
  }

  return (
    <div className="flex items-center justify-center gap-2">
      <AuditFormSlider audit={audit} site={site} onAuditSave={onAuditSave}>
        <Button size="icon" variant="outline" className="ml-auto hidden border-0 lg:flex">
          <Pen className="size-3" />
        </Button>
      </AuditFormSlider>
      <AlertDialog>
        <AlertDialogTrigger asChild>
          <Button size="icon" variant="outline" className="ml-auto hidden border-0 lg:flex">
            <Trash2 className="size-3" />
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>{t(i18nKeys.beeoaudit.siteDetails.deleteDialog.title)}</AlertDialogTitle>
            <AlertDialogDescription>{t(i18nKeys.beeoaudit.siteDetails.deleteDialog.content)}</AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>{t(i18nKeys.beeoaudit.siteDetails.deleteDialog.cancel)}</AlertDialogCancel>
            <AlertDialogAction>
              <Button variant="destructive" onClick={() => deleteAudit(audit)}>
                {t(i18nKeys.beeoaudit.siteDetails.deleteDialog.delete)}
              </Button>
            </AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </div>
  )
}

export default AuditsTableRowActions
