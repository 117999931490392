import { Badge } from '@/components/ui/badge'

interface BadgeWithIconProps {
  scoring: number | undefined
  icon: React.ReactNode
}

const BadgeWithIcon = ({ scoring, icon }: BadgeWithIconProps) => {
  const bgColor = getColorByScoring(scoring)
  const score = scoring as number
  return (
    <Badge
      className={`${bgColor} flex w-full flex-row items-end justify-center rounded-full px-4 py-1 text-xs font-medium text-white sm:w-32`}
    >
      {icon}
      {score >= 0 ? `${score.toFixed(0)}%` : '0.00%'}
    </Badge>
  )
}

export default BadgeWithIcon

const getColorByScoring = (scoring: number | undefined) => {
  if (scoring === undefined) return 'bg-green-500 hover:bg-green-600'
  if (scoring <= 30) {
    return 'bg-green-500 hover:bg-green-600'
  } else if (scoring > 30 && scoring < 71) {
    return 'bg-orange-500 hover:bg-orange-600'
  } else if (scoring > 70) {
    return 'bg-red-500 hover:bg-red-600'
  } else {
    return 'bg-gray-500 hover:bg-gray-600'
  }
}
