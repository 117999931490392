/**
 * * `INDUSTRIAL` - Industrial
 * `OFFICE` - Office
 * `AGRICULTURAL` - Agricultural
 * `EXTRACTION` - Extraction
 * `MANUFACTURING` - Manufacturing
 */
export type BuildingTypeEnum = (typeof BuildingTypeEnum)[keyof typeof BuildingTypeEnum]

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const BuildingTypeEnum = {
  INDUSTRIAL: 'INDUSTRIAL',
  OFFICE: 'OFFICE',
  AGRICULTURAL: 'AGRICULTURAL',
  EXTRACTION: 'EXTRACTION',
  MANUFACTURING: 'MANUFACTURING',
} as const
