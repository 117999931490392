import { useMemo } from 'react'
import { PaginatedSiteList } from '@/client/backend/models'
import MonthlySchedule from '@/pages/beeoinitiative/date-planning'
import { format, parseISO } from 'date-fns'

interface PlannedInitiativesProps {
  actionsTakenInitiatives?: PaginatedSiteList
  isLoading?: boolean
}
const PlannedInitiatives = ({ actionsTakenInitiatives, isLoading }: PlannedInitiativesProps) => {
  const currentYear = useMemo(() => new Date().getFullYear(), [])

  const monthsToColorNumber = useMemo(() => {
    if (isLoading || !actionsTakenInitiatives?.results?.features) {
      return []
    }

    return actionsTakenInitiatives.results.features
      .flatMap((site) => site.properties?.actions_taken ?? []) // flatMap to get all actions
      .filter((action) => {
        // filter actions by current year
        if (!action.date_start) return false
        const actionYear = new Date(action.date_start).getFullYear()
        return actionYear === currentYear
      })
      .map((action) => action.date_start) // get all dates
      .filter((date): date is string => !!date) // filter out undefined dates
      .map((date) => {
        // parse dates to month
        const parsedDate = parseISO(date)
        return isNaN(parsedDate.getTime()) ? null : format(parsedDate, 'M')
      })
      .map((month) => parseInt(month ?? '')) // convert months to numbers
  }, [actionsTakenInitiatives, isLoading, currentYear])

  return <MonthlySchedule monthsToColor={monthsToColorNumber} isLoading={isLoading} />
}

export default PlannedInitiatives
