import { SensitiveAreaOut } from '@/client/backend/models'
import { LatLng } from 'leaflet'
import { Polygon, Tooltip } from 'react-leaflet'

import { invertCoordinates } from '@/lib/geo'

interface CardWithMapCddaProps {
  designatedAreas: SensitiveAreaOut[]
  onToggleRow: (value: LatLng[] | LatLng[][]) => void
  setSelectedPolygonId: (value: number) => void
  setSelectedProtectedAreaId: (value: number) => void
  clickRow: number
  setClickRow: (value: number) => void
  selectedPolygonId: number
}

const CardWithMapCdda = ({
  designatedAreas,
  onToggleRow,
  selectedPolygonId,
  setSelectedPolygonId,
  setSelectedProtectedAreaId,
  clickRow,
  setClickRow,
}: CardWithMapCddaProps) => {
  const getNewGeometry = (designatedAreas: SensitiveAreaOut): LatLng[] | LatLng[][] | null => {
    const coordinates = designatedAreas.geom?.['coordinates']
    const polygonType = designatedAreas.geom?.['type'] as string

    if (polygonType === 'Polygon') {
      return invertCoordinates(coordinates?.[0])
    } else if (polygonType === 'MultiPolygon') {
      if (designatedAreas?.geom?.['coordinates']) {
        return designatedAreas?.geom?.['coordinates'].flat(1).map((polygon) => invertCoordinates(polygon))
      }
    }
    return null
  }

  const nationallyDesignatedAreas =
    designatedAreas === null
      ? []
      : designatedAreas.map((designatedArea, idx) => {
          const isSelected = designatedArea.id === selectedPolygonId
          const isClickRowCdda = designatedArea.id === clickRow
          const options = {
            color: isSelected ? 'black' : isClickRowCdda ? 'black' : '',
            fillColor: 'darkblue',
            fillOpacity: 0.5,
            weight: isSelected ? 2 : isClickRowCdda ? 2 : 0,
            opacity: isSelected ? 2 : isClickRowCdda ? 2 : 0,
          }
          const newGeom = getNewGeometry(designatedArea)

          if (!newGeom) {
            return null
          }
          return (
            <Polygon
              key={idx}
              pathOptions={options}
              positions={newGeom}
              pane="markerPane"
              className="outline-none"
              eventHandlers={{
                click: () => {
                  setSelectedPolygonId(isSelected ? 0 : designatedArea.id)
                  onToggleRow(newGeom)
                  setSelectedPolygonId(0)
                  setSelectedProtectedAreaId(isSelected ? 0 : designatedArea.id)
                  setClickRow(isClickRowCdda ? 0 : designatedArea.id)
                },
              }}
            >
              <Tooltip sticky>
                <ul>
                  <li>{designatedArea.name}</li>
                </ul>
              </Tooltip>
            </Polygon>
          )
        })

  return nationallyDesignatedAreas
}

export default CardWithMapCdda
