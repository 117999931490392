import { useMutation, useQuery } from '@tanstack/react-query'
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type { PatchedQuestion, Question } from '../../models'

// https://stackoverflow.com/questions/49579094/typescript-conditional-types-filter-out-readonly-properties-pick-only-requir/49579497#49579497
type IfEquals<X, Y, A = X, B = never> = (<T>() => T extends X ? 1 : 2) extends <T>() => T extends Y ? 1 : 2 ? A : B

type WritableKeys<T> = {
  [P in keyof T]-?: IfEquals<{ [Q in P]: T[P] }, { -readonly [Q in P]: T[P] }, P>
}[keyof T]

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never
type DistributeReadOnlyOverUnions<T> = T extends any ? NonReadonly<T> : never

type Writable<T> = Pick<T, WritableKeys<T>>
type NonReadonly<T> = [T] extends [UnionToIntersection<T>]
  ? {
      [P in keyof Writable<T>]: T[P] extends object ? NonReadonly<NonNullable<T[P]>> : T[P]
    }
  : DistributeReadOnlyOverUnions<T>

export const questionsList = (signal?: AbortSignal) => {
  return backendInstance<Question[]>({ url: `/questions/`, method: 'GET', signal })
}

export const getQuestionsListQueryKey = () => {
  return [`/questions/`] as const
}

export const getQuestionsListQueryOptions = <
  TData = Awaited<ReturnType<typeof questionsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof questionsList>>, TError, TData>>
}) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getQuestionsListQueryKey()

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionsList>>> = ({ signal }) => questionsList(signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionsList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type QuestionsListQueryResult = NonNullable<Awaited<ReturnType<typeof questionsList>>>
export type QuestionsListQueryError = ErrorType<unknown>

export const useQuestionsList = <
  TData = Awaited<ReturnType<typeof questionsList>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof questionsList>>, TError, TData>>
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getQuestionsListQueryOptions(options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const questionsCreate = (question: NonReadonly<Question>) => {
  return backendInstance<Question>({
    url: `/questions/`,
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    data: question,
  })
}

export const getQuestionsCreateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionsCreate>>,
    TError,
    { data: NonReadonly<Question> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionsCreate>>,
  TError,
  { data: NonReadonly<Question> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof questionsCreate>>, { data: NonReadonly<Question> }> = (
    props
  ) => {
    const { data } = props ?? {}

    return questionsCreate(data)
  }

  return { mutationFn, ...mutationOptions }
}

export type QuestionsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof questionsCreate>>>
export type QuestionsCreateMutationBody = NonReadonly<Question>
export type QuestionsCreateMutationError = ErrorType<unknown>

export const useQuestionsCreate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionsCreate>>,
    TError,
    { data: NonReadonly<Question> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof questionsCreate>>,
  TError,
  { data: NonReadonly<Question> },
  TContext
> => {
  const mutationOptions = getQuestionsCreateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const questionsRetrieve = (id: number, signal?: AbortSignal) => {
  return backendInstance<Question>({ url: `/questions/${id}/`, method: 'GET', signal })
}

export const getQuestionsRetrieveQueryKey = (id: number) => {
  return [`/questions/${id}/`] as const
}

export const getQuestionsRetrieveQueryOptions = <
  TData = Awaited<ReturnType<typeof questionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof questionsRetrieve>>, TError, TData>> }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getQuestionsRetrieveQueryKey(id)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof questionsRetrieve>>> = ({ signal }) =>
    questionsRetrieve(id, signal)

  return { queryKey, queryFn, enabled: !!id, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof questionsRetrieve>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type QuestionsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof questionsRetrieve>>>
export type QuestionsRetrieveQueryError = ErrorType<unknown>

export const useQuestionsRetrieve = <
  TData = Awaited<ReturnType<typeof questionsRetrieve>>,
  TError = ErrorType<unknown>,
>(
  id: number,
  options?: { query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof questionsRetrieve>>, TError, TData>> }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getQuestionsRetrieveQueryOptions(id, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}

export const questionsUpdate = (id: number, question: NonReadonly<Question>) => {
  return backendInstance<Question>({
    url: `/questions/${id}/`,
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    data: question,
  })
}

export const getQuestionsUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionsUpdate>>,
    TError,
    { id: number; data: NonReadonly<Question> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionsUpdate>>,
  TError,
  { id: number; data: NonReadonly<Question> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionsUpdate>>,
    { id: number; data: NonReadonly<Question> }
  > = (props) => {
    const { id, data } = props ?? {}

    return questionsUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type QuestionsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof questionsUpdate>>>
export type QuestionsUpdateMutationBody = NonReadonly<Question>
export type QuestionsUpdateMutationError = ErrorType<unknown>

export const useQuestionsUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionsUpdate>>,
    TError,
    { id: number; data: NonReadonly<Question> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof questionsUpdate>>,
  TError,
  { id: number; data: NonReadonly<Question> },
  TContext
> => {
  const mutationOptions = getQuestionsUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const questionsPartialUpdate = (id: number, patchedQuestion: NonReadonly<PatchedQuestion>) => {
  return backendInstance<Question>({
    url: `/questions/${id}/`,
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json' },
    data: patchedQuestion,
  })
}

export const getQuestionsPartialUpdateMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionsPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedQuestion> },
    TContext
  >
}): UseMutationOptions<
  Awaited<ReturnType<typeof questionsPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedQuestion> },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof questionsPartialUpdate>>,
    { id: number; data: NonReadonly<PatchedQuestion> }
  > = (props) => {
    const { id, data } = props ?? {}

    return questionsPartialUpdate(id, data)
  }

  return { mutationFn, ...mutationOptions }
}

export type QuestionsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof questionsPartialUpdate>>>
export type QuestionsPartialUpdateMutationBody = NonReadonly<PatchedQuestion>
export type QuestionsPartialUpdateMutationError = ErrorType<unknown>

export const useQuestionsPartialUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof questionsPartialUpdate>>,
    TError,
    { id: number; data: NonReadonly<PatchedQuestion> },
    TContext
  >
}): UseMutationResult<
  Awaited<ReturnType<typeof questionsPartialUpdate>>,
  TError,
  { id: number; data: NonReadonly<PatchedQuestion> },
  TContext
> => {
  const mutationOptions = getQuestionsPartialUpdateMutationOptions(options)

  return useMutation(mutationOptions)
}
export const questionsDestroy = (id: number) => {
  return backendInstance<void>({ url: `/questions/${id}/`, method: 'DELETE' })
}

export const getQuestionsDestroyMutationOptions = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof questionsDestroy>>, TError, { id: number }, TContext>
}): UseMutationOptions<Awaited<ReturnType<typeof questionsDestroy>>, TError, { id: number }, TContext> => {
  const { mutation: mutationOptions } = options ?? {}

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof questionsDestroy>>, { id: number }> = (props) => {
    const { id } = props ?? {}

    return questionsDestroy(id)
  }

  return { mutationFn, ...mutationOptions }
}

export type QuestionsDestroyMutationResult = NonNullable<Awaited<ReturnType<typeof questionsDestroy>>>

export type QuestionsDestroyMutationError = ErrorType<unknown>

export const useQuestionsDestroy = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof questionsDestroy>>, TError, { id: number }, TContext>
}): UseMutationResult<Awaited<ReturnType<typeof questionsDestroy>>, TError, { id: number }, TContext> => {
  const mutationOptions = getQuestionsDestroyMutationOptions(options)

  return useMutation(mutationOptions)
}
