import { useEffect, useRef, useState } from 'react'
import { Site } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import L, { Map, MarkerOptions } from 'leaflet'
import { useTranslation } from 'react-i18next'
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-cluster'
import { useNavigate } from 'react-router-dom'

import { DEFAULT_CENTER, getSiteCenter } from '@/lib/geo'
import { getMapIcon, hoverSiteIcon } from '@/lib/map'
import { Skeleton } from '@/components/ui/skeleton'
import MapPopup from '@/components/beeoinitiative/map-popup'

interface SitesMapProps {
  sites: Site[]
  hoveredCard: number | null
  hoveredCardRef: React.MutableRefObject<number | null>
  isLoading: boolean
}
interface CustomMarkerOptions extends MarkerOptions {
  id?: number
}

const SitesMap = ({ sites, hoveredCard, hoveredCardRef, isLoading }: SitesMapProps) => {
  const { t } = useTranslation()
  const mapRef = useRef<Map>(null)
  const markerRefs = useRef({})
  const navigate = useNavigate()
  const worldBounds = new L.LatLngBounds(new L.LatLng(-88, -180), new L.LatLng(88, 180))
  const [, setHoveredCluster] = useState<number | null>(null)

  const openPopup = (id) => {
    if (markerRefs.current[id]) {
      markerRefs.current[id].openPopup()
    }
  }

  const closePopup = (id) => {
    if (markerRefs.current[id]) {
      markerRefs.current[id].closePopup()
    }
  }

  useEffect(() => {
    if (hoveredCard) {
      openPopup(hoveredCard)
    } else {
      closePopup(hoveredCardRef.current)
    }
  }, [hoveredCard])

  useEffect(() => {
    hoveredCardRef.current = hoveredCard
    setHoveredCluster(hoveredCard)
  }, [hoveredCard])

  const createClusterIcon = (cluster) => {
    const count = cluster.getChildCount()
    const clusterMarkers = cluster.getAllChildMarkers()

    const clusterIds = clusterMarkers.map((marker) => (marker.options as CustomMarkerOptions).id)

    const isHovered = clusterIds.includes(hoveredCardRef.current)

    return L.divIcon({
      html: `<div class="custom-cluster flex items-center justify-center w-9 h-9 text-sm ${
        isHovered ? 'bg-teal-100 border-2' : 'bg-white'
      } border border-biodivTealSevenHundred text-biodivTealSevenHundred rounded-full">
          ${count}
        </div>`,
      className: 'custom-cluster-icon',
      iconSize: L.point(40, 40, true),
    })
  }
  if (isLoading) {
    return <Skeleton className="h-[calc(100vh-100px)] md:w-0 lg:w-[400px] xl:w-[504.1px] 2xl:w-[504.1px]" />
  }
  return (
    <MapContainer
      className="z-0 size-full"
      center={DEFAULT_CENTER}
      zoom={6}
      dragging={true}
      scrollWheelZoom={false}
      ref={mapRef}
      maxBounds={worldBounds}
      maxBoundsViscosity={1}
    >
      <TileLayer key="tile" url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

      <MarkerClusterGroup
        spiderfyOnMaxZoom={true}
        zoomToBoundsOnClick={true}
        showCoverageOnHover={false}
        maxClusterRadius={50}
        iconCreateFunction={createClusterIcon}
      >
        {sites?.map((site) => {
          const isHovered = hoveredCard === site.id
          return (
            <Marker
              icon={isHovered ? hoverSiteIcon('pin') : getMapIcon('newDot')}
              key={site.id}
              position={getSiteCenter(site)}
              eventHandlers={{
                mouseover: (event) => event.target.openPopup(),
                mouseout: (event) => event.target.closePopup(),
                click: () => {
                  navigate(`/beeo-initiative/sites/${site.id}`)
                },
              }}
              ref={(ref) => {
                if (ref) {
                  markerRefs.current[site.id] = ref
                  return ((ref.options as CustomMarkerOptions).id = site.id)
                }
              }}
            >
              <Popup className="rounded-xl border border-biodivTealSevenHundred">
                <MapPopup
                  siteName={site.properties?.name ?? ''}
                  title={t(i18nKeys.beeoinitiative.sites.initiatives)}
                  numberOfInits={`${site?.properties?.actions_taken?.filter((action) => action?.status === 'DONE').length ?? 0} / ${site?.properties?.actions_taken?.length ?? 0}`}
                  progressBarValue={
                    site?.properties?.actions_taken && site?.properties?.actions_taken.length > 0
                      ? (site.properties.actions_taken.filter((action) => action?.status === 'DONE').length /
                          site.properties.actions_taken.length) *
                        100
                      : 0
                  }
                />
              </Popup>
            </Marker>
          )
        })}
      </MarkerClusterGroup>
    </MapContainer>
  )
}

export default SitesMap
