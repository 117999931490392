import { Link } from 'react-router-dom'

import { Card } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'

interface NoInitiativesCardProps {
  title: string
  message: React.ReactNode
  button?: React.ReactNode
  buttonLink?: string
  isLoading: boolean
}

const NoInitiativesCard = ({ title, message, button, buttonLink, isLoading }: NoInitiativesCardProps) => {
  if (isLoading) {
    return <Skeleton className="min-h-[435px] w-full" />
  }
  return (
    <Card className="flex min-h-[435px] w-full flex-col justify-start space-y-36 px-8 py-9 2xl:px-10">
      <div>
        <div className="flex space-x-2 pb-6 text-xl font-semibold">
          <p>{title}</p>
        </div>
        <Separator className="bg-separatorGray" />
      </div>

      <div className="flex flex-col items-center space-y-8">
        <p className="text-xl">{message}</p>
        {button && <div>{buttonLink ? <Link to={buttonLink}>{button}</Link> : button}</div>}
      </div>
    </Card>
  )
}

export default NoInitiativesCard
