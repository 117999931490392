export enum SpeciesType {
  Mammals = 'ecoscore_mammals',
  Birds = 'ecoscore_birds',
  Worms = 'ecoscore_worms',
  Bees = 'ecoscore_bees',
  Fishes = 'ecoscore_fishes',
  Soil = 'ecoscore_soil',
  Plant = 'ecoscore_plant',
  Squirrel = 'ecoscore_squirrel',
  Water = 'ecoscore_water',
}
