import { useQuery } from '@tanstack/react-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryResult } from '@tanstack/react-query'

import { backendInstance } from '../../backend-instance'
import type { ErrorType } from '../../backend-instance'
import type {
  BeeorefTotalSubstanceByRegionList400,
  BeeorefTotalSubstanceByRegionListParams,
  TotalSubstanceByRegion,
} from '../../models'

/**
 * Get total substances by region
 */
export const beeorefTotalSubstanceByRegionList = (
  params?: BeeorefTotalSubstanceByRegionListParams,
  signal?: AbortSignal
) => {
  return backendInstance<TotalSubstanceByRegion[]>({
    url: `/beeoref/total_substance_by_region`,
    method: 'GET',
    params,
    signal,
  })
}

export const getBeeorefTotalSubstanceByRegionListQueryKey = (params?: BeeorefTotalSubstanceByRegionListParams) => {
  return [`/beeoref/total_substance_by_region`, ...(params ? [params] : [])] as const
}

export const getBeeorefTotalSubstanceByRegionListQueryOptions = <
  TData = Awaited<ReturnType<typeof beeorefTotalSubstanceByRegionList>>,
  TError = ErrorType<BeeorefTotalSubstanceByRegionList400>,
>(
  params?: BeeorefTotalSubstanceByRegionListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof beeorefTotalSubstanceByRegionList>>, TError, TData>>
  }
) => {
  const { query: queryOptions } = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getBeeorefTotalSubstanceByRegionListQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof beeorefTotalSubstanceByRegionList>>> = ({ signal }) =>
    beeorefTotalSubstanceByRegionList(params, signal)

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof beeorefTotalSubstanceByRegionList>>,
    TError,
    TData
  > & { queryKey: QueryKey }
}

export type BeeorefTotalSubstanceByRegionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof beeorefTotalSubstanceByRegionList>>
>
export type BeeorefTotalSubstanceByRegionListQueryError = ErrorType<BeeorefTotalSubstanceByRegionList400>

export const useBeeorefTotalSubstanceByRegionList = <
  TData = Awaited<ReturnType<typeof beeorefTotalSubstanceByRegionList>>,
  TError = ErrorType<BeeorefTotalSubstanceByRegionList400>,
>(
  params?: BeeorefTotalSubstanceByRegionListParams,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof beeorefTotalSubstanceByRegionList>>, TError, TData>>
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getBeeorefTotalSubstanceByRegionListQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey }

  query.queryKey = queryOptions.queryKey

  return query
}
