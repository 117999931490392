import { useEffect } from 'react'
import { i18nKeys } from '@/locales/keys'
import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Link, useNavigate } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { LoginSchema, LoginSchemaForm } from '@/lib/validation/login-schema'
import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { Icons } from '@/components/icons'

const LoginPage = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { signin, user } = useAuth()

  // if we are already logged in
  useEffect(() => {
    if (user) {
      if (hasAccesTo(user.features, FeatureType.PESTICIDES)) {
        navigate(getPath(Routes.BEEOIMPACT_DASHBOARD), { replace: true })
      } else if (hasAccesTo(user.features, FeatureType.BIODIVERSITY)) {
        navigate(getPath(Routes.BEEOIMPACT_DASHBOARD), { replace: true })
      } else if (hasAccesTo(user.features, FeatureType.POTENTIAL)) {
        navigate(getPath(Routes.BEEOIMPACT_DASHBOARD), { replace: true })
      } else if (hasAccesTo(user.features, FeatureType.INITIATIVE)) {
        navigate(getPath(Routes.BEEOINITIATIVE_DASHBOARD), { replace: true })
      } else if (hasAccesTo(user.features, FeatureType.MONITORING)) {
        navigate(getPath(Routes.BEEOMONITORING_SITES), { replace: true })
      }
    }
  }, [user])

  const form = useForm<LoginSchemaForm>({
    resolver: zodResolver(LoginSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const submit = async ({ email, password }: LoginSchemaForm) => {
    await signin(email, password)
  }

  return (
    <Form {...form}>
      <form className="space-y-6" onSubmit={form.handleSubmit(submit)}>
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.beeoimpact.common.connection.email)}</FormLabel>
              <FormControl>
                <Input type="email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <div className="flex  items-center justify-between">
                <FormLabel> {t(i18nKeys.beeoimpact.common.connection.password)}</FormLabel>
                <div className="group">
                  <Link
                    to={getPath(Routes.AUTH_FORGOT_PASSWORD)}
                    className="text-xs text-primary underline-offset-4 group-hover:underline"
                  >
                    {t(i18nKeys.beeoimpact.common.connection.forgotPassword)}
                  </Link>
                </div>
              </div>

              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <div>
          {form.formState.isSubmitting ? (
            <Button disabled className="w-full">
              <Icons.Loader2 className="mr-2 size-4 animate-spin" />
              {t(i18nKeys.beeoimpact.common.connection.ongoingConnection)}
            </Button>
          ) : (
            <Button type="submit" className="w-full">
              {t(i18nKeys.beeoimpact.common.connection.connection)}
            </Button>
          )}
        </div>
      </form>
    </Form>
  )
}

export default LoginPage
