import { createContext, useEffect, useState } from 'react'
import { authJwtCreateCreate } from '@/client/backend/api/auth/auth'
import { usersMeRetrieve, usersTokenRetrieve } from '@/client/backend/api/users/users'
import { AXIOS_INSTANCE } from '@/client/backend/backend-instance'
import { User } from '@/client/backend/models/user'

import { AvailableLanguage } from '@/types/available-language'
import { QUERY_CLIENT } from '@/lib/config/react-query'
import { HTTP_CODE } from '@/lib/constants/http-code'
import { STORAGE_KEYS } from '@/lib/constants/local-storage'
import { setHeaders } from '@/lib/utils'
import { toast } from '@/components/ui/use-toast'

type AuthContextType = {
  user: User | null
  isLogged: boolean
  token: string | null
  isImpersonate: boolean
  signin: (email: string, password: string) => Promise<User | null | void>
  signout: () => void
  impersonate: (id: number) => Promise<User | void>
  stopImpersonate: () => void
}

export const AuthContext = createContext<AuthContextType>({
  token: null,
  user: null,
  isLogged: false,
  signin: () => Promise.resolve({} as User),
  signout: () => {},
  impersonate: () => Promise.resolve({} as User),
  stopImpersonate: () => null,
  isImpersonate: false,
})

export const AuthProvider = ({ children }) => {
  const auth = useAuthProvider()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

const useAuthProvider = () => {
  const localToken =
    localStorage.getItem(STORAGE_KEYS.TOKEN_IMPERSONATE) ?? localStorage.getItem(STORAGE_KEYS.TOKEN) ?? null
  const localLanguage =
    (localStorage.getItem(STORAGE_KEYS.LAST_SELECTED_LANGUAGE) as AvailableLanguage) ?? AvailableLanguage.EN

  const [token, setToken] = useState<string | null>(localToken)
  const [isLogged, setLogged] = useState(!!localToken)
  const [user, setUser] = useState<User | null>(null)
  const [isImpersonate, setIsImpersonate] = useState(false)

  useEffect(() => {
    const fetchToken = async () => {
      if (token) {
        setHeaders(token, localLanguage)

        const responseInterceptorId = AXIOS_INSTANCE.interceptors.response.use(
          (response) => {
            return response
          },
          (error) => {
            const { response } = error

            if (response?.status === HTTP_CODE.FORBIDDEN) {
              toast({
                title: 'Uh oh! Something went wrong.',
                description: 'You do not have the permission for this action.',
              })
              return
            }

            if (response?.status === HTTP_CODE.UNAUTHORIZED) {
              const tokenErrorMessages = ['Given token not valid for any token type', 'Token is invalid or expired']
              if (tokenErrorMessages.includes(response.data?.detail)) {
                toast({
                  title: 'Uh oh! Something went wrong.',
                  description: 'Your session has expired. Please log in again.',
                })
                signout()
                return
              }

              return
            }

            if (response?.data) {
              if (response.data?.current_password) {
                toast({
                  variant: 'destructive',
                  title: 'current password',
                  description: response.data.current_password,
                })
              }
              if (response.data?.new_password) {
                toast({
                  variant: 'destructive',
                  title: response.data.new_password,
                })
              }
              return Promise.reject(response.data)
            }
            return Promise.reject(error)
          }
        )
        try {
          const currentUser = await usersMeRetrieve()
          setUser(currentUser)
        } catch (error) {
          signout()
        }

        return () => {
          AXIOS_INSTANCE.interceptors.response.eject(responseInterceptorId)
        }
      }
    }
    fetchToken()
  }, [token])

  const signin = async (email: string, password: string) => {
    return await authJwtCreateCreate({ email, password })
      .then((response) => {
        localStorage.setItem(STORAGE_KEYS.TOKEN, response.access)
        setToken(response.access)
        setLogged(true)
        return user
      })
      .catch(() => {
        setLogged(false)
        toast({
          variant: 'destructive',
          title: 'Wrong credentials',
          description: 'Please check your email and password',
        })
      })
  }

  const signout = () => {
    setUser(null)
    setLogged(false)
    localStorage.clear()
    QUERY_CLIENT.invalidateQueries()
    delete AXIOS_INSTANCE.defaults.headers.common['Authorization']
  }

  const impersonate = async (id: number) => {
    return await usersTokenRetrieve(id)
      .then((response) => {
        localStorage.setItem(STORAGE_KEYS.TOKEN_IMPERSONATE, response.access_token)
        setToken(response.access_token)
        setLogged(true)
        setIsImpersonate(true)
        return response.user
      })
      .catch((error) => {
        setLogged(false)
        toast({
          variant: 'destructive',
          title: 'Uh oh! Something went wrong.',
          description: error.response,
        })
      })
  }

  const stopImpersonate = () => {
    localStorage.removeItem(STORAGE_KEYS.TOKEN_IMPERSONATE)
    const access_token = localStorage.getItem(STORAGE_KEYS.TOKEN) ?? ''
    setToken(access_token)
    setIsImpersonate(false)
  }

  return { user, isLogged, signin, signout, impersonate, stopImpersonate, token, isImpersonate }
}
