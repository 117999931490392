import { Account, AccountStats, PaginatedSiteList } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { ResponsivePie } from '@nivo/pie'
import { BadgeCheck, BadgeMinus, MapPin, Squirrel } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { buttonVariants } from '@/components/ui/button'
import { Card, CardContent } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import CardAnalytics from '@/components/card-analytics'
import NoSitesMessage from '@/components/no-sites-message'
import CenteredLayer from '@/components/pie/centered-layer'
import SitesTable from '@/components/sites-table'
import { H3, H4 } from '@/components/typography'

interface DashboardBiodiversityProps {
  sites?: PaginatedSiteList
  stats?: AccountStats
  account?: Account
  isLoadingStats: boolean
  isLoadingAccount: boolean
  isLoadingSites: boolean
}

const DashboardBiodiversity = ({
  sites,
  account,
  stats,
  isLoadingStats,
  isLoadingAccount,
  isLoadingSites,
}: DashboardBiodiversityProps) => {
  const { t } = useTranslation()

  const {
    nb_sites = 0,
    nb_sites_with_in_sensitive_area = 0,
    nb_sites_with_adjacent_sensitive_area = 0,
    nb_sites_with_out_sensitive_area = 0,
    nb_species_in = { CR: 0, EN: 0, VU: 0, NT: 0, LC: 0 },
    nb_species_adjacent = { CR: 0, EN: 0, VU: 0, NT: 0, LC: 0 },
  } = stats || {}

  const {
    CR: speciesInCR = 0,
    EN: speciesInEN = 0,
    VU: speciesInVU = 0,
    NT: speciesInNT = 0,
    LC: speciesInLC = 0,
  } = nb_species_in

  const {
    CR: speciesAdjacentCR = 0,
    EN: speciesAdjacentEN = 0,
    VU: speciesAdjacentVU = 0,
    NT: speciesAdjacentNT = 0,
    LC: speciesAdjacentLC = 0,
  } = nb_species_adjacent

  const totalIUCNRedListCR = speciesInCR + speciesAdjacentCR
  const totalIUCNRedListEN = speciesInEN + speciesAdjacentEN
  const totalIUCNRedListVU = speciesInVU + speciesAdjacentVU
  const totalIUCNRedListNT = speciesInNT + speciesAdjacentNT
  const totalIUCNRedListLC = speciesInLC + speciesAdjacentLC
  const totalIUCNRedList = totalIUCNRedListCR + totalIUCNRedListEN + totalIUCNRedListVU

  const notAdjacentPercentage = nb_sites ? (nb_sites_with_out_sensitive_area / nb_sites) * 100 : 0
  const adjacentPercentage = nb_sites ? (nb_sites_with_adjacent_sensitive_area / nb_sites) * 100 : 0
  const inPercentage = nb_sites ? (nb_sites_with_in_sensitive_area / nb_sites) * 100 : 0
  const percentageAdjacentIn = adjacentPercentage + inPercentage
  const totalAdjacentIn = nb_sites_with_adjacent_sensitive_area + nb_sites_with_in_sensitive_area

  const dataPieChart = [
    {
      id: 'in',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.in),
      value: nb_sites_with_in_sensitive_area,
    },
    {
      id: 'not',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.notAdjacent),
      value: nb_sites_with_out_sensitive_area,
    },
    {
      id: 'adjacent',
      label: t(i18nKeys.beeoimpact.dashboard.metrics.pie.adjacent),
      value: nb_sites_with_adjacent_sensitive_area,
    },
  ]

  if (nb_sites === 0 && !isLoadingStats) {
    return <NoSitesMessage />
  }

  return (
    <section>
      <div className="mb-8">
        <div className="mb-4 flex flex-row">
          {isLoadingAccount ? (
            <Skeleton className="mr-2 h-60 w-[880px]" />
          ) : (
            <Card className="mr-4 basis-2/3 border-none bg-gray-300/20">
              <CardContent>
                <div className="flex items-start">
                  <div className="mr-8 h-44 w-1/5">
                    <ResponsivePie
                      data={dataPieChart}
                      margin={{ top: 10, right: 20, bottom: 10, left: 10 }}
                      innerRadius={0.5}
                      padAngle={0.7}
                      cornerRadius={3}
                      activeOuterRadiusOffset={8}
                      enableArcLinkLabels={false}
                      enableArcLabels={false}
                      borderColor={{
                        from: 'color',
                        modifiers: [['darker', 0.2]],
                      }}
                      colors={['#f87171', '#86efac', '#fdba74']}
                      layers={[
                        'arcs',
                        'arcLabels',
                        'arcLinkLabels',
                        'legends',
                        CenteredLayer(`${nb_sites_with_in_sensitive_area}`),
                      ]}
                    />
                  </div>
                  <div className="w-3/5">
                    <H4 className="mb-6 mt-4">{t(i18nKeys.beeoimpact.dashboard.metrics.card.in.title)}</H4>
                    <p className="text-muted-foreground">
                      <Trans
                        i18nKey={i18nKeys.beeoimpact.dashboard.metrics.in}
                        values={{
                          adjacent: nb_sites_with_in_sensitive_area,
                          adjacentPercentage: inPercentage.toFixed(2),
                        }}
                        components={{ bold: <span className="font-bold" /> }}
                      />
                    </p>
                    <Link
                      to={getPath(Routes.BEEOIMPACT_SITES)}
                      className={cn(buttonVariants({ variant: 'default', size: 'lg' }), 'mt-6')}
                    >
                      {t(i18nKeys.beeoimpact.dashboard.title.viewSites)}
                    </Link>
                  </div>
                </div>
              </CardContent>
            </Card>
          )}
          <div className="basis-1/3">
            <CardAnalytics
              label={t(i18nKeys.beeoimpact.dashboard.metrics.card.adjancentAndIn.title)}
              description={t(i18nKeys.beeoimpact.dashboard.metrics.card.adjancentAndIn.description)}
              value={percentageAdjacentIn.toFixed(0)}
              unit="%"
              total={totalAdjacentIn}
              icon={<BadgeMinus className="mt-px size-5 text-purple-600" />}
              iconBgColor="bg-purple-200"
              isLoading={isLoadingAccount}
            />
          </div>
        </div>
        <div>
          <H3>{t(i18nKeys.beeoimpact.dashboard.metrics.title)}</H3>
          <p className="pb-4 text-sm text-muted-foreground">
            {isLoadingAccount ? (
              <Skeleton className="h-5 w-48" />
            ) : (
              t(i18nKeys.beeoimpact.dashboard.metrics.description, { count: parseInt(account?.nb_sites ?? '0') })
            )}
          </p>
        </div>

        <Separator />
      </div>
      <div className="mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-4">
        <CardAnalytics
          label={t(i18nKeys.beeoimpact.dashboard.pesticides.sites.title)}
          value={account?.nb_sites || 0}
          icon={<MapPin className="mt-px size-5 text-gray-600" />}
          iconBgColor="bg-gray-200"
          isLoading={isLoadingAccount}
        />

        <CardAnalytics
          label={t(i18nKeys.beeoimpact.dashboard.metrics.card.notAdjacent.title)}
          description={
            <Trans
              i18nKey={i18nKeys.beeoimpact.dashboard.metrics.notAdjacent}
              values={{
                notAdjacent: nb_sites_with_out_sensitive_area,
                notAdjacentPercentage: notAdjacentPercentage.toFixed(2),
              }}
              components={{ bold: <span className="font-bold" /> }}
            />
          }
          value={notAdjacentPercentage.toFixed(0)}
          unit="%"
          total={nb_sites_with_out_sensitive_area}
          icon={<BadgeCheck className="mt-px size-5 text-green-600" />}
          iconBgColor="bg-green-200"
          isLoading={isLoadingAccount}
        />

        <CardAnalytics
          label={t(i18nKeys.beeoimpact.dashboard.metrics.card.adjacent.title)}
          description={
            <Trans
              i18nKey={i18nKeys.beeoimpact.dashboard.metrics.adjacent}
              values={{
                adjacent: nb_sites_with_adjacent_sensitive_area,
                adjacentPercentage: adjacentPercentage.toFixed(2),
              }}
              components={{ bold: <span className="font-bold" /> }}
            />
          }
          value={adjacentPercentage.toFixed(0)}
          unit="%"
          total={nb_sites_with_adjacent_sensitive_area}
          icon={<BadgeMinus className="mt-px size-5 text-orange-600" />}
          iconBgColor="bg-orange-200"
          isLoading={isLoadingAccount}
        />

        <CardAnalytics
          label={t(i18nKeys.beeoimpact.dashboard.metrics.card.in.title)}
          description={
            <Trans
              i18nKey={i18nKeys.beeoimpact.dashboard.metrics.in}
              values={{
                adjacent: nb_sites_with_in_sensitive_area,
                adjacentPercentage: inPercentage.toFixed(2),
              }}
              components={{ bold: <span className="font-bold" /> }}
            />
          }
          value={inPercentage.toFixed(0)}
          unit="%"
          total={nb_sites_with_in_sensitive_area}
          icon={<BadgeMinus className="mt-px size-5 text-red-600" />}
          iconBgColor="bg-red-200"
          isLoading={isLoadingAccount}
        />
      </div>

      <div className="my-8">
        <div className="mb-8">
          <H3>{t(i18nKeys.beeoimpact.dashboard.metrics.titleThreat)}</H3>
          <p className="pb-4 text-sm text-muted-foreground">
            {isLoadingStats ? (
              <Skeleton className="h-5 w-40" />
            ) : (
              <Trans
                i18nKey={i18nKeys.beeoimpact.dashboard.metrics.totalIUCNRedList}
                values={{
                  totalIUCNRedList: totalIUCNRedList,
                }}
                components={{ bold: <span className="font-bold" /> }}
              />
            )}
          </p>
          <Separator />
        </div>
        <div className="mb-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3">
          <CardAnalytics
            label={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.title)}
            description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.description)}
            value={totalIUCNRedList}
            icon={<Squirrel className="mt-px size-5 text-gray-600" />}
            iconBgColor="bg-gray-200"
            isLoading={isLoadingStats}
          />

          <CardAnalytics
            label={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.CR.title)}
            description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.CR.description)}
            value={totalIUCNRedListCR}
            icon={<Squirrel className="mt-px size-5 text-red-600" />}
            iconBgColor="bg-red-200"
            isLoading={isLoadingStats}
          />

          <CardAnalytics
            label={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.EN.title)}
            description={t(i18nKeys.beeoimpact.dashboard.metrics.card.species.EN.description)}
            value={totalIUCNRedListEN}
            icon={<Squirrel className="mt-px size-5 text-orange-600" />}
            iconBgColor="bg-orange-200"
            isLoading={isLoadingStats}
          />
        </div>
        <div className="flex flex-col items-center justify-center lg:flex-row lg:space-x-8 lg:space-y-0">
          {isLoadingStats ? (
            <Skeleton className="h-[214px] w-full" />
          ) : (
            <Card className="my-8 flex w-full flex-col">
              <CardContent>
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead></TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.CR)}</TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.EN)}</TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.VU)}</TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.NT)}</TableHead>
                      <TableHead>{t(i18nKeys.beeoimpact.dashboard.groups.LC)}</TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody>
                    <TableRow>
                      <TableCell className="font-medium">
                        {t(i18nKeys.beeoimpact.dashboard.metrics.pie.adjacent)}
                      </TableCell>
                      <TableCell className="font-medium">{speciesAdjacentCR}</TableCell>
                      <TableCell>{speciesAdjacentEN}</TableCell>
                      <TableCell>{speciesAdjacentVU}</TableCell>
                      <TableCell>{speciesAdjacentNT}</TableCell>
                      <TableCell>{speciesAdjacentLC}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">{t(i18nKeys.beeoimpact.dashboard.metrics.pie.in)}</TableCell>
                      <TableCell className="font-medium">{speciesInCR}</TableCell>
                      <TableCell>{speciesInEN}</TableCell>
                      <TableCell>{speciesInVU}</TableCell>
                      <TableCell>{speciesInNT}</TableCell>
                      <TableCell>{speciesInLC}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="font-medium">Total</TableCell>
                      <TableCell className="text-lg font-bold">{totalIUCNRedListCR}</TableCell>
                      <TableCell className="text-lg font-bold">{totalIUCNRedListEN}</TableCell>
                      <TableCell className="text-lg font-bold">{totalIUCNRedListVU}</TableCell>
                      <TableCell className="text-lg font-bold">{totalIUCNRedListNT}</TableCell>
                      <TableCell className="text-lg font-bold">{totalIUCNRedListLC}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </CardContent>
            </Card>
          )}
        </div>
      </div>

      <div className="my-8">
        <div className="my-8">
          <H3>{t(i18nKeys.beeoimpact.dashboard.metrics.sites)}</H3>
          <p className="pb-4 text-sm text-muted-foreground">
            {isLoadingAccount ? (
              <Skeleton className="h-5 w-44" />
            ) : (
              t(i18nKeys.beeoimpact.dashboard.metrics.description, { count: parseInt(account?.nb_sites ?? '0') })
            )}
          </p>
          <Separator />
        </div>
        <Card className="mt-8 w-full min-w-0">
          <CardContent className="my-8">
            {isLoadingSites ? <Skeleton className="h-[534px] w-full" /> : <SitesTable data={sites} />}
          </CardContent>
        </Card>
      </div>
    </section>
  )
}

export default DashboardBiodiversity
