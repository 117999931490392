import { Badge } from '@/components/ui/badge'

interface SiteCategoryBadgeProps {
  category: string
}

const SiteCategoryBadge = ({ category }: SiteCategoryBadgeProps) => {
  return (
    <Badge className="h-8 w-max border-biodivLimeFiveHundred bg-lime-50 font-semibold text-biodivTealSevenHundred hover:bg-lime-200">
      {category}
    </Badge>
  )
}

export default SiteCategoryBadge
