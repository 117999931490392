import * as jPalette from 'jpalette'

import { rgbToHex } from '@/lib/colors'

interface Colors {
  [key: string]: string
}

export const buildColors = (parcels: string[]): Colors => {
  const colorMap = new jPalette.ColorMap(19, [
    new jPalette.Color(255, 0, 0, 255),
    new jPalette.Color(0, 255, 0, 255),
    new jPalette.Color(0, 0, 255, 255),
    new jPalette.Color(255, 255, 0, 255),
    new jPalette.Color(255, 0, 255, 255),
    new jPalette.Color(0, 255, 255, 255),
    new jPalette.Color(255, 255, 255, 255),
    new jPalette.Color(255, 0, 0, 255),
    new jPalette.Color(0, 255, 0, 255),
    new jPalette.Color(0, 0, 255, 255),
  ])

  const generateColorKey = (index: number) => index * 0.05

  const getColorForParcel = (parcel: string, index: number) => {
    const colorKey = generateColorKey(index)
    return { [parcel]: rgbToHex(colorMap.getColor(colorKey)) }
  }

  const colors: Colors = parcels.reduce((acc, parcel, index) => ({ ...acc, ...getColorForParcel(parcel, index) }), {})

  return colors
}
