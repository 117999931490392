import { createContext, useState } from 'react'
import { ActionTaken } from '@/client/backend/models'

export interface BeeoinitiativeContextType {
  newActionTaken: ActionTaken | null
  saveNewActionTaken: (e: ActionTaken) => void
  resetNewActionTaken: () => void
}

export const BeeoinitiativeContext = createContext<BeeoinitiativeContextType | null>(null)

const useBeeoinitiativeProvider = () => {
  const [newActionTaken, setNewActionTaken] = useState<ActionTaken | null>(null)

  const saveNewActionTaken = (e: ActionTaken) => {
    setNewActionTaken(e)
  }

  const resetNewActionTaken = () => {
    setNewActionTaken(null)
  }

  return { newActionTaken, saveNewActionTaken, resetNewActionTaken }
}

export const BeeoinitiativeProvider = ({ children }) => {
  const beeoinitiative = useBeeoinitiativeProvider()
  return <BeeoinitiativeContext.Provider value={beeoinitiative}>{children}</BeeoinitiativeContext.Provider>
}
