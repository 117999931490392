import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'

const CardLongListSkeleton = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle>
          <Skeleton className="h-6 w-32" />
        </CardTitle>

        <Skeleton className="h-4 w-48" />
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          {Array.from({ length: 10 }).map((_, index) => (
            <li key={index}>
              <Skeleton className="h-4 w-full" />
            </li>
          ))}
        </ul>
      </CardContent>
    </Card>
  )
}

export default CardLongListSkeleton
