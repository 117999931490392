import accountSitesLoader from '@/pages/admin/sites.loader'
import { useLoaderData } from 'react-router-dom'

import { Separator } from '@/components/ui/separator'
import SettingsSitesTable from '@/components/settings-sites-table'

const AdminSites = () => {
  const { sites, account } = useLoaderData() as Awaited<ReturnType<typeof accountSitesLoader>>

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-lg font-medium"> {account.name}'s sites</h3>
        <p className="text-sm text-muted-foreground">You will find the {account.name} sites</p>
      </div>
      <Separator />
      <SettingsSitesTable data={sites?.features ?? []} accountId={account.id} />
    </div>
  )
}

export default AdminSites
