import { BarDatum, ResponsiveBar } from '@nivo/bar'

import { cn } from '@/lib/utils'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

type CardBarChartStackedProps = {
  size?: string
  title?: string
  subtitle?: string
  legendY: string
  data: Record<string, number>[]
  colors: string[]
}

const CardBarChartStacked = ({ size, title, subtitle, data, colors, legendY }: CardBarChartStackedProps) => {
  const maxValue = Math.max(...data.map((item) => Object.values(item).reduce((acc, value) => acc + value, 0))) * 1.25
  const dataObject = data[0]

  // for the legends we remove the object "data"
  delete dataObject['data']

  return (
    <Card className={size}>
      <CardHeader>
        <CardTitle className={cn('flex items-center text-foreground')}>{title}</CardTitle>
        <CardDescription>{subtitle}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mx-auto flex h-[300px] w-full items-center justify-center">
          <ResponsiveBar
            data={data as unknown as BarDatum[]}
            colors={colors}
            keys={['0', '1', '2', '3-5', '>5']}
            indexBy={'data'}
            labelTextColor="#FFFFFF"
            padding={0.3}
            maxValue={maxValue}
            innerPadding={0}
            margin={{ top: 5, right: 20, bottom: 10, left: 60 }}
            layout="vertical"
            enableLabel={true}
            valueFormat={(value) => {
              return `${value.toFixed(3)} `
            }}
            enableGridY={true}
            enableGridX={false}
            groupMode="stacked"
            axisLeft={{
              tickPadding: 10,
              tickSize: 0,
              tickRotation: 0,
              legend: legendY,
              legendOffset: -55,
              legendPosition: 'middle',
            }}
            axisBottom={null}
            tooltip={({ id, value, color }) => (
              <div className="rounded-md bg-muted p-2 shadow-md">
                <div className="flex items-center justify-evenly">
                  <div className={cn('mr-2 size-2 rounded-full')} style={{ backgroundColor: color }} />
                  <span>{id}</span>
                  <span className="ml-5 text-muted-foreground">{value.toFixed(2)}</span>
                </div>
              </div>
            )}
          />
        </div>
        <div className="flex min-h-[50px] flex-wrap justify-center">
          {Object.entries(dataObject).map(([key], index) => {
            return (
              <div key={index} className="mr-4 flex items-center text-xs text-muted-foreground">
                <div
                  className={cn(' mr-2 size-3 rounded-full')}
                  style={{ backgroundColor: colors[index % colors.length] }}
                />
                <span>{key}</span>
              </div>
            )
          })}
        </div>
      </CardContent>
    </Card>
  )
}

export default CardBarChartStacked
