import { usersResetPasswordConfirmCreate } from '@/client/backend/api/users/users'
import { i18nKeys } from '@/locales/keys'
import Page404 from '@/pages/errors/404'
import { zodResolver } from '@hookform/resolvers/zod'
import { isAxiosError } from 'axios'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import { goToLoginPage } from '@/lib/utils'
import { ResetPasswordFormValues, ResetPasswordSchema } from '@/lib/validation/reset-password-schema'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { toast } from '@/components/ui/use-toast'

const ResetPasswordPage = () => {
  const { id, token } = useParams()
  const { t } = useTranslation()

  const form = useForm<ResetPasswordFormValues>({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues: {
      password: '',
    },
    shouldUnregister: false,
    mode: 'onChange',
  })

  if (!id || !token) {
    return <Page404 />
  }

  const submit = async (data: ResetPasswordFormValues) => {
    try {
      await usersResetPasswordConfirmCreate({ uid: id, token: token, new_password: data.password })

      toast({
        title: t(i18nKeys.global.auth.resetpassword.success),
      })
      goToLoginPage()
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.auth.resetpassword.error),
          description: error.response.data.new_password[0],
        })
      }
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(submit)} className="space-y-8">
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel> {t(i18nKeys.global.auth.resetpassword.password)}</FormLabel>
              <FormControl>
                <Input type="password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <Button type="submit" className="w-full">
          {t(i18nKeys.global.auth.resetpassword.submit)}
        </Button>
      </form>
    </Form>
  )
}

export default ResetPasswordPage
