import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Badge } from '@/components/ui/badge'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Icons } from '@/components/icons'

const RiskLegend = () => {
  const { t } = useTranslation()
  return (
    <>
      <div className="flex items-center justify-center rounded-lg border p-2">
        <div className="flex flex-row items-center space-x-2">
          <Popover>
            <PopoverTrigger>
              <Icons.Info size={14} className="text-muted-foreground" />
            </PopoverTrigger>
            <PopoverContent className="flex flex-col space-y-6">
              <p className="flex flex-row gap-x-2 text-sm">
                <div>
                  <Icons.FlaskConical size={18} className="text-muted-foreground" />
                </div>
                {t(i18nKeys.beeoimpact.sites.definition.pesticidesScoring)}
              </p>
              <p className="flex flex-row gap-x-2 text-sm">
                <div>
                  <Icons.Leaf size={18} />
                </div>
                {t(i18nKeys.beeoimpact.sites.definition.biodivScoring)}
              </p>
            </PopoverContent>
          </Popover>
          <span className="text-sm">{t(i18nKeys.beeoimpact.sites.top.risk)}</span>
          <Badge
            className={`mr-2 flex flex-row items-end justify-center rounded-full bg-green-500 px-4 py-1 text-xs font-medium text-white`}
          >
            &lt; 30{' '}
          </Badge>
          <Badge
            className={`mr-2 flex flex-row items-end justify-center rounded-full bg-orange-500 px-4 py-1 text-xs font-medium text-white`}
          >
            <span> &lt; 30 & &gt; 70</span>
          </Badge>
          <Badge
            className={`flex flex-row items-end justify-center rounded-full bg-red-500 px-4 py-1 text-xs font-medium text-white`}
          >
            &gt; 70{' '}
          </Badge>
        </div>
      </div>
    </>
  )
}

export default RiskLegend
