import { accountsUsersList } from '@/client/backend/api/accounts/accounts'

const adminUsersLoader = async ({ params }) => {
  const accountId = params.accountId as number

  const users = await accountsUsersList(accountId)
  return { users, accountId }
}

export default adminUsersLoader
