import { Link, useLocation } from 'react-router-dom'

import { isAdmin } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'
import { buttonVariants } from '@/components/ui/button'
import Loader from '@/components/loader'

const AdminSidebar = () => {
  const { user } = useAuth()
  const { pathname } = useLocation()

  if (!user) return <Loader />

  const items = [
    {
      title: 'Accounts',
      href: getPath(Routes.ADMIN_ACCOUNTS),
      canAccess: isAdmin(user),
    },
    {
      title: 'Mappings',
      href: getPath(Routes.ADMIN_MAPPING),
      canAccess: isAdmin(user),
    },
    {
      title: 'Data visualisation',
      href: getPath(Routes.ADMIN_DATA_VISUALISATION),
      canAccess: isAdmin(user),
    },
  ]

  return (
    <nav className="flex space-x-2 lg:flex-col lg:space-x-0 lg:space-y-1">
      {items.map((item) => {
        return (
          item.canAccess && (
            <Link
              key={item.href}
              to={item.href}
              className={cn(
                buttonVariants({ variant: 'ghost' }),
                pathname === item.href ? 'bg-muted hover:bg-muted' : 'hover:bg-transparent hover:underline',
                'justify-start'
              )}
            >
              {item.title}
            </Link>
          )
        )
      })}
    </nav>
  )
}

export default AdminSidebar
