import { Card } from '@/components/ui/card'

interface GreenCardSmallCardProps {
  icon: React.ReactNode
  text: string
  text2?: string | number
  text1?: string
  text3?: string | number
  text4?: string | number
}

const GreenCardSmallCard = ({ icon, text1, text2, text3, text }: GreenCardSmallCardProps) => {
  return (
    <Card className="flex h-[140px] min-w-[200px] flex-row items-center justify-center px-5 py-7 sm:max-h-[140px] sm:max-w-[306px] md:max-h-[140px] md:max-w-[306px] lg:max-h-[80px] lg:max-w-[306px] xl:max-h-[140px] xl:max-w-[225px] 2xl:max-h-[140px] 2xl:max-w-[225px]">
      <div className="flex h-20 items-center justify-start space-y-4 sm:flex-col md:flex-col lg:flex-row lg:space-x-4 lg:px-4 lg:pb-3 xl:flex-col xl:space-x-0  xl:px-0 xl:pb-0 2xl:flex-col 2xl:space-x-0 2xl:px-0 2xl:pb-0">
        <p className="size-6">{icon}</p>
        <p className="text-sm lg:text-start xl:text-center 2xl:text-center">
          <span className="font-semibold">{text}</span>
          <span> {text1}</span>
          <span className="font-semibold">{text2}</span>
          <span>{text3}</span>
        </p>
      </div>
    </Card>
  )
}

export default GreenCardSmallCard
