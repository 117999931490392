import { i18nKeys } from '@/locales/keys'
import { BarDatum, ResponsiveBar } from '@nivo/bar'
import { CartesianMarkerProps, DatumValue } from '@nivo/core'
import { useTranslation } from 'react-i18next'

import { BarChartValueWithColor } from '@/types/graph'
import { cn } from '@/lib/utils'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'

type CardBarChartSubstanceProps = {
  size?: string
  title?: string
  subtitle?: string
  data: BarChartValueWithColor[]
  type: string
  showAllSums?: boolean
}

const CardBarChartSubstance = ({
  size,
  title,
  subtitle,
  data,
  type,
  showAllSums = false,
}: CardBarChartSubstanceProps) => {
  const { t } = useTranslation()

  const lrmValues = data.filter((item) => item.lmr)

  // unique lmr values
  const uniqueLmrValues = lrmValues.filter((item, index, self) => index === self.findIndex((t) => t.lmr === item.lmr))

  const markers: CartesianMarkerProps<DatumValue>[] = uniqueLmrValues.map((item) => {
    const legend =
      uniqueLmrValues.length > 1
        ? item.name?.toLowerCase().startsWith('sum')
          ? `Sum: ${item.lmr}`
          : `LMR: ${item.lmr}`
        : `Sum & LMR: ${item.lmr}`
    return {
      axis: 'y' as const,
      value: item.lmr,
      lineStyle: {
        stroke: 'rgba(255, 0, 0, .35)',
        strokeWidth: 2,
      },
      textStyle: {
        fill: '#000000',
        fontSize: 10,
      },
      legend: legend,
      legendOrientation: 'horizontal',
    } as CartesianMarkerProps<DatumValue>
  })

  // add a markers of dotted line only if type === 'DIOFUR'

  if (type === 'DIOFUR') {
    const marker = {
      axis: 'y' as const,
      value: 1,
      lineStyle: {
        stroke: 'rgba(255, 0, 0, .35)',
        strokeWidth: 2,
      },
      textStyle: {
        fill: '#000000',
        fontSize: 10,
      },
      legend: t(i18nKeys.beeomonitoring.diofur.graph.legend),
      legendOrientation: 'horizontal',
    } as CartesianMarkerProps<DatumValue>
    markers.push(marker)
  }

  let maxValue = 20

  if (type === 'DIOFUR') {
    maxValue = Math.max(...data.map((item) => Math.max(item.teq ?? 0, item.lmr ?? 0))) * 1.25
  } else {
    maxValue = Math.max(...data.map((item) => Math.max(item.local_value, item.lmr ?? 0))) * 1.25
  }

  const allSums = data.filter((item) => item.name?.toLowerCase().startsWith('sum'))

  const dataByType = showAllSums ? allSums : data

  return (
    <Card className={size}>
      <CardHeader>
        <CardTitle className="flex items-center  text-foreground">{title}</CardTitle>
        <CardDescription>{subtitle}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="mx-auto flex h-[500px] w-full items-center justify-center">
          <ResponsiveBar
            layout="vertical"
            data={dataByType as unknown as BarDatum[]}
            maxValue={maxValue < 1 ? 1.25 : maxValue}
            colors={({ data }) => {
              return data.color.toString()
            }}
            enableTotals={true}
            keys={type === 'DIOFUR' ? ['teq'] : ['local_value']}
            indexBy={'name'}
            labelTextColor="#FFFFFF"
            padding={0.4}
            margin={{ top: 5, right: 20, bottom: 60, left: 60 }}
            enableLabel={false}
            valueFormat={(value) => (type === 'DIOFUR' ? `${value.toFixed(3)}` : `${value.toFixed(2)} `)}
            enableGridY={true}
            enableGridX={false}
            groupMode="grouped"
            axisBottom={{
              tickSize: 5,
              tickPadding: 10,
              tickRotation: -15,

              legendPosition: 'middle',
              legendOffset: 40,
              truncateTickAt: 0,
            }}
            axisLeft={{
              tickSize: 0,
              tickPadding: 6,
              tickRotation: 0,
              tickValues: type == 'PAH' ? 4 : [0, 0.2, 0.4, 0.6, 0.8, 1],
              legend: type == 'DIOFUR' ? 'Concentration (pg/g)' : 'Concentration (ng/g)',
              legendPosition: 'middle',
              legendOffset: -40,
              truncateTickAt: 0,
            }}
            labelSkipWidth={18}
            labelSkipHeight={16}
            tooltip={({ id, value, color }) => (
              <div className="rounded-md bg-muted p-2 shadow-md">
                <div className="flex items-center justify-evenly">
                  <div className={cn('mr-2 size-2 rounded-full')} style={{ backgroundColor: color }} />
                  <span>{t(i18nKeys.beeomonitoring.common.graph.legend[id]).toLowerCase()}</span>
                  <span className="ml-5 text-muted-foreground">
                    {type === 'DIOFUR' ? value.toFixed(3) : value.toFixed(2)}
                  </span>
                </div>
              </div>
            )}
            markers={markers}
          />
        </div>
      </CardContent>
    </Card>
  )
}

export default CardBarChartSubstance
