import {
  beeomonitoringGraphsPcbValuesByPeriodList,
  getBeeomonitoringGraphsPcbValuesByPeriodListQueryKey,
} from '@/client/backend/api/graphs/graphs'
import {
  beeomonitoringSitesSiteInfosRetrieve,
  getBeeomonitoringSitesSiteInfosRetrieveQueryKey,
} from '@/client/backend/api/sites/sites'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { t } from 'i18next'
import { Trans } from 'react-i18next'
import { useParams } from 'react-router'

import SubstancePageView from '@/components/substance-pageview'

const PcbPolluantsMonitoringPage = () => {
  const { siteId } = useParams<{ siteId: string }>() as { siteId: string }

  const siteData = useQuery({
    queryKey: [getBeeomonitoringSitesSiteInfosRetrieveQueryKey(siteId)],
    queryFn: () => beeomonitoringSitesSiteInfosRetrieve(siteId, undefined),
  })

  const dataValuesPcbByPeriodData = useQuery({
    queryKey: [getBeeomonitoringGraphsPcbValuesByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsPcbValuesByPeriodList(siteId, undefined),
  })

  return (
    <SubstancePageView
      title={t(i18nKeys.beeomonitoring.pcb.graph.title)}
      subtitle={t(i18nKeys.beeomonitoring.pcb.graph.description)}
      description={
        <Trans
          i18nKey={i18nKeys.beeomonitoring.pcb.definition.content.para_1}
          components={{ p: <p className="mt-4 leading-6" /> }}
        />
      }
      table_title={t(i18nKeys.beeomonitoring.pcb.table.title)}
      table_subtitle={t(i18nKeys.beeomonitoring.pcb.table.description)}
      siteData={siteData}
      substanceData={dataValuesPcbByPeriodData}
      type="PCB"
    />
  )
}

export default PcbPolluantsMonitoringPage
