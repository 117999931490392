import { useState } from 'react'
import { sitesCreate } from '@/client/backend/api/sites/sites'
import { BuildingTypeEnum, SiteCreate } from '@/client/backend/models'
import { FileCheck2Icon, Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import * as XLSX from 'xlsx'

import { useAuth } from '@/hooks/useAuth'
import { Button } from '@/components/ui/button'
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table'
import Dropzone from '@/components/dropzone'
import Loader from '@/components/loader'

type ExcelFile = {
  header: string[]
  data: ExcelData[]
}

type ExcelData = {
  name: string
  lat: number
  lng: number
  biodiversity_radius: number
}

type ExcelError = {
  data: ExcelData
  error: string
}

interface ImportSiteProps {
  accountId?: number
}

const ImportSites = ({ accountId }: ImportSiteProps) => {
  const { user } = useAuth()

  const [excelData, setExcelData] = useState<ExcelFile | null>(null)
  const [errorsImport, setErrorsImport] = useState<ExcelError[]>([])
  const [isImporting, setIsImporting] = useState(false)
  const [nbSitesCreated, setNbSitesCreated] = useState(0)

  const defaultValues = { file: null as File | null }
  const methods = useForm({
    defaultValues,
    shouldFocusError: true,
    shouldUnregister: false,
    shouldUseNativeValidation: false,
  })

  const handleOnDrop = (acceptedFiles: FileList | null) => {
    // Define the headers to extract from the file
    const headersToExtract = ['name', 'lat', 'lng', 'biodiversity_radius']

    // Check if a file is present
    if (!acceptedFiles || acceptedFiles.length === 0) {
      methods.setError('file', {
        message: 'File is required',
        type: 'typeError',
      })
      methods.setValue('file', null)
      return
    }

    const file = acceptedFiles[0]
    const allowedTypes = [
      'text/csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ]

    // Check if the file type is valid
    if (!allowedTypes.includes(file.type)) {
      methods.setError('file', {
        message: 'File type is not valid',
        type: 'typeError',
      })
      methods.setValue('file', null)
      return
    }

    methods.setValue('file', file)
    methods.clearErrors('file')

    const reader = new FileReader()
    reader.onload = (event) => {
      const data = event.target?.result
      if (!data) return

      const workbook = XLSX.read(data, { type: 'binary' })
      const sheetName = workbook.SheetNames[0]
      const sheet = workbook.Sheets[sheetName]
      const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 })

      const [originalHeaderRow, ...dataRows] = jsonData as string[][]

      // Find indices for each desired header
      const columnIndices = headersToExtract.map((header) =>
        originalHeaderRow.findIndex((originalHeader) => new RegExp(header, 'i').test(originalHeader))
      )

      if (columnIndices.includes(-1)) {
        methods.setError('file', {
          message:
            'File is missing required headers, please make sure the file contains ' + headersToExtract.join(', '),
          type: 'typeError',
        })
        methods.setValue('file', null)
        return
      }

      // Get relevant headers and rows
      const relevantHeaderRow = columnIndices.map((index) => originalHeaderRow[index])
      const dataRowsToSave: ExcelData[] = dataRows.map((row) =>
        columnIndices.reduce((acc, index, i) => {
          acc[headersToExtract[i]] = row[index]
          return acc
        }, {} as ExcelData)
      )

      setExcelData({
        header: relevantHeaderRow,
        data: dataRowsToSave,
      })
    }

    reader.readAsBinaryString(file)
  }

  if (!user) return <Loader />

  const handleFormSubmit = async () => {
    const file = excelData?.data

    if (!file) return

    setIsImporting(true)

    for (const site of file) {
      try {
        const dataObject = {
          name: site.name,
          lat: site.lat,
          lng: site.lng,
          features: user?.account.features || [],
          user_id: user.id.toString(),
          radius: site.biodiversity_radius,
          account_id: accountId ? accountId.toString() : user.account_id.toString(),
          building_type: BuildingTypeEnum.INDUSTRIAL,
          surface_ha: 0,
        } as SiteCreate

        await sitesCreate(dataObject)
        setNbSitesCreated((prev) => prev + 1)
      } catch (error) {
        const errorMessage = error instanceof Error ? error.message : 'An error occurred'
        const newError: ExcelError = {
          data: site,
          error: errorMessage,
        }
        setErrorsImport((prevErrors) => [...prevErrors, newError])
      }
    }

    setIsImporting(false)
  }

  const file = methods.watch('file')

  return (
    <>
      <Form {...methods}>
        <form
          className="flex flex-col items-center justify-center gap-2"
          onSubmit={methods.handleSubmit(handleFormSubmit)}
          noValidate
          autoComplete="off"
        >
          <FormField
            control={methods.control}
            name="file"
            render={({ field }) => (
              <FormItem className="w-full ">
                <FormControl>
                  <Dropzone
                    {...field}
                    dropMessage="Drop files or click here"
                    handleOnDrop={handleOnDrop}
                    className="h-80"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          {methods.watch('file') && (
            <div className="relative flex items-center justify-center gap-3 p-4">
              <FileCheck2Icon className="size-4" />
              <p className="text-sm font-medium">{methods.watch('file')?.name}</p>
            </div>
          )}

          <Button type="submit" disabled={isImporting}>
            {isImporting && <Loader2 className="mr-2 size-4 animate-spin" />}
            Importer
          </Button>

          {isImporting && (
            <p>
              {nbSitesCreated} sites created on {excelData?.data?.length}
            </p>
          )}
        </form>
      </Form>
      {file ? (
        <Table>
          <TableHeader>
            <TableRow>
              {excelData?.header?.map((header, index) => <TableHead key={index}>{header}</TableHead>)}
            </TableRow>
          </TableHeader>
          <TableBody>
            {excelData?.data?.map((d, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className="font-medium">{d.name}</TableCell>
                  <TableCell className="font-medium">{d.lat}</TableCell>
                  <TableCell className="font-medium">{d.lng}</TableCell>
                  <TableCell className="font-medium">{d.biodiversity_radius}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      ) : null}
      {errorsImport ? (
        <Table>
          <TableHeader>
            <TableRow>
              {excelData?.header?.map((header, index) => <TableHead key={index}>{header}</TableHead>)}
            </TableRow>
          </TableHeader>
          <TableBody>
            {errorsImport?.map((d, index) => {
              return (
                <TableRow key={index}>
                  <TableCell className="font-medium">dddd</TableCell>
                  <TableCell className="font-medium">{d.error}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      ) : null}
    </>
  )
}

export default ImportSites
