import { Dispatch, SetStateAction, useState } from 'react'
import { Account } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import { Button } from '@/components/ui/button'
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet'
import AccountForm from '@/components/account-form'
import { Icons } from '@/components/icons'

type AccountsTableActionProps = {
  accounts: Account[]
  setAccounts: Dispatch<SetStateAction<Account[]>>
}

const AccountsTableAction = ({ accounts, setAccounts }: AccountsTableActionProps) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button size="sm" className="ml-auto h-8 lg:flex">
          <Icons.UserPlus2 className="mr-2 size-4" />
          {t(i18nKeys.global.common.buttons.addAccount)}
        </Button>
      </SheetTrigger>
      <SheetContent side="right">
        <SheetHeader>
          <SheetTitle> {t(i18nKeys.global.admin.page.accounts.sheet.title)}</SheetTitle>
          <SheetDescription>{t(i18nKeys.global.admin.page.accounts.sheet.description)}</SheetDescription>
        </SheetHeader>
        <AccountForm accounts={accounts} setAccounts={setAccounts} setOpen={setOpen} />
      </SheetContent>
    </Sheet>
  )
}

export default AccountsTableAction
