import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'

import Error from '@/components/error'

const Page503 = () => {
  const { t } = useTranslation()

  return (
    <div className="container relative">
      <Error
        status={503}
        title={t(i18nKeys.global.common.errors[503].title)}
        description={t(i18nKeys.global.common.errors[503].description)}
      />
    </div>
  )
}

export default Page503
