import { createContext, useEffect, useState } from 'react'
import mixpanel from 'mixpanel-browser'

import { IS_DEV } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'

type MixpanelContextType = {
  track: (event: string, properties?: object) => void
  isInitialized: boolean
}

export const MixpanelContext = createContext<MixpanelContextType>({
  track: () => {},
  isInitialized: false,
})

const useMixpanelProvider = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  const { user } = useAuth()
  const token = import.meta.env.VITE_MIXPANEL_TOKEN

  useEffect(() => {
    if (user && !IS_DEV) {
      mixpanel.init(token, { debug: true, track_pageview: false, persistence: 'localStorage' })
      mixpanel.identify(user.id)
      mixpanel.register({
        account: user.account.id,
        account_name: user.account.name,
      })
      setIsInitialized(true)
    }
  }, [user])

  const track = (event: string, properties?: object) => {
    if (isInitialized) {
      mixpanel.track(event, properties)
    }
  }

  return { track, isInitialized }
}

export const MixpanelProvider = ({ children }) => {
  const mixpanel = useMixpanelProvider()

  return <MixpanelContext.Provider value={mixpanel}>{children}</MixpanelContext.Provider>
}
