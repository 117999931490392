import { Icons } from '@/components/icons'

interface ComingSoonProps {
  big?: boolean
  cardBorder?: string
}
const ComingSoon = ({ big, cardBorder }: ComingSoonProps) => {
  return (
    <div className={`${cardBorder} absolute inset-0 z-40 flex items-center justify-center rounded-lg`}>
      <div
        className={`flex items-center justify-center space-x-1 rounded-full bg-biodivVioletEightHundred text-[8px] text-white ${
          big ? 'h-[35px] w-[140px]' : 'h-[20px] w-[95px]'
        }`}
      >
        <span>
          <Icons.Hourglass className={`${big ? 'size-4' : 'size-3'}`} />
        </span>
        <span className={`${big ? 'text-xs' : 'text-[8px]'}`}>COMING SOON</span>
      </div>
    </div>
  )
}

export default ComingSoon
