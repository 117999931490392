import { AVAILABLE_FILTERS } from '@/lib/available-filters'
import { MODULE } from '@/lib/module'
import SitesPageView from '@/components/sites-pageview'

const SitesPage = () => {
  return (
    <SitesPageView
      module={MODULE.IMPACT}
      availableFilters={[AVAILABLE_FILTERS.SCORE_BIODIVERSITY, AVAILABLE_FILTERS.SCORE_PESTICIDES]}
    />
  )
}

export default SitesPage
