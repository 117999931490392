import { PlotCulture, ProbablePesticide } from '@/client/backend/models'
import * as turf from '@turf/turf'
import { Polygon, Tooltip } from 'react-leaflet'

interface SiteParcelProps {
  landplot: PlotCulture
  colors: Record<string, string>
  selectedPesticide?: ProbablePesticide
}

const SiteParcelsPesticides = ({ landplot, colors, selectedPesticide }: SiteParcelProps) => {
  const fillColor = colors[landplot.culture]
  const clickedParcelPesticide = selectedPesticide?.plot_ids?.includes(landplot.id)

  const parcelOptions = {
    color: clickedParcelPesticide ? 'black' : '',
    opacity: clickedParcelPesticide ? 2 : 0.5,
    weight: clickedParcelPesticide ? 1.5 : 0.5,
    fillColor,
    fillOpacity: 0.5,
  }

  const coordinates = landplot.intersection?.['coordinates']
  const newGeom = coordinates?.[0].map((coord) => [coord[1], coord[0]])
  return (
    <Polygon
      key={landplot.id}
      pathOptions={parcelOptions}
      positions={newGeom}
      className="outline-none"
      pane="markerPane"
    >
      <Tooltip sticky>
        <ul>
          <li>{landplot.culture_description}</li>
          <li>{(turf.area(landplot.intersection) / 10000).toFixed(2)} ha</li>
        </ul>
      </Tooltip>
    </Polygon>
  )
}

export default SiteParcelsPesticides
