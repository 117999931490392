import { Link } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { Icons } from '@/components/icons'
import Nav from '@/components/nav'

const Menu = () => {
  const { user } = useAuth()

  let link = getPath(Routes.LANDING_PAGE)

  if (user && hasAccesTo(user.features, FeatureType.PESTICIDES)) {
    link = getPath(Routes.BEEOIMPACT_DASHBOARD)
  } else if (user && hasAccesTo(user.features, FeatureType.INITIATIVE)) {
    link = getPath(Routes.BEEOINITIATIVE_DASHBOARD)
  }

  return (
    <>
      {user && (
        <div className="sticky top-0 flex min-w-[241px] flex-col justify-between border-r bg-biodivStoneHundred backdrop-blur ">
          <div className="sticky top-0 z-50  flex-col  items-center ">
            <Link to={link} className="flex items-center space-x-4 pb-[15px] pl-[26px] pr-[50px] pt-[39px]">
              <Icons.BeeodivLeaf />
            </Link>
            {user && <Nav />}
          </div>
        </div>
      )}
    </>
  )
}

export default Menu
