import { usersResendActivationCreate } from '@/client/backend/api/users/users'
import { User } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { isAxiosError } from 'axios'
import { formatDistance } from 'date-fns'
import * as Locales from 'date-fns/locale'
import { LogIn } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { FeatureType } from '@/types/feature-type'
import { hasAccesTo, isAdmin } from '@/lib/permission'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { useAuth } from '@/hooks/useAuth'
import { useLanguage } from '@/hooks/useLanguage'
import { Button } from '@/components/ui/button'
import { toast } from '@/components/ui/use-toast'
import { Icons } from '@/components/icons'

type UsersTableRowActionProps = {
  user: User
}
const UsersTableRowAction = ({ user }: UsersTableRowActionProps) => {
  const { t } = useTranslation()
  const { language } = useLanguage()
  const { user: currentUser, impersonate } = useAuth()
  const navigate = useNavigate()

  const lastLoginDate = (user.last_login && new Date(user.last_login)) || new Date()
  const formattedDistance = formatDistance(lastLoginDate, Date.now(), {
    addSuffix: true,
    locale: Locales[language],
  })

  const reInviteUser = async (email) => {
    if (!email) {
      return null
    }
    try {
      await usersResendActivationCreate({ email: email })

      toast({
        title: t(i18nKeys.global.settings.page.users.toast.success),
      })
    } catch (error) {
      if (isAxiosError(error) && error.response?.data) {
        toast({
          title: t(i18nKeys.global.settings.page.users.toast.error),
          description: error.response.data[0],
        })
      }
    }
  }

  const impersonateAs = async (id: number) => {
    const impersonateUser = await impersonate(id)

    if (impersonateUser) {
      if (hasAccesTo(impersonateUser.features, FeatureType.INITIATIVE)) {
        navigate(getPath(Routes.BEEOINITIATIVE_DASHBOARD), { replace: true })
      } else {
        navigate(getPath(Routes.BEEOIMPACT_DASHBOARD), { replace: true })
      }
    }
  }

  return (
    <>
      <div className="flex items-center justify-center">
        {!user.last_login ? (
          <Button size="sm" className="h-8" variant="outline" onClick={() => reInviteUser(user.email)}>
            <Icons.Pen className="mr-2 size-4" />
            {t(i18nKeys.global.common.buttons.reInviteUser)}
          </Button>
        ) : (
          <p>{formattedDistance}</p>
        )}

        {isAdmin(currentUser) && (
          <Button
            size="icon"
            variant="outline"
            className="ml-auto hidden border-0 lg:flex"
            onClick={() => impersonateAs(user.id)}
          >
            <LogIn className="size-3" />
          </Button>
        )}
      </div>
    </>
  )
}

export default UsersTableRowAction
