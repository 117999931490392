type ImageProps = {
  src: string
  alt: string
  className?: string
}

const Image = ({ src, alt, ...props }: ImageProps) => {
  if (src.startsWith('./')) {
    const externalSrc = new URL(src, 'https://beeodiversitystorage.blob.core.windows.net/public/')
    return <img src={externalSrc.href} alt={alt} {...props} />
  }
  return <img src={src} alt={alt} {...props} />
}

export default Image
