import { User } from 'lucide-react'
import { Link, useLocation } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { cn } from '@/lib/utils'
import { useAuth } from '@/hooks/useAuth'
import { buttonVariants } from '@/components/ui/button'
import { Separator } from '@/components/ui/separator'
import LanguageSelector from '@/components/language-selector'
import UserNav from '@/components/user-nav'

const Header = () => {
  const { user, isImpersonate } = useAuth()
  const { pathname } = useLocation()
  const bgHeader = isImpersonate ? 'bg-red-200' : null

  return (
    <header
      className={cn(
        'supports-backdrop-blur:bg-background/60 sticky top-0 z-50 flex h-[70px] items-center justify-end border-b bg-background/95 px-8 backdrop-blur',
        bgHeader
      )}
    >
      <LanguageSelector />
      <Separator orientation="vertical" className="mr-3 h-5" />

      {user && pathname !== getPath(Routes.LANDING_PAGE) ? (
        <UserNav />
      ) : (
        <Link to={getPath(Routes.AUTH_LOGIN)} rel="noreferrer">
          <div
            className={cn(
              buttonVariants({
                variant: 'ghost',
              }),
              'w-9 px-0'
            )}
          >
            <User className="size-4" />
            <span className="sr-only">Login</span>
          </div>
        </Link>
      )}
    </header>
  )
}

export default Header
