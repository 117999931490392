const CenteredLayer = (myProps) => (layerProps) => {
  const { centerX, centerY } = layerProps

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: '28px',
        fontWeight: '600',
        fill: '#f87171',
      }}
    >
      {myProps}
    </text>
  )
}

export default CenteredLayer
