import { i18nKeys } from '@/locales/keys'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Button } from '@/components/ui/button'

type ErrorProps = {
  status: number
  title: string
  description: string
}

const Error = ({ status, title, description }: ErrorProps) => {
  const { t } = useTranslation()

  return (
    <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold">{status}</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">{title}</h1>
        <p className="mt-6 text-base leading-7">{description}.</p>
        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link to={getPath(Routes.LANDING_PAGE)}>
            <Button>{t(i18nKeys.global.common.errors.back.home)}</Button>
          </Link>
          <a
            href="mailto:info@beeodiversity.com?Subject=Issue on BeeOimpact or BeeOinitiative"
            className="text-sm font-semibold"
          >
            {t(i18nKeys.global.common.errors.contact)} <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </div>
    </main>
  )
}

export default Error
