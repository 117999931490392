import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Skeleton } from '@/components/ui/skeleton'

const CardStatsBarchartSkeleton = () => {
  return (
    <Card className="mr-4 cursor-pointer md:mr-1">
      <CardHeader className="p-4">
        <Skeleton className="h-6 w-1/2" />
        <div className="py-4">
          <Skeleton className="h-10 w-1/4" />
        </div>
      </CardHeader>
      <CardContent className="p-2 pt-0">
        <div className="h-20">
          <Skeleton className="size-full" />
        </div>
      </CardContent>
    </Card>
  )
}

export default CardStatsBarchartSkeleton
