import { useEffect, useMemo, useState } from 'react'
import {
  beeomonitoringGraphsCountVegetalsByPeriodList,
  beeomonitoringGraphsCountVegetalsBySplitList,
  beeomonitoringGraphsListVegetalsList,
  beeomonitoringGraphsMapVegetalsByCategoryList,
  beeomonitoringGraphsMapVegetalsByFamilyList,
  getBeeomonitoringGraphsCountVegetalsByPeriodListQueryKey,
  getBeeomonitoringGraphsCountVegetalsBySplitListQueryKey,
  getBeeomonitoringGraphsListVegetalsListQueryKey,
  getBeeomonitoringGraphsMapVegetalsByCategoryListQueryKey,
  getBeeomonitoringGraphsMapVegetalsByFamilyListQueryKey,
} from '@/client/backend/api/graphs/graphs'
import {
  beeomonitoringSitesSiteInfosRetrieve,
  getBeeomonitoringSitesSiteInfosRetrieveQueryKey,
} from '@/client/backend/api/sites/sites'
import { ListValues } from '@/client/backend/models'
import { i18nKeys } from '@/locales/keys'
import { useQuery } from '@tanstack/react-query'
import { HomeIcon } from 'lucide-react'
import { Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { useSearchParams } from 'react-router-dom'

import { BarChartValueWithColor, ValuePieChart } from '@/types/graph'
import { COLORS } from '@/lib/constants/constants'
import { getBarPeriodData, getListPeriodData, getPiePeriodData } from '@/lib/graph'
import { Routes } from '@/lib/routes/routes'
import { getPath } from '@/lib/routes/utils'
import { Badge } from '@/components/ui/badge'
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { Separator } from '@/components/ui/separator'
import { Skeleton } from '@/components/ui/skeleton'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink } from '@/components/breadcrumb'
import CardBarChart from '@/components/card-barchart'
import CardBarChartSkeleton from '@/components/card-barchart-skeleton'
import CardLongList from '@/components/card-long-list'
import CardLongListSkeleton from '@/components/card-long-list-skeleton'
import CardPieChart from '@/components/card-piechart'
import CardPieChartSkeleton from '@/components/card-piechart-skeleton'
import { PageHeader, PageHeaderHeading } from '@/components/page-header'
import { H3 } from '@/components/typography'

const BiodiversityMonitoringPage = () => {
  const [searchParams] = useSearchParams()

  const year = searchParams.get('year') ?? '2023'
  const period = searchParams.get('period') ?? 'P4'

  const { t } = useTranslation()
  const [selectedYear, setSelectedYear] = useState(year)
  const [selectedPeriod, setSelectedPeriod] = useState(period)

  const [years, setYears] = useState<string[]>(['2023'])
  const [periods, setPeriods] = useState<string[]>([])

  const [familiesByPeriods, setFamiliesByPeriods] = useState<ValuePieChart[]>([])
  const [categoriesByPeriods, setCategoriesByPeriods] = useState<ValuePieChart[]>([])
  const [countBySplit, setCountBySplit] = useState<ValuePieChart[]>([])
  const [countByPeriod, setCountByPeriod] = useState<BarChartValueWithColor[]>([])
  const [dataSpecies, setDataSpecies] = useState<ListValues[]>()

  const speciesOrder = ['dominant', 'significant', 'trace']

  useEffect(() => {
    setSelectedYear(year ?? selectedYear)
    setSelectedPeriod(period ?? selectedPeriod)
  }, [year, period])

  const { siteId } = useParams<{ siteId: string }>() as { siteId: string }

  const { data: site, isLoading: isLoadingSite } = useQuery({
    queryKey: [getBeeomonitoringSitesSiteInfosRetrieveQueryKey(siteId)],
    queryFn: () => beeomonitoringSitesSiteInfosRetrieve(siteId, undefined),
  })

  const { data: dataFamilies, isLoading: isLoadingDataFamilies } = useQuery({
    queryKey: [getBeeomonitoringGraphsMapVegetalsByFamilyListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsMapVegetalsByFamilyList(siteId, undefined),
  })

  const { data: dataCategories, isLoading: isLoadingDataCategories } = useQuery({
    queryKey: [getBeeomonitoringGraphsMapVegetalsByCategoryListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsMapVegetalsByCategoryList(siteId, undefined),
  })

  const { data: dataCountBySplit, isLoading: isLoadingCountBySplit } = useQuery({
    queryKey: [getBeeomonitoringGraphsCountVegetalsBySplitListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsCountVegetalsBySplitList(siteId, undefined),
  })

  const { data: dataCountByPeriod, isLoading: isLoadingCountByPeriod } = useQuery({
    queryKey: [getBeeomonitoringGraphsCountVegetalsByPeriodListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsCountVegetalsByPeriodList(siteId, undefined),
  })

  const { data: dataListIdentifications, isLoading: isLoadingDataListIdentification } = useQuery({
    queryKey: [getBeeomonitoringGraphsListVegetalsListQueryKey(siteId)],
    queryFn: () => beeomonitoringGraphsListVegetalsList(siteId, undefined),
  })

  const allYears = useMemo(() => {
    const yearsCategories = dataCategories?.map((item) => item.year) || []
    const yearsFamilies = dataFamilies?.map((item) => item.year) || []
    const yearsCountyBySplit = dataCountBySplit?.map((item) => item.year) || []
    const yearsCountByPeriod = dataCountByPeriod?.map((item) => item.year) || []

    return Array.from(
      new Set([...yearsCategories, ...yearsFamilies, ...yearsCountyBySplit, ...yearsCountByPeriod])
    ).sort()
  }, [dataCategories, dataFamilies, dataCountBySplit, dataCountByPeriod])

  useEffect(() => {
    if (allYears.length > 0) {
      setYears(allYears)
      setSelectedYear(year ?? allYears[allYears.length - 1])
    }
  }, [allYears])

  const allPeriods = useMemo(() => {
    const dataCategoriesPeriods = dataCategories?.find((item) => item.year === selectedYear)?.periods || []
    const dataFamiliesPeriods = dataFamilies?.find((item) => item.year === selectedYear)?.periods || []
    const dataCountSpeciesPeriods = dataCountBySplit?.find((item) => item.year === selectedYear)?.periods || []

    return Array.from(
      new Set([...dataCategoriesPeriods, ...dataFamiliesPeriods, ...dataCountSpeciesPeriods].map((item) => item.key))
    ).sort()
  }, [dataCategories, dataFamilies, dataCountBySplit, selectedYear])

  useEffect(() => {
    if (allPeriods.length > 0) {
      setPeriods(allPeriods)
      setSelectedPeriod(allPeriods.includes(period) ? period : allPeriods[allPeriods.length - 1])
    }
  }, [allPeriods])

  useEffect(() => {
    if (selectedYear && selectedPeriod) {
      setFamiliesByPeriods(getPiePeriodData(dataFamilies ?? [], selectedPeriod, selectedYear))
      setCategoriesByPeriods(getPiePeriodData(dataCategories ?? [], selectedPeriod, selectedYear))
      setCountBySplit(getPiePeriodData(dataCountBySplit ?? [], selectedPeriod, selectedYear))
      setCountByPeriod(getBarPeriodData(dataCountByPeriod ?? [], selectedPeriod, selectedYear))

      const unsortedDataSpecies = getListPeriodData(dataListIdentifications ?? [], selectedPeriod, selectedYear)
      const sortedDataSpecies = speciesOrder
        .map((orderItem) => unsortedDataSpecies.find((species) => species.name === orderItem))
        .filter(Boolean) as ListValues[]

      setDataSpecies(sortedDataSpecies)
    }
  }, [
    dataFamilies,
    dataCategories,
    dataCountBySplit,
    dataCountByPeriod,
    dataListIdentifications,
    selectedPeriod,
    selectedYear,
  ])

  const piechartData = [
    {
      isLoading: isLoadingDataCategories,
      data: categoriesByPeriods,
      title: t(i18nKeys.beeomonitoring.biodiversity.graphs.map_by_category.title),
      subtitle: t(i18nKeys.beeomonitoring.biodiversity.graphs.map_by_category.description),
    },
    {
      isLoading: isLoadingDataFamilies,
      data: familiesByPeriods,
      title: t(i18nKeys.beeomonitoring.biodiversity.graphs.map_by_family.title),
      subtitle: t(i18nKeys.beeomonitoring.biodiversity.graphs.map_by_family.description),
    },
    {
      isLoading: isLoadingCountBySplit,
      data: countBySplit,
      title: t(i18nKeys.beeomonitoring.biodiversity.graphs.count_by_split.title),
      subtitle: t(i18nKeys.beeomonitoring.biodiversity.graphs.count_by_split.description),
    },
  ]

  return (
    <div className="container relative my-8">
      <Breadcrumb>
        <BreadcrumbItem>
          <BreadcrumbLink href="/">
            <HomeIcon className="size-4" />
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOMONITORING_SITES)}>
            {t(i18nKeys.beeoimpact.common.breadcrumb.mySites)}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink href={getPath(Routes.BEEOMONITORING_SITE, { params: { siteId: site?.id as number } })}>
            {isLoadingSite ? <Skeleton className="h-4 w-[200px]" /> : site?.name}
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink
            href={getPath(Routes.BEEOMONITORING_SITE_BIODIVERSITY, { params: { siteId: site?.id as number } })}
          >
            {t(i18nKeys.beeomonitoring.common.breadcrumb.biodiversity)}
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>
      <div className="flex flex-col justify-between">
        <div className="flex items-center justify-between space-y-2">
          <PageHeader>
            <PageHeaderHeading>
              {isLoadingSite ? (
                <div className="flex items-end justify-start">
                  <Skeleton className="mr-2 h-[34px] w-[400px]" />
                  <Skeleton className="h-[24px] w-[80px]" />
                </div>
              ) : (
                site && (
                  <div>
                    {site.name} <span className="text-sm font-medium text-muted-foreground"># {site.key}</span>
                  </div>
                )
              )}
            </PageHeaderHeading>
            <div className="flex flex-row">
              {isLoadingSite ? (
                <>
                  <div>
                    <Skeleton className="h-[22px] w-[62px]" />
                  </div>

                  <div>
                    <Skeleton className="ml-2 h-[22px] w-[110px]" />
                  </div>
                </>
              ) : (
                site?.tags?.map((tag) => (
                  <Badge key={tag} className="mr-4 rounded-none bg-muted text-foreground hover:bg-foreground/10">
                    {tag}
                  </Badge>
                ))
              )}
            </div>
          </PageHeader>
          <div className="flex items-center space-x-2">
            {isLoadingSite ||
            isLoadingCountByPeriod ||
            isLoadingCountByPeriod ||
            isLoadingCountBySplit ||
            isLoadingDataCategories ||
            isLoadingDataFamilies ? (
              <>
                <Skeleton className="h-10 w-44" />
                <Skeleton className="h-10 w-44" />
              </>
            ) : (
              <>
                <Select value={selectedYear} onValueChange={(e) => setSelectedYear(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedYear} placeholder={selectedYear} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {years.map((year) => (
                        <SelectItem key={year} value={year}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
                <Select value={selectedPeriod} onValueChange={(e) => setSelectedPeriod(e)}>
                  <SelectTrigger className="w-[180px]">
                    <SelectValue defaultValue={selectedPeriod} placeholder={`${selectedPeriod}`} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectGroup>
                      {periods.map((period, index) => (
                        <SelectItem key={index} value={period}>
                          {period}
                        </SelectItem>
                      ))}
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </>
            )}
          </div>
        </div>
      </div>

      <div>
        <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-2">
          {isLoadingCountByPeriod ? (
            <CardBarChartSkeleton />
          ) : (
            <CardBarChart
              data={countByPeriod}
              title={t(i18nKeys.beeomonitoring.biodiversity.graphs.count_by_period.title)}
              subtitle={t(i18nKeys.beeomonitoring.biodiversity.graphs.count_by_period.description)}
              colors={COLORS}
              legendY={t(i18nKeys.beeomonitoring.biodiversity.graphs.count_by_period.legend.y)}
              showLegend
              rounded={1}
            />
          )}

          <Card>
            <CardHeader>
              <CardTitle>{t(i18nKeys.beeomonitoring.common.definition)}</CardTitle>
              <CardDescription>{t(i18nKeys.beeomonitoring.biodiversity.definition.description)}</CardDescription>
            </CardHeader>
            <CardContent>
              <p className="leading-6">
                <Trans
                  i18nKey={i18nKeys.beeomonitoring.biodiversity.definition.content.para1}
                  components={{ span: <span className="text-xs italic" /> }}
                />
              </p>

              <p className="mt-4 leading-6">{t(i18nKeys.beeomonitoring.biodiversity.definition.content.para2)}</p>
              <p className="mt-4 leading-6">{t(i18nKeys.beeomonitoring.biodiversity.definition.content.para3)}</p>
            </CardContent>
          </Card>
        </div>
        <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3">
          {piechartData.map((item, index) => {
            return item.isLoading ? (
              <CardPieChartSkeleton key={index} />
            ) : (
              <CardPieChart key={index} data={item.data} title={item.title} subtitle={item.subtitle} />
            )
          })}
        </div>
      </div>
      <div>
        <H3>{t(i18nKeys.beeomonitoring.biodiversity.species.title)}</H3>
        <p className="pb-4 text-sm text-muted-foreground">
          {t(i18nKeys.beeomonitoring.biodiversity.species.description)}
        </p>
        <Separator />

        <div>
          <div className="grid grid-cols-1 gap-4 pt-8 md:grid-cols-3">
            {isLoadingDataListIdentification
              ? Array.from({ length: 3 }).map((_, index) => <CardLongListSkeleton key={index} />)
              : dataSpecies?.map((item) => {
                  return (
                    <CardLongList
                      item={item}
                      key={item.name}
                      title={t(i18nKeys.beeomonitoring.biodiversity.species.titleType[item.name])}
                      description={t(i18nKeys.beeomonitoring.biodiversity.species.type[item.name])}
                    />
                  )
                })}
          </div>
        </div>
      </div>
    </div>
  )
}

export default BiodiversityMonitoringPage
