import { sitesCddaList, sitesN2000List, sitesRetrieve } from '@/client/backend/api/sites/sites'

import { getSiteCenter } from '@/lib/geo'

const impactsTableLoader = async ({ params }) => {
  const { siteId } = params
  const site = await sitesRetrieve(siteId)

  const center = getSiteCenter(site)

  const [natura2000, cdda] = await Promise.all([sitesN2000List(siteId), sitesCddaList(siteId)])

  return { site, natura2000, cdda, center }
}

export default impactsTableLoader
